import { PayloadAction } from '@reduxjs/toolkit';
import { IGetReviewResponse } from '@svitlofour/types';

import { IDataReview, IDataState } from '../types';

export const dataReviewsReducers = {
  setDataReviewsRatingItem: (
    state: IDataState,
    { payload: { uid, agree } }: PayloadAction<IDataReview>
  ) => {
    state.reviews.data[uid].rating += agree ? -1 : +1;
    state.reviews.data[uid].agree = !agree;
  },
  setDataReviewByUid: (
    state: IDataState,
    { payload }: PayloadAction<IGetReviewResponse>
  ) => {
    state.reviews.data[payload.uid] = {
      ...payload,
      timestamp: new Date().valueOf(),
    };
  },
};
