import { CloseOutlined } from '@ant-design/icons';
import { Flex, Image } from 'antd';
import { InnerDrawer, SmallButton } from '@svitlofour/ui';

import { useAppSelector, setIsReviewDrawerSmall, useAppDispatch } from 'store';
import { _activeReviewUid, _getDataReview, _photo } from 'store/selectors';
import { useReviewDrawer } from 'shared/hooks';

import { getPhotoSmall } from 'helpers';

import { SharedSNDropdown } from 'modules/cabinet/components/MyReviews/components/MyReview/components';

import { ComplaintDropdown } from './components';
import { Photo } from './components';
import { SSmallDrawerWrapper, SCloseButtonWrapper } from './style';

export const MapPhotoDrawer = () => {
  const dispatch = useAppDispatch();
  const { isOpenDrawer, closeDrawer } = useReviewDrawer();

  const photo = useAppSelector(_photo);
  const reviewUid = useAppSelector(_activeReviewUid);
  const review = useAppSelector(_getDataReview(reviewUid));
  const { isReviewDrawerSmall } = useAppSelector(({ active }) => active);

  if (isReviewDrawerSmall) {
    return (
      <SSmallDrawerWrapper
        onClick={() => dispatch(setIsReviewDrawerSmall(false))}
      >
        <Image
          preview={false}
          src={getPhotoSmall(review.urls[0])}
          alt={`photo-${review.uid}`}
          style={{
            borderRadius: '15px',
            border: '3px solid white',
            background: 'none',
          }}
        />
        <SCloseButtonWrapper>
          <SmallButton
            onClick={closeDrawer}
            icon={<CloseOutlined />}
            $color="light"
          />
        </SCloseButtonWrapper>
      </SSmallDrawerWrapper>
    );
  }

  return (
    <InnerDrawer
      title={<></>}
      open={isOpenDrawer}
      destroyOnClose
      extra={
        <Flex justify="flex-end" align="center" gap={8}>
          <SharedSNDropdown
            text={photo?.text || review?.text}
            urls={photo?.urls || review?.urls}
            uid={photo?.uid || review?.uid}
            placement="bottomRight"
          />
          <ComplaintDropdown reviewUid={review?.uid} />
          <SmallButton onClick={closeDrawer} icon={<CloseOutlined />} />
        </Flex>
      }
    >
      {review && <Photo data={review} />}
    </InnerDrawer>
  );
};
