import { Flex } from 'antd';
import { ISelectedReportActionsProps } from './types';
import { ActionButton } from 'shared';
import { StopOutlined } from '@ant-design/icons';

import { useReportsContext } from '../../../ReportsContext';

export const SelectedReportActions = ({
  report,
}: ISelectedReportActionsProps) => {
  const { onRemoveReport } = useReportsContext();

  return (
    <Flex
      vertical
      justify="center"
      style={{ height: '100%' }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <ActionButton
        icon={<StopOutlined />}
        onClick={() => onRemoveReport(report)}
      />
    </Flex>
  );
};
