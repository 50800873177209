import { Amplify } from 'aws-amplify';
import { USER_POOL_ID, USER_POOL_WEB_CLIENT_ID } from './envConfig';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID,
      userPoolClientId: USER_POOL_WEB_CLIENT_ID,
    },
  },
});

export const currentConfig = Amplify.getConfig();
