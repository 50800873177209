/* eslint-disable react/react-in-jsx-scope */
/**
 * MarkerSvg component for creating Marker with color parameters
 * Example: <MarkerSvg size={512} data={{ R: 15, Y: 25, G: 60 }} />
 * R, G, Y - colors percentage data
 */

import { polarToCartesian } from './helper';
import { markerConfig } from '../../config';

export interface MarkerSvgProps {
  size?: number;
  data: {
    R: number;
    Y: number;
    G: number;
  };
}

export const MarkerData = ({
  size = markerConfig.defaultMarkerSize,
  data,
}: MarkerSvgProps) => {
  const R = size / 2;
  const red = {
    start: polarToCartesian(R, 180),
    // end: polarToCartesian(R, 0),
    end: polarToCartesian(R, (data.R * 3.6 + 180) < 360 ? (data.R * 3.6 + 180) : 0),
    largeArcFlag: data.R * 3.6 <= 180 ? '0' : '1',
  };
  const yellow = {
    start: polarToCartesian(R, 180 - data.Y * 3.6),
    end: polarToCartesian(R, 180),
    largeArcFlag: data.Y * 3.6 <= 180 ? '0' : '1',
  };
  const dG = [
    'M',
    0,
    R,
    'a',
    R,
    R,
    0,
    1,
    0,
    R * 2,
    0,
    'a',
    R,
    R,
    0,
    1,
    0,
    -(R * 2),
    0,
  ].join(' ');

  const fullR = [
    'M',
    0,
    R,
    'a',
    R,
    R,
    0,
    1,
    0,
    R * 2,
    0,
    'a',
    R,
    R,
    0,
    1,
    0,
    -(R * 2),
    0,
  ].join(' ');

  const fullY = [
    'M',
    0,
    R,
    'a',
    R,
    R,
    0,
    1,
    0,
    R * 2,
    0,
    'a',
    R,
    R,
    0,
    1,
    0,
    -(R * 2),
    0,
  ].join(' ');

  const dR = [
    'M',
    red.start.X,
    red.start.Y,
    'A',
    R,
    R,
    0,
    red.largeArcFlag,
    1,
    red.end.X,
    red.end.Y,
    'L',
    R,
    R,
    'Z',
  ].join(' ');

  const dY = [
    'M',
    yellow.start.X,
    yellow.start.Y,
    'A',
    R,
    R,
    0,
    yellow.largeArcFlag,
    1,
    yellow.end.X,
    yellow.end.Y,
    'L',
    R + (data.Y >= 50 ? 0 : 1),
    R - (data.Y >= 50 ? 1 : 0),
    'Z',
  ].join(' ');

  return {
    G: {
      path: dG,
      fill: markerConfig.green,
    },
    Y: {
      path: data.Y === 100 ? fullY : dY,
      fill: markerConfig.yellow,
    },
    R: {
      path: data.R === 100 ? fullR : dR,
      fill: markerConfig.red,
    },
  };
};

// export const MarkerSvg = ({ size = config.defaultMarkerSize, data }: MarkerSvgProps) => {
//   // const R = size / 2;
//   const sizePx = `${size}px`;
//   const viewBox = `0 0 ${size} ${size}`;

//   const { R, Y } = MarkerData({ size, data });

//   return (
//     <svg
//       width={sizePx}
//       height={sizePx}
//       viewBox={viewBox}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       {/* Green */}
//       <circle
//         cx={size / 2}
//         cy={size / 2}
//         r={size / 2}
//         fill={config.green}
//       />

//       {/* Yellow */}
//       <path
//         fill={config.yellow}
//         stroke={config.yellow}
//         d={Y.path}
//       />

//       {/* Red */}
//       <path
//         fill={config.red}
//         stroke={config.red}
//         d={R.path}
//       />
//     </svg>
//   );
// };
