import styled from 'styled-components';
import { Flex } from 'antd';

import { colors, metrics } from 'theme';

export const SWrapper = styled(Flex)<{ $isNewNotification: boolean }>`
  width: auto;
  height: ${({ $isNewNotification }) => ($isNewNotification ? '80px' : '40px')};
  background-color: ${colors.white};
  border-radius: 8px;
  margin-top: 10px;
  padding: 12px;

  // @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
  //   display: none;
  // }
`;
