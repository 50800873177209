import { combineReducers } from '@reduxjs/toolkit';

import {
  markersReducer,
  reviewReducer,
  activeReducer,
  authReducer,
  myReviewsReducer,
  observersReducer,
  settingsReducer,
  dataReducer,
  cabinetReducer,
  notificationsReducer,
  corporativeCabinetReducer,
  reportsReducer,
  mapReducer,
} from './slices';
import {
  myReviewsApi,
  observersApi,
  hashtagsApi,
  filterApi,
  locationsApi,
  commentApi,
  reviewAgreeApi,
  reviewApi,
  photosApi,
  complaintApi,
  notificationApi,
  companiesApi,
  companyReportsApi,
  userDataApi,
  companyUsersApi,
} from './queries';

const rootReducer = combineReducers({
  markers: markersReducer,
  review: reviewReducer,
  active: activeReducer,
  auth: authReducer,
  myReviews: myReviewsReducer,
  [myReviewsApi.reducerPath]: myReviewsApi.reducer,
  observers: observersReducer,
  [observersApi.reducerPath]: observersApi.reducer,
  [hashtagsApi.reducerPath]: hashtagsApi.reducer,
  [filterApi.reducerPath]: filterApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  settings: settingsReducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [reviewAgreeApi.reducerPath]: reviewAgreeApi.reducer,
  [photosApi.reducerPath]: photosApi.reducer,
  [complaintApi.reducerPath]: complaintApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  data: dataReducer,
  cabinet: cabinetReducer,
  notifications: notificationsReducer,
  corporativeCabinet: corporativeCabinetReducer,
  reports: reportsReducer,
  map: mapReducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [companyReportsApi.reducerPath]: companyReportsApi.reducer,
  [userDataApi.reducerPath]: userDataApi.reducer,
  [companyUsersApi.reducerPath]: companyUsersApi.reducer,
});

export default rootReducer;
