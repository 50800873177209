import { MarkerF } from '@react-google-maps/api';
import { markerConfig } from 'config';
import { colors } from 'theme';
import { useAppSelector } from 'store';
import {
  _activeReviewUid,
  _activeShowReviewMarker,
  _getDataReview,
} from 'store/selectors';
import { path } from '../../../MyMarker/constants';

export const MapGoogleReviewMarker = () => {
  const reviewUid = useAppSelector(_activeReviewUid);
  const review = useAppSelector(_getDataReview(reviewUid));
  const showReviewMarker = useAppSelector(_activeShowReviewMarker);

  // console.log(reviewUid, review);
  if (!showReviewMarker || !reviewUid || !review) {
    return null;
  }

  return (
    <MarkerF
      position={{
        lng: review.longitude,
        lat: review.latitude,
      }}
      icon={{
        anchor: {
          x: markerConfig.myMarkerSize / 2,
          y: markerConfig.myMarkerSize / 2,
        } as google.maps.Point,
        path,
        fillColor: colors[review.color],
        fillOpacity: 0.9,
        scale: 1,
        strokeColor: colors[review.color],
        strokeWeight: 1,
      }}
      opacity={1}
      // onClick={scrollToSelectedItem}
    />
  );
};
