export const pointSize = 2;

export const centerSize = 10;

export const R = pointSize / 2;

export const RCenter = centerSize / 2;

export const pointColor = '#0033ff';

export const centerColor = '#ff3232';

export const centerPath = [
  'M',
  0,
  RCenter,
  'a',
  RCenter,
  RCenter,
  0,
  1,
  0,
  RCenter * 2,
  0,
  'a',
  RCenter,
  RCenter,
  0,
  1,
  0,
  -(RCenter * 2),
  0,
].join(' ');

export const path = [
  'M',
  0,
  R,
  'a',
  R,
  R,
  0,
  1,
  0,
  R * 2,
  0,
  'a',
  R,
  R,
  0,
  1,
  0,
  -(R * 2),
  0,
].join(' ');
