import { Flex } from 'antd';
import { MarkerF } from '@react-google-maps/api';
import { useAppSelector } from 'store';
import { _selectedMarker } from 'store/selectors';
import { markerConfig } from 'config';
import { colors } from 'theme';

export const MapGoogleSelectedMarker = () => {
  const selectedMarker = useAppSelector(_selectedMarker);

  if (!selectedMarker) {
    return null;
  }

  const r = markerConfig.defaultMarkerSize / 2 + 2;
  const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
    r * 2
  } 0`;

  return (
    <Flex key={selectedMarker.id}>
      <MarkerF
        position={{
          lng: selectedMarker.longitude,
          lat: selectedMarker.latitude,
        }}
        icon={{
          anchor: {
            x: markerConfig.defaultMarkerSize / 2 + 2,
            y: markerConfig.defaultMarkerSize / 2 + 2,
          } as google.maps.Point,
          path,
          strokeColor: colors.black,
          strokeWeight: 2,
          strokeOpacity: 1,
          scale: 1.2,
        }}
      />
    </Flex>
  );
};
