import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetLocationsResponse,
  IPostLocationResponse,
  IPostLocationRequest,
  IDeleteLocationResponse,
  IDeleteLocationRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';
import { ELocationsTagType } from './types';

export const locationsApi = createApi({
  reducerPath: 'locationsApi',
  tagTypes: [ELocationsTagType.LOCATIONS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getLocations: build.query<IGetLocationsResponse, void>({
      query: () => ({
        url: `${version}/locations`,
      }),
      providesTags: (
        result
      ): { type: ELocationsTagType.LOCATIONS; id: string }[] =>
        result
          ? [
              ...result.map(({ uid }) => ({
                type: ELocationsTagType.LOCATIONS,
                id: uid,
              })),
              { type: ELocationsTagType.LOCATIONS, id: 'List' },
            ]
          : [{ type: ELocationsTagType.LOCATIONS, id: 'List' }],
    }),
    addLocation: build.mutation<IPostLocationResponse, IPostLocationRequest>({
      query: (body) => ({
        url: `${version}/location`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: ELocationsTagType.LOCATIONS, id: 'List' }],
    }),
    deleteLocation: build.mutation<
      IDeleteLocationResponse,
      IDeleteLocationRequest
    >({
      query: (body) => ({
        url: `${version}/location`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: ELocationsTagType.LOCATIONS, id: 'List' }],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useDeleteLocationMutation,
} = locationsApi;
