import { useState } from 'react';
import { Tooltip, List, Flex, message, Dropdown } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  EllipsisOutlined,
  CloseOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  useAppSelector,
  useAppDispatch,
  useLazyGetCommentsQuery,
  useEditCommentMutation,
  useDeleteCommentMutation,
  setComments,
} from 'store';

import {
  AddCommentForm,
  EditCommentInput,
  LoadAllCommentsButton,
} from './components';
import { IComment } from './types';
import {
  SWrapper,
  SHeader,
  SAuthor,
  STime,
  SIconBlock,
  SDropdownBody,
  SReportButton,
  SActionsButton,
} from './style';

dayjs.extend(relativeTime);

export const Comment = ({
  reviewsData,
  commentFormIsVisible,
  setCommentFormIsVisible,
}: IComment) => {
  const dispatch = useAppDispatch();
  const { name, userUid: currentUserUid } = useAppSelector(({ auth }) => auth);

  const [trigger, { isFetching }] = useLazyGetCommentsQuery();
  const [editComment, { isLoading: isEditCommentLoading }] =
    useEditCommentMutation();
  const [deleteComment, { isLoading: isDeleteCommentLoading }] =
    useDeleteCommentMutation();

  const [isEditMode, setIsEditMode] = useState<string | null>(null);
  const [isDelete, setIsDelete] = useState<string | null>(null);
  const [commentText, setCommentText] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<string | null>(null);

  const listData = reviewsData.comments.map(
    ({ uid, userUid, userName, text, createdAt }) => ({
      uid,
      userUid,
      author:
        userUid === currentUserUid
          ? `${userName || name} (You)`
          : userName || name,
      content: text,
      datetime: (
        <Tooltip title={dayjs().to(dayjs(createdAt))}>
          <span>{dayjs().to(dayjs(createdAt))}</span>
        </Tooltip>
      ),
    })
  );

  const onEdit = (uid: string) => {
    editComment({ uid, text: commentText })
      .unwrap()
      .then(({ comments, total }) => {
        setIsEditMode(null);
        dispatch(
          setComments({
            ...reviewsData,
            comments,
            total,
          })
        );
      })
      .catch((err) => message.error(err));
  };

  const onDelete = (uid: string) => {
    setIsDelete(uid);

    deleteComment({ uid })
      .unwrap()
      .then(({ comments, total }) => {
        dispatch(
          setComments({
            ...reviewsData,
            comments,
            total,
          })
        );
      })
      .catch((err) => message.error(err))
      .finally(() => setIsDropdownOpen(null));
  };

  return (
    <SWrapper vertical>
      <AddCommentForm
        reviewsData={reviewsData}
        commentFormIsVisible={commentFormIsVisible}
        setCommentFormIsVisible={setCommentFormIsVisible}
      />
      <List
        className="comment-list"
        header={<SHeader>Comments ({reviewsData?.commentsTotal})</SHeader>}
        itemLayout="horizontal"
        dataSource={listData}
        loading={isFetching}
        loadMore={
          <LoadAllCommentsButton reviewsData={reviewsData} trigger={trigger} />
        }
        renderItem={({ uid, userUid, author, content, datetime }) => (
          <li>
            <Flex justify="space-between" align="center">
              <Flex align="center" gap={16}>
                <SIconBlock justify="center" align="center">
                  {author?.slice(0, 1).toUpperCase()}
                </SIconBlock>
                <Flex vertical style={{ paddingBlock: 16 }}>
                  <Flex>
                    <SAuthor>{author}</SAuthor>
                    <STime>{datetime}</STime>
                  </Flex>
                  <Flex style={{ paddingBlock: 8 }}>
                    {isEditMode === uid ? (
                      <EditCommentInput
                        initialText={content}
                        commentText={commentText}
                        setCommentText={setCommentText}
                      />
                    ) : (
                      content
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <Dropdown
                open={isDropdownOpen === uid}
                destroyPopupOnHide
                dropdownRender={() => (
                  <>
                    {currentUserUid && (
                      <SDropdownBody vertical>
                        {userUid !== currentUserUid ? (
                          <Flex align="center">
                            <SReportButton icon={<WarningOutlined />}>
                              Report
                            </SReportButton>
                          </Flex>
                        ) : (
                          <Flex align="baseline" vertical>
                            <SActionsButton
                              disabled={userUid !== currentUserUid}
                              icon={<EditOutlined />}
                              onClick={() => {
                                setIsEditMode(uid);
                                setIsDropdownOpen(null);
                              }}
                            >
                              Edit
                            </SActionsButton>
                            <SActionsButton
                              disabled={userUid !== currentUserUid}
                              icon={
                                isDelete === uid && isDeleteCommentLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <DeleteOutlined />
                                )
                              }
                              onClick={() =>
                                isEditMode !== uid && onDelete(uid)
                              }
                            >
                              Delete
                            </SActionsButton>
                          </Flex>
                        )}
                      </SDropdownBody>
                    )}
                  </>
                )}
                placement="topRight"
                trigger={['click']}
              >
                {isEditMode === uid ? (
                  <Flex>
                    <SActionsButton
                      icon={
                        isEditCommentLoading ? (
                          <LoadingOutlined />
                        ) : (
                          <CheckCircleOutlined style={{ color: 'green' }} />
                        )
                      }
                      onClick={() => onEdit(uid)}
                    />
                    <SActionsButton
                      icon={<CloseOutlined />}
                      onClick={() => setIsEditMode(null)}
                    />
                  </Flex>
                ) : (
                  <SActionsButton
                    onClick={() =>
                      isDropdownOpen === uid
                        ? setIsDropdownOpen(null)
                        : setIsDropdownOpen(uid)
                    }
                    icon={<EllipsisOutlined />}
                  />
                )}
              </Dropdown>
            </Flex>
          </li>
        )}
      />
    </SWrapper>
  );
};
