import { useMapType } from 'shared/hooks';
import {
  MapGoogleSelectedMarker,
  MapLeafletSelectedMarker,
} from './components';

export const MapSelectedMarker = () => {
  const { isLeaflet } = useMapType();

  if (isLeaflet) {
    return <MapLeafletSelectedMarker />;
  }

  return <MapGoogleSelectedMarker />;
};
