import { Flex } from 'antd';
import { styled } from 'styled-components';
import { colors } from 'theme';

export const SMapLocationSelect = styled(Flex)`
  position: absolute;
  z-index: 1000;
  bottom: 40px;
  left: calc(50% - 90px);
  gap: 8px;
  padding: 8px;
  border: 1px solid ${colors.grey40};
  border-radius: 4px;
  background-color: ${colors.white};
  justify-content: space-between;
  align-items: center;
`;
