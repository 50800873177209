import { INotificationsInitialState } from './types';

export const initialState: INotificationsInitialState = {
  isLoading: false,
  isNotificationsOpen: false,
  notificationsData: {
    total: 0,
    events: [],
  },
  notificationsBounds: null,
  isDetailedNotificationCoordinate: null,
  isNotificationDrawerOpen: false,
};
