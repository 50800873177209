import { createSlice } from '@reduxjs/toolkit';

import { IDataState } from './types';
import { dataReviewsReducers } from './reviews';
import { dataCommentsReducers } from './comments';
import { dataPhotosReducers } from './photos';

const initialState: IDataState = {
  reviews: {
    data: {},
  },
  photos: {
    data: {},
  },
  comments: {
    data: {
      total: 0,
      comments: [],
    },
  },
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    ...dataReviewsReducers,
    ...dataCommentsReducers,
    ...dataPhotosReducers,
  },
});

export const {
  setDataReviewsRatingItem,
  setComments,
  setPhotos,
  setDataReviewByUid,
} = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
