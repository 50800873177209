import { Collapse, CollapseProps, ConfigProvider, Flex, Select } from 'antd';
import { EDirection, IReportTableItem } from '@svitlofour/types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ReportsActionsDashboard } from '../ReportsActionsDashboard';
import { ReportsPath } from 'router/routes/constants';
import { useNavigate } from 'react-router-dom';
import {
  _selectedReports,
  _selectedReportsDirections,
  setSelectedReportsDirection,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ReportCardLabel } from '../ReportCardLabel';
import { ReportCard } from '../ReportCard';
import { SelectedReportActions } from './components';

const SelectedReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const direction = useAppSelector(_selectedReportsDirections);
  const selectedReports = useAppSelector(_selectedReports);
  const [sortedReports, setSortedReports] = useState<IReportTableItem[]>([]);

  if (!selectedReports.length) {
    return null;
  }

  const sortReports = (
    reports: IReportTableItem[],
    direction: EDirection
  ): IReportTableItem[] => {
    if (direction === EDirection.ASC) {
      const sortedReports = reports.sort(
        (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
      );
      return sortedReports;
    } else {
      const sortedReports = reports.sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
      return sortedReports;
    }
  };

  const items: CollapseProps['items'] = sortedReports.map((report) => {
    return {
      key: report.uid,
      label: <ReportCardLabel report={report} />,
      children: <ReportCard report={report} />,
      extra: <SelectedReportActions report={report} />,
    };
  });

  useEffect(() => {
    if (!selectedReports) {
      navigate(ReportsPath);
    } else {
      const arrForSort = [...selectedReports];
      setSortedReports(sortReports(arrForSort, direction));
    }
  }, [selectedReports, direction]);

  return (
    <Flex vertical gap={16} style={{ padding: '0px 16px' }}>
      <Flex gap={16} justify="space-between">
        <ReportsActionsDashboard />
        <ConfigProvider theme={{ token: { colorPrimary: '#D0D5DD66' } }}>
          <Select
            defaultValue={EDirection.ASC}
            value={direction}
            onChange={(direction) =>
              dispatch(setSelectedReportsDirection(direction))
            }
            options={[
              { value: EDirection.DESC, label: t('sortSelect.newest') },
              { value: EDirection.ASC, label: t('sortSelect.oldest') },
            ]}
          />
        </ConfigProvider>
      </Flex>
      <Flex vertical style={{ width: '100%', padding: '4px 16px' }}>
        <Collapse accordion items={items} />
      </Flex>
    </Flex>
  );
};

export default SelectedReports;
