import { useEffect, useState } from 'react';
import { TMapLevel, TSquareId } from '@svitlofour/types';
import { getVisibleParentSquareIds } from '@svitlofour/map';

import {
  getMarkerList,
  setSquaresStatus,
  useAppDispatch,
  useAppSelector,
} from 'store';
import {
  _activeMapCenter,
  _activeWindowSize,
  _level,
  _levelMarkers,
} from 'store/selectors';

import { updateMarkersList } from 'helpers/services/markersListService';

import { MapMarkersSquares } from '../MapMarkersSquares';
import { isArraysEqual } from 'shared/helpers/isArraysEqual';

export const MapMarkers = () => {
  const dispatch = useAppDispatch();

  const [activeParentSquares, setActiveParentSquares] = useState<TSquareId[]>(
    []
  );
  const [activeSquaresLevelUp, setActiveSquaresLevelUp] = useState<TSquareId[]>(
    []
  );
  const [activeSquaresLevelDown, setActiveSquaresLevelDown] = useState<
    TSquareId[]
  >([]);

  const [levelUp, setLevelUp] = useState<TMapLevel>(21);
  const [levelDown, setLevelDown] = useState<TMapLevel>(0);

  const level = useAppSelector(_level);
  const center = useAppSelector(_activeMapCenter);
  const windowSize = useAppSelector(_activeWindowSize);
  const data = useAppSelector(_levelMarkers(level));
  const dataLevelUp = useAppSelector(_levelMarkers(levelUp));
  const dataLevelDown = useAppSelector(_levelMarkers(levelDown));

  useEffect(() => {
    if (level === 0 || level === 21) {
      return;
    }
    setLevelUp((level + 1) as TMapLevel);
    setLevelDown((level - 1) as TMapLevel);
  }, [level]);

  useEffect(() => {
    if (!center || !level) {
      return;
    }
    if (
      !isArraysEqual(
        activeParentSquares,
        getVisibleParentSquareIds(center, level, windowSize)
      )
    ) {
      setActiveParentSquares(
        getVisibleParentSquareIds(center, level, windowSize)
      );
    }

    if (
      levelUp &&
      !isArraysEqual(
        activeSquaresLevelUp,
        getVisibleParentSquareIds(center, levelUp, windowSize)
      )
    ) {
      setActiveSquaresLevelUp(
        getVisibleParentSquareIds(center, levelUp, windowSize)
      );
    }
    if (
      levelDown &&
      !isArraysEqual(
        activeSquaresLevelDown,
        getVisibleParentSquareIds(center, levelDown, windowSize)
      )
    ) {
      setActiveSquaresLevelDown(
        getVisibleParentSquareIds(center, levelDown, windowSize)
      );
    }
  }, [center, level, windowSize.width, windowSize.height]);

  useEffect(() => {
    if (!activeParentSquares.length || !level) {
      return;
    }

    const updateSquareIds = updateMarkersList(activeParentSquares, data);

    if (!updateSquareIds.length) {
      return;
    }

    dispatch(
      setSquaresStatus({ level, id: updateSquareIds, value: Date.now() })
    );

    if (updateSquareIds.length > 0) {
      dispatch(getMarkerList({ level, id: updateSquareIds }));
    }

    if (!levelUp || !levelDown) {
      return;
    }
    const updateSquareIdsLevelUp = updateMarkersList(
      activeSquaresLevelUp,
      dataLevelUp
    );
    const updateSquareIdsLevelDown = updateMarkersList(
      activeSquaresLevelDown,
      dataLevelDown
    );

    dispatch(
      setSquaresStatus({
        level: levelUp,
        id: updateSquareIdsLevelUp,
        value: Date.now(),
      })
    );
    dispatch(
      setSquaresStatus({
        level: levelDown,
        id: updateSquareIdsLevelDown,
        value: Date.now(),
      })
    );

    if (updateSquareIdsLevelUp && updateSquareIdsLevelUp.length > 0) {
      dispatch(getMarkerList({ level: levelUp, id: updateSquareIdsLevelUp }));
    }

    if (updateSquareIdsLevelDown && updateSquareIdsLevelDown.length > 0) {
      dispatch(
        getMarkerList({
          level: levelDown,
          id: updateSquareIdsLevelDown,
        })
      );
    }
  }, [activeParentSquares]);

  return (
    <>
      {activeParentSquares && (
        <MapMarkersSquares level={level} squares={activeParentSquares} />
      )}
    </>
  );
};
