import { useLoadScript } from '@react-google-maps/api';

export const useLoadMapGoogle = () => {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  return isLoaded;
};
