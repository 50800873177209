import styled from 'styled-components';
import { Button } from 'antd';

import { colors } from 'theme';

export const SButton = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.subtext};
  border: 1px solid ${colors.grey40};

  &&& {
    width: 48px;
    height: 48px;
    outline: none;
  }

  &&&:hover {
    border: 2px solid ${colors.grey40};
  }
`;
