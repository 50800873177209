import styled from 'styled-components';
import { Button } from 'antd';

export const SLoginText = styled.span`
  cursor: pointer;
  color: #667085;
  font-weight: 500;
`;

export const SSubmitButton = styled(Button)`
  height: 40px;
  background-color: #111111;
  font-weight: 500;
  margin-top: 30px;

  &&&:hover {
    background-color: #111111;
  }
`;

export const SCancelButton = styled(Button)`
  height: 40px;
  font-weight: 500;
  margin-top: 30px;
`;
