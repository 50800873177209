import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import rootReducer from './rootReducer';

import {
  myReviewsApi,
  observersApi,
  hashtagsApi,
  filterApi,
  locationsApi,
  commentApi,
  reviewAgreeApi,
  reviewApi,
  photosApi,
  complaintApi,
  notificationApi,
  companiesApi,
  companyReportsApi,
  userDataApi,
  companyUsersApi,
} from './queries';
import { TAppDispatch, TRootState } from './types';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDM) =>
    getDM()
      .concat(myReviewsApi.middleware)
      .concat(observersApi.middleware)
      .concat(hashtagsApi.middleware)
      .concat(filterApi.middleware)
      .concat(locationsApi.middleware)
      .concat(commentApi.middleware)
      .concat(reviewAgreeApi.middleware)
      .concat(reviewApi.middleware)
      .concat(photosApi.middleware)
      .concat(complaintApi.middleware)
      .concat(notificationApi.middleware)
      .concat(companiesApi.middleware)
      .concat(companyReportsApi.middleware)
      .concat(userDataApi.middleware)
      .concat(companyUsersApi.middleware),
});

export const useAppDispatch = () => useDispatch<TAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;
