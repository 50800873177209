import { Tooltip } from 'antd';
import { SButton, SDisableButton } from './style';
import { IActionButtonProps } from './types';

//TODO ADD TO LIBS
export const ActionButton = ({
  disabled,
  titleTooltip,
  icon,
  onClick,
}: IActionButtonProps) => {
  if (disabled) {
    return (
      <SDisableButton justify="center" align="flex-start">
        {icon}
      </SDisableButton>
    );
  }
  return (
    <Tooltip
      title={titleTooltip}
      color="#d9d9d9"
      placement="topRight"
      overlayInnerStyle={{ color: '#000000' }}
    >
      <SButton size="large" icon={icon} onClick={onClick} />
    </Tooltip>
  );
};
