import { styled } from 'styled-components';
import { Flex } from 'antd';

import { metrics } from 'theme';

export const SSmallDrawerWrapper = styled(Flex)`
  position: absolute;
  z-index: 1000;
  //top: 75px;
  bottom: 25px;
  right: 25px;
  background-color: white;
  cursor: pointer;
  width: 300px;
    border-radius: 15px;

  @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
    width: 250px;
    right: 30px;
  }

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    width: 200px;
  }

  @media screen and (max-width: ${metrics.mediaLargePhoneSize}px) {
    width: 150px;
    right: 20px;
  }
`;

export const SCloseButtonWrapper = styled.div`
  position: absolute;
  top: -55px;
  right: 0px;
`;
