import { Flex } from 'antd';
import { markerConfig } from 'config';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

import { MarkerData } from 'shared/MarkerSvg';

import { IMarkerProps } from './types';

export const LeafletMarker = ({
  marker,
  scale,
  handlerMarkerClick,
}: IMarkerProps) => {
  const { R, Y, G } = MarkerData({ data: marker });

  const getMarker = (color: typeof R): L.DivIcon => {
    return L.divIcon({
      iconAnchor: [
        (scale * markerConfig.defaultMarkerSize) / 2,
        (scale * markerConfig.defaultMarkerSize) / 2,
      ],
      className: 'hidden',
      html: `
          <svg 
            width="${markerConfig.defaultMarkerSize * scale}" 
            height="${markerConfig.defaultMarkerSize * scale}" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${color.path}"
              fill="${color.fill}"
              fill-opacity="1"
              stroke="${color.fill}"
              stroke-width="0"
              style="scale: ${scale}"
            />
          </svg>`,
    });
  };

  return (
    <>
      <Flex key={marker.id}>
        {/*<Marker*/}
        {/*  position={[marker.latitude, marker.longitude]}*/}
        {/*/>*/}
        {marker.G > 0 && (
          <Marker
            position={[marker.latitude, marker.longitude]}
            icon={getMarker(G)}
            opacity={0.9}
            eventHandlers={{
              click: handlerMarkerClick,
            }}
          />
        )}
        {marker.Y > 0 && (
          <Marker
            position={[marker.latitude, marker.longitude]}
            icon={getMarker(Y)}
            opacity={0.9}
            eventHandlers={{
              click: handlerMarkerClick,
            }}
          />
        )}
        {marker.R > 0 && (
          <Marker
            position={[marker.latitude, marker.longitude]}
            icon={getMarker(R)}
            opacity={0.9}
            eventHandlers={{
              click: handlerMarkerClick,
            }}
          />
        )}
      </Flex>
    </>
  );
};
