import { mapConfig } from 'config';
import { useEffect, useState } from 'react';
import { getReportsBounds } from 'helpers';
import { useReportsContext } from '../../../ReportsContext';
import { ReportsGoogleMap } from '../ReportsGoogleMap';
import { _selectedReports, useAppSelector } from 'store';

export const LoadReportsGoogleMap = () => {
  const selectedReports = useAppSelector(_selectedReports);
  const { defaultZoom, initialCenterMap } = mapConfig;
  const [bounds, setBounds] = useState<
    google.maps.LatLngBoundsLiteral | undefined
  >(undefined);

  const { isShowSelectedReports, reports, setReport } = useReportsContext();

  useEffect(() => {
    if (!reports?.length) {
      setReport(null);
      return;
    }
    if (isShowSelectedReports) {
      setBounds(getReportsBounds(selectedReports));
      return;
    }
    setBounds(getReportsBounds(reports));
  }, [reports, isShowSelectedReports]);

  return (
    <ReportsGoogleMap
      initialZoom={defaultZoom}
      initialCenter={initialCenterMap}
      bounds={bounds}
    />
  );
};
