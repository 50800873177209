import { mapConfig } from 'config';
interface IUseMapType {
  isGoogle: boolean
  isLeaflet: boolean
}

export const useMapType = (): IUseMapType => {
  const { google, leaflet } = mapConfig.mapType;

  return {
    isGoogle: google,
    isLeaflet: !google || leaflet,
  };
};
