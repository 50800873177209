import { getPhoto } from 'shared/helpers/getPhoto';
import { APP_URL } from 'envConfig';

const newRow = '%0A';

export const telegramShareUrl = (
  uid?: string,
  text?: string | null,
  urls?: string[]
) => `
  https://telegram.me/share/url?
  text=Open this review on the map in Svitlofour.
  ${newRow}${text ?? ''}${newRow}${urls && getPhoto(urls[0])}
  &url=${APP_URL}/?uid=${uid}
`;

export const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${APP_URL}/?uid=`;
