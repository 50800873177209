import { useEffect, useState } from 'react';
import { IGeoCoordinates } from '@svitlofour/types/map';

export const useGeolocation = (): IGeoCoordinates | undefined => {
  const [userGeo, setUserGeo] = useState<IGeoCoordinates>();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserGeo({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  return userGeo;
};
