import { useEffect, useState } from 'react';
import { Tween, update } from '@tweenjs/tween.js';

import {
  setActiveIsAnimatedView,
  setActiveShowReviewMarker,
  setMarkersVisible,
  useAppDispatch,
  useAppSelector,
} from 'store';
import {
  _activeIsAnimatedView,
  _activeReviewUid,
  _getDataReview,
} from 'store/selectors';

import { useReviewDrawer } from './useReviewDrawer';

interface IUseDeepLinkingGoogle {
  hideReview: () => void;
}

const startZoomLevel = 4;
const destinationZoomLevel = 17;
const animationDuration = 4.5 * 1000;

export const useDeepLinkingGoogle = (
  map: google.maps.Map | undefined
): IUseDeepLinkingGoogle => {
  const dispatch = useAppDispatch();
  const { openDrawer, closeDrawer } = useReviewDrawer();
  const [isCheckZoom, setIsCheckZoom] = useState(false);

  const activeReviewUid = useAppSelector(_activeReviewUid);
  const isAnimatedView = useAppSelector(_activeIsAnimatedView);
  const review = useAppSelector(_getDataReview(activeReviewUid));

  const hideReview = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (isAnimatedView && review) {
      dispatch(setActiveIsAnimatedView(false));
      setIsCheckZoom(true);
      dispatch(setMarkersVisible(false));
      const cameraOptions: google.maps.CameraOptions = {
        tilt: 0,
        heading: 0,
        zoom: startZoomLevel,
        center: { lat: review.latitude, lng: review.longitude },
      };

      new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
        .to(
          { tilt: 65, heading: 90, zoom: destinationZoomLevel },
          animationDuration
        ) // Move to destination
        // .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          map?.moveCamera(cameraOptions);
        })
        .start(1000);

      // Setup the animation loop.
      const animate = (time: number) => {
        requestAnimationFrame(animate);
        update(time);
      };

      requestAnimationFrame(animate);
    }
  }, [review]);

  useEffect(() => {
    if (isCheckZoom && (map?.getZoom() || 0) === destinationZoomLevel) {
      setIsCheckZoom(false);
      openDrawer();
      dispatch(setActiveShowReviewMarker(true));
    }
  }, [isCheckZoom, map?.getZoom()]);

  return {
    hideReview,
  };
};
