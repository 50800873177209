import { useMapType } from 'shared/hooks';
import { MapGoogleReviewMarker, MapLeafletReviewMarker } from './components';

export const MapReviewMarker = () => {
  const { isLeaflet } = useMapType();

  if (isLeaflet) {
    return <MapLeafletReviewMarker />;
  }

  return <MapGoogleReviewMarker />;
};
