import styled, { css } from 'styled-components';
import { Divider, Flex } from 'antd';

import { colors } from 'theme';

export const SWrapper = styled(Flex)<{ isLoading: boolean }>`
  background-color: ${colors.white};
  padding: 0 12px;
  border-radius: 8px;
  max-height: 500px;
  overflow: ${({ isLoading }) => !isLoading && 'auto'};
`;

const initialStickyStyles = css`
  position: sticky;
  background-color: ${colors.white};
  z-index: 1;
`;

export const SHeader = styled(Flex)`
  ${initialStickyStyles};
  top: 0px;
  padding-top: 8px;
`;

export const SFooter = styled(Flex)`
  ${initialStickyStyles};
  bottom: 0px;
  padding-bottom: 8px;
`;

export const SDivider = styled(Divider)`
  margin: 0;
`;
