import { Form, Input, ConfigProvider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHeaderContext } from 'view/Header/components/HeaderContext';

import { SSubmitButton, SCancelButton } from './style';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const { setIsForgotPassword } = useHeaderContext();
  return (
    <Form name="forgot-password" onFinish={() => setIsForgotPassword(false)}>
      <Form.Item
        name="email"
        style={{ marginBottom: 5 }}
        rules={[{ required: true, type: 'email' }]}
      >
        <Input placeholder={t('auth.email')} />
      </Form.Item>
      <ConfigProvider wave={{ disabled: true }}>
        <Flex justify="space-between">
          <SSubmitButton htmlType="submit" type="primary">
            {t('auth.sendPassword')}
          </SSubmitButton>
          <SCancelButton type="text" onClick={() => setIsForgotPassword(false)}>
            {t('auth.cancel')}
          </SCancelButton>
        </Flex>
      </ConfigProvider>
    </Form>
  );
};

export default ForgotPasswordForm;
