import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IDeleteCompanyReportsRequest,
  IDeleteCompanyReportsResponse,
  IGetCompanyReportsRequest,
  IGetCompanyReportsResponse,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../../client';
import { version } from '../../client/constants';
import { ECompanyReportsTagType } from './types';

export const companyReportsApi = createApi({
  reducerPath: 'companyReportsApi',
  tagTypes: [ECompanyReportsTagType.COMPANY_REPORTS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getCompanyReports: build.query<
      IGetCompanyReportsResponse,
      IGetCompanyReportsRequest
    >({
      query: (params) => ({
        url: `${version}/company-reports`,
        params,
      }),
      providesTags: () => [ECompanyReportsTagType.COMPANY_REPORTS],
    }),
    deleteCompanyReports: build.mutation<
      IDeleteCompanyReportsResponse,
      IDeleteCompanyReportsRequest
    >({
      query: (body) => ({
        url: `${version}/company-reports`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [ECompanyReportsTagType.COMPANY_REPORTS],
    }),
  }),
});

export const { useGetCompanyReportsQuery, useDeleteCompanyReportsMutation } =
  companyReportsApi;
