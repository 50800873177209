import { Button, Flex } from 'antd';
import styled, { css } from 'styled-components';

const hoverStyles = css`
  color: unset;
  border: 0;
  background: none;
  color: #667085;
`;

export const SButton = styled(Button)`
  border: 0;
  background: none;
  box-shadow: none;

  .ant-btn-icon .anticon {
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: #bfbfbf;
    }
  }

  &&& {
    width: 40px;
    height: 40px;
  }

  &&&:hover {
    ${hoverStyles};
  }

  &&&:active {
    ${hoverStyles};
  }
`;

export const SDisableButton = styled(Flex)`
  width: 40px;
  height: 24px;
  cursor: default;
  font-size: 24px;
  color: #bfbfbf;
`;
