import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetUserDataResponse } from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const userDataApi = createApi({
  reducerPath: 'userDataApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getUserData: build.query<IGetUserDataResponse, void>({
      query: () => ({
        url: `${version}/user-data`,
      }),
    }),
  }),
});

export const { useLazyGetUserDataQuery } = userDataApi;
