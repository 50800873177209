import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shared';

import { Auth } from 'modules/auth';

import {
  useMenuItems,
  CustomDrawer,
  LeftSide,
  RightSide,
  useHeaderContext,
} from './components';
import { useSignOut } from './hooks/useSignOut';
import { SWrapper } from './style';
import { setIsDrawerOpen, useAppDispatch } from 'store';
import { useEffect } from 'react';

export const Header = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isModalOpen, showModal, closeModal } = useHeaderContext();

  const { onSignOut, isLoading } = useSignOut();

  const { drawerIsOpen, setDrawerIsOpen, menuItems, burgerMenuItems } =
    useMenuItems(onSignOut);

  useEffect(() => {
    if (drawerIsOpen) {
      dispatch(setIsDrawerOpen(true));
    } else {
      dispatch(setIsDrawerOpen(false));
    }
  }, [drawerIsOpen]);

  return (
    <SWrapper justify="space-between" align="center">
      <LeftSide />
      <RightSide
        isLoading={isLoading}
        menuItems={menuItems}
        burgerMenuItems={burgerMenuItems}
        showModal={showModal}
        closeMenuDrawer={() => setDrawerIsOpen(null)}
      />

      <CustomDrawer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />

      <CustomModal
        title={t('auth.logIn')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      >
        <Auth />
      </CustomModal>
    </SWrapper>
  );
};
