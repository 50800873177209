import { useEffect, useState } from 'react';
import { Map } from 'leaflet';
import {
  setActiveIsAnimatedView,
  setActiveIsDeepLinking,
  setActiveShowReviewMarker,
  setMarkersVisible,
  useAppDispatch,
  useAppSelector,
} from 'store';
import {
  _activeIsAnimatedView,
  _activeReviewUid,
  _getDataReview,
} from 'store/selectors';

import { useReviewDrawer } from './useReviewDrawer';
import { mapConfig } from 'config';

interface IUseDeepLinkingLeaflet {
  hideReview: () => void;
}

export const useDeepLinkingLeaflet = (
  map: Map | undefined
): IUseDeepLinkingLeaflet => {
  const dispatch = useAppDispatch();
  const { openDrawer, closeDrawer } = useReviewDrawer();
  const { defaultMyReviewZoom } = mapConfig;

  const [isCheckZoom, setIsCheckZoom] = useState(false);

  const activeReviewUid = useAppSelector(_activeReviewUid);
  const isAnimatedView = useAppSelector(_activeIsAnimatedView);
  const review = useAppSelector(_getDataReview(activeReviewUid));

  const hideReview = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (isAnimatedView && review && map) {
      dispatch(setActiveIsAnimatedView(false));
      setIsCheckZoom(true);
      dispatch(setMarkersVisible(false));
      map.flyTo([review.latitude, review.longitude], defaultMyReviewZoom);
    }
  }, [review]);

  useEffect(() => {
    if (isCheckZoom && (map?.getZoom() || 0) === defaultMyReviewZoom) {
      setIsCheckZoom(false);
      openDrawer();
      dispatch(setActiveShowReviewMarker(true));
      dispatch(setActiveIsDeepLinking(true));
    }
  }, [isCheckZoom, map?.getZoom()]);

  return {
    hideReview,
  };
};
