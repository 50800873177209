import { useState } from 'react';
import {
  Form,
  type FormProps,
  Input,
  ConfigProvider,
  Flex,
  message,
} from 'antd';
import { signIn, fetchUserAttributes, AuthError } from 'aws-amplify/auth';
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { EPlan } from '@svitlofour/types';

import {
  setIsAuth,
  setUserDataAWS,
  useAppDispatch,
  useLazyGetUserDataQuery,
  setUserData,
  setUserDataIsLoading,
} from 'store';

import {
  SForgotPassword,
  SButton,
  SSocialNetworkCard,
  SDivider,
} from './style';
import { IFormValues } from './types';
import { useTranslation } from 'react-i18next';
import { useHeaderContext } from 'view/Header/components/HeaderContext';

const LoginForm = () => {
  const { t } = useTranslation();
  const { setIsForgotPassword, closeModal } = useHeaderContext();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [getUserData] = useLazyGetUserDataQuery();

  const [isLoading, setIsLoading] = useState(false);

  const fetchUserData = async () => {
    dispatch(setUserDataIsLoading(true));

    await getUserData()
      .unwrap()
      .then((data) => dispatch(setUserData(data)))
      .catch((err) => message.error(err.message));

    dispatch(setUserDataIsLoading(false));
  };

  const onFinish: FormProps<IFormValues>['onFinish'] = async ({
    email,
    password,
  }) => {
    try {
      setIsLoading(true);

      const { isSignedIn } = await signIn({ username: email, password });

      if (isSignedIn) {
        const userAttributes = await fetchUserAttributes();

        dispatch(setIsAuth(isSignedIn));
        dispatch(setUserDataAWS(userAttributes));
        form.resetFields();
        closeModal();

        fetchUserData();
      }
    } catch (err) {
      const authError = err as AuthError;

      message.error(authError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form<IFormValues>
      name="login"
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={t('auth.email')} />
      </Form.Item>
      <Form.Item
        name="password"
        style={{ marginBottom: 5 }}
        rules={[{ required: true }]}
      >
        <Input.Password placeholder={t('auth.password')} />
      </Form.Item>
      <SForgotPassword onClick={() => setIsForgotPassword(true)}>
        {t('auth.forgot')}
      </SForgotPassword>

      <ConfigProvider wave={{ disabled: true }}>
        <SButton loading={isLoading} htmlType="submit" type="primary">
          {t('auth.logIn')}
        </SButton>
      </ConfigProvider>
      <SDivider plain>{t('auth.or')}</SDivider>
      <Flex justify="space-around">
        <SSocialNetworkCard>
          <GoogleOutlined style={{ fontSize: 24 }} />
        </SSocialNetworkCard>
        <SSocialNetworkCard>
          <FacebookOutlined style={{ fontSize: 24 }} />
        </SSocialNetworkCard>
      </Flex>
    </Form>
  );
};

export default LoginForm;
