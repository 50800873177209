import { EDirection, ECardView } from '@svitlofour/types';

import { date, tableParams } from 'shared/constants';

import { IReportsInitialState } from './types';

export const initialState: IReportsInitialState = {
  reportsDirection: undefined,
  selectedReportsDirection: EDirection.ASC,
  listType: ECardView.TILE,
  currentPage: tableParams,
  pickerDate: date,
  selectedReports: [],
};
