import styled from 'styled-components';
import { Card } from 'antd';

import { metrics } from 'theme';

export const SCard = styled(Card)`
  width: 110px;
  height: 150px;

  @media screen and (max-width: ${metrics.mediaMediumPhoneSize}px) {
    width: 155px;
  }

  @media screen and (max-width: ${metrics.mediaSmallPhoneSize}px) {
    width: 130px;
  }

  .ant-card-body {
    padding: 10px 12px;
  }
`;
