import { MarkerF } from '@react-google-maps/api';

import { colors } from 'theme';
import { markerConfig } from 'config';

import { path } from './constants';
import { IMyMarker } from './types';

export const GoogleMyMarker = ({
  uid,
  longitude,
  latitude,
  color,
}: IMyMarker) => {
  const scrollToSelectedItem = () => {
    const element = document.getElementById(`my-review-${uid}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <MarkerF
      position={{
        lng: longitude,
        lat: latitude,
      }}
      icon={{
        anchor: {
          x: markerConfig.myMarkerSize / 2,
          y: markerConfig.myMarkerSize / 2,
        } as google.maps.Point,
        path,
        fillColor: colors[color],
        fillOpacity: 0.9,
        scale: 1,
        strokeColor: colors[color],
        strokeWeight: 1,
      }}
      opacity={1}
      onClick={scrollToSelectedItem}
    />
  );
};
