import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Dropdown } from 'antd';

import {
  useAppDispatch,
  useAppSelector,
  useLazyGetNotificationsQuery,
  setNotificationsData,
  setIsNotificationDrawerOpen,
  setIsNotificationsLoading,
} from 'store';

import { ReactComponent as IconBell } from 'assets/images/icon-bell.svg';

import { DropdownBlock, MyEventsDrawer } from './components';
import { dateFormat } from './constants';
import { SButton } from './style';
import { INotifications } from './types';

export const Notifications = ({ isAuth, closeMenuDrawer }: INotifications) => {
  const dispatch = useAppDispatch();

  const { isLoading, isNotificationDrawerOpen, notificationsData } =
    useAppSelector(({ notifications }) => notifications);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const openDrawer = () => dispatch(setIsNotificationDrawerOpen(true));
  const closeDrawer = () => dispatch(setIsNotificationDrawerOpen(false));

  const [
    getNotifications,
    { data: notificationsList, isLoading: isNativeLoading },
  ] = useLazyGetNotificationsQuery();

  const handleOpenChangeNotifyDropdown = (state: boolean) => {
    if (state) {
      getNotifications({
        // fromDate: dayjs().startOf('year').format(dateFormat),
        fromDate: dayjs().year(2024).format(dateFormat),
        toDate: dayjs().add(1, 'day').format(dateFormat),
        limit: 10,
      })
        .unwrap()
        .then((data) => {
          dispatch(setNotificationsData(data));
        })
        .finally(() => dispatch(setIsNotificationsLoading(isNativeLoading)));
    }
  };

  useEffect(() => {
    dispatch(setIsNotificationsLoading(isNativeLoading));
  }, [isNativeLoading]);

  return (
    <>
      <Dropdown
        dropdownRender={() => (
          <DropdownBlock
            openDrawer={openDrawer}
            closeDropdown={() => setDropdownIsOpen(false)}
            events={notificationsList?.events}
            isLoading={isLoading}
            closeMenuDrawer={closeMenuDrawer}
          />
        )}
        trigger={['click']}
        onOpenChange={handleOpenChangeNotifyDropdown}
        placement="bottomRight"
        open={dropdownIsOpen}
      >
        <SButton
          disabled={!isAuth}
          shape="circle"
          size="large"
          icon={<IconBell />}
          loading={isLoading}
          onClick={() => setDropdownIsOpen((prevState) => !prevState)}
        />
      </Dropdown>
      <MyEventsDrawer
        isDrawerOpen={isNotificationDrawerOpen}
        closeDrawer={closeDrawer}
        myEvents={notificationsData?.events}
        isLoading={isLoading}
      />
    </>
  );
};
