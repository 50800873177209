import styled from 'styled-components';
import { Flex } from 'antd';

import { colors, metrics } from 'theme';

export const SWrapper = styled(Flex)`
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid ${colors.border};
  padding: 12px 40px;
  background-color: ${colors.white};

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    padding: 12px 20px;
  }
`;
