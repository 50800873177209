import { keyframes, styled } from 'styled-components';
import { Flex } from 'antd';

import { colors } from 'theme';

const open = () => keyframes`
  0% {
    bottom: -100px;
  }
  100% { 
    bottom: 62px;
  }
`;

const close = () => keyframes`
  0% {
    bottom: 62px;
  }
  100% {
   bottom: -100px;
  }
`;

export const SLanguagePanel = styled(Flex)<{ $show: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: -100px;
  left: calc(50vw - 148px);
  height: 120px;
  width: 160px;
  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.border};
  cursor: pointer;
  padding: 8px;
  animation: ${({ $show }) => ($show ? open : close)} 0.4s linear forwards;
`;
