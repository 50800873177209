import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetReviewResponse, IGetReviewRequest } from '@svitlofour/types';

import { baseQueryWithoutAuth } from '../client';
import { version } from '../client/constants';

export const reviewApi = createApi({
  reducerPath: 'reviewApi',
  baseQuery: baseQueryWithoutAuth,
  endpoints: (build) => ({
    getReview: build.query<IGetReviewResponse, IGetReviewRequest>({
      query: (params) => ({
        url: `${version}/review`,
        params,
      }),
    }),
  }),
});

export const { useLazyGetReviewQuery } = reviewApi;
