import { markerConfig } from 'config';

const R = markerConfig.myMarkerSize / 2;

export const path = [
  'M',
  0,
  R,
  'a',
  R,
  R,
  0,
  1,
  0,
  R * 2,
  0,
  'a',
  R,
  R,
  0,
  1,
  0,
  -(R * 2),
  0,
].join(' ');
