import { useState, useEffect } from 'react';
import { InnerDrawer, Loader, SmallButton } from '@svitlofour/ui';
import { CloseOutlined } from '@ant-design/icons';
import { Flex, Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  useAppDispatch,
  useAppSelector,
  setIsNotificationsOpen,
  setMarkersVisible,
  setDetailedNotificationCoordinate,
  setIsDrawerOpen,
} from 'store';

import { DetailedMyEvent, MyEvent } from './components';

import { IMyEventsDrawer, INotification } from './types';

export const MyEventsDrawer = ({
  isDrawerOpen,
  closeDrawer,
  myEvents,
  isLoading,
}: IMyEventsDrawer) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDetailed, setIsDetailed] = useState<INotification | null>(null);

  const { isAuth } = useAppSelector(({ auth }) => auth);
  const { isNotificationDrawerOpen } = useAppSelector(
    ({ notifications }) => notifications
  );

  const destroyDrawerStateOnClose = () => {
    closeDrawer();
    setIsDetailed(null);
    dispatch(setIsNotificationsOpen(false));
  };

  useEffect(() => {
    if (!isAuth) {
      destroyDrawerStateOnClose();
      dispatch(setMarkersVisible(true));
    }
  }, [isAuth]);

  useEffect(() => {
    if (!isNotificationDrawerOpen) {
      destroyDrawerStateOnClose();
    }
    dispatch(setIsDrawerOpen(isNotificationDrawerOpen));
  }, [isNotificationDrawerOpen]);

  useEffect(() => {
    if (isDetailed) {
      const { latitude, longitude } = isDetailed.review;

      dispatch(
        setDetailedNotificationCoordinate({ lat: latitude, lng: longitude })
      );
    } else {
      dispatch(setDetailedNotificationCoordinate(null));
    }
  }, [isDetailed]);

  return (
    <InnerDrawer
      title={t('title.notifications')}
      open={isDrawerOpen}
      destroyOnClose
      extra={
        <SmallButton
          onClick={destroyDrawerStateOnClose}
          icon={<CloseOutlined />}
        />
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isDetailed ? (
            <DetailedMyEvent
              eventData={isDetailed}
              closeDetailedEvent={() => setIsDetailed(null)}
            />
          ) : (
            <>
              {myEvents?.length ? (
                <Flex gap={12} vertical>
                  {myEvents?.map((event) => (
                    <Flex key={event.uid} onClick={() => setIsDetailed(event)}>
                      <MyEvent eventData={event} />
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Empty />
              )}
            </>
          )}
        </>
      )}
    </InnerDrawer>
  );
};
