import { ReactComponent as IconLogo } from 'assets/images/icon-logo.svg';

import { useCorporativeCabinet } from 'shared/hooks';

import { MapPath } from 'router/routes/constants';

import { SLogoWrapper, SLogoText } from './style';

export const LeftSide = () => {
  const { onCorporativeCabinetClose } = useCorporativeCabinet(MapPath);

  return (
    <SLogoWrapper align="center" onClick={onCorporativeCabinetClose}>
      <IconLogo />
      <SLogoText align="center" justify="flex-end">
        Svitlofour
      </SLogoText>
    </SLogoWrapper>
  );
};
