import { useMapType } from 'shared/hooks';

import { LeafletMyMarker } from './LeafletMyMarker';
import { GoogleMyMarker } from './GoogleMyMarker';
import { IMyMarker } from './types';

export const MyMarker = (props: IMyMarker) => {
  const { isLeaflet } = useMapType();

  if (isLeaflet) {
    return <LeafletMyMarker {...props} />;
  }

  return <GoogleMyMarker {...props} />;
};
