import { keyframes, styled } from 'styled-components';
import { Flex } from 'antd';

import { metrics, colors } from 'theme';

const open = () => keyframes`
  0% {
    top: -42px;
  }
  100% {
    top: 2px;
  }
`;

const close = () => keyframes`
  0% {
    top: 2px;
  }
  100% {
    top: -42px;
  }
`;

export const SReturnPanel = styled(Flex)<{
  $show: boolean;
}>`
  position: absolute;
  z-index: 10000;
  top: -42px;
  left: 0px;
  height: 40px;
  width: 100%;
  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.border};
  padding: 4px;
  
  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    animation: ${({ $show }) => ($show ? open : close)} 0.6s linear forwards;
`;
