import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetFiltersResponse,
  IPostFilterResponse,
  IPostFilterRequest,
  IDeleteFilterResponse,
  IDeleteFilterRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EFilterTagType } from './types';

export const filterApi = createApi({
  reducerPath: 'filterApi',
  tagTypes: [EFilterTagType.FILTER],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getFilters: build.query<IGetFiltersResponse, void>({
      query: () => ({
        url: `${version}/filters`,
      }),
      providesTags: (result): { type: EFilterTagType.FILTER; id: string }[] =>
        result
          ? [
              ...result.map(({ uid }) => ({
                type: EFilterTagType.FILTER,
                id: uid,
              })),
              { type: EFilterTagType.FILTER, id: 'List' },
            ]
          : [{ type: EFilterTagType.FILTER, id: 'List' }],
    }),
    addFilter: build.mutation<IPostFilterResponse, IPostFilterRequest>({
      query: (body) => ({
        url: `${version}/filter`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: EFilterTagType.FILTER, id: 'List' }],
    }),
    deleteFilter: build.mutation<IDeleteFilterResponse, IDeleteFilterRequest>({
      query: (body) => ({
        url: `${version}/filter`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: EFilterTagType.FILTER, id: 'List' }],
    }),
  }),
});

export const {
  useGetFiltersQuery,
  useAddFilterMutation,
  useDeleteFilterMutation,
} = filterApi;
