export const isUidValid = (value: string | null): boolean => {
  if (!value) {
    return false;
  }

  const parts = value.split('-');

  if (parts.length !== 5) {
    return false;
  }

  if (
    parts[0].length !== 8 ||
    parts[1].length !== 4 ||
    parts[2].length !== 4 ||
    parts[3].length !== 4 ||
    parts[4].length !== 12
  ) {
    return false;
  }

  return true;
};
