import { useState, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EDirection, ECardView } from '@svitlofour/types';
import { useMediaQuery } from 'react-responsive';

import { useAppSelector } from 'store';

import { MyReviewsFilter } from 'modules/cabinet/components/MyReviews/components';

import { IDrawersList, EMenuItems } from '../../Menu/types';

const MyReviews = lazy(() => import('modules/cabinet/components/MyReviews'));
const Observers = lazy(() => import('modules/cabinet/components/Observers'));
const Settings = lazy(() => import('modules/cabinet/components/Settings'));

export const useDrawersList = (
  drawerIsOpen: EMenuItems | null
): IDrawersList[] => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const { isAuth } = useAppSelector(({ auth }) => auth);

  const [listType, setListType] = useState<ECardView>(ECardView.TILE);
  const [direction, setDirection] = useState(EDirection.DESC);

  useEffect(() => {
    if (isMobile) {
      setListType(ECardView.TILE);
    }
  }, [isMobile]);

  const drawersList: IDrawersList[] = [
    {
      title: t('title.myReviews'),
      isOpen: drawerIsOpen === EMenuItems.MY_REVIEWS,
      filterComponent: (
        <MyReviewsFilter
          listType={listType}
          setListType={setListType}
          direction={direction}
          setDirection={setDirection}
        />
      ),
      children: isAuth && (
        <MyReviews listType={listType} direction={direction} />
      ),
    },
    {
      title: t('title.observers'),
      isOpen: drawerIsOpen === EMenuItems.OBSERVERS,
      children: <Observers />,
    },
    {
      title: t('title.settings'),
      isOpen: drawerIsOpen === EMenuItems.SETTINGS,
      children: <Settings />,
    },
  ];

  return drawersList;
};
