import { useMapParams, useMapType } from 'shared/hooks';

import { SMapWrapper } from './style';
import { MapLeaflet } from './components/MapLeaflet';
import { MapGoogle } from './components/MapGoogle';
import { DownLoadAppPanel, ReturnDrawerPanel } from 'shared';
import { useAppSelector } from 'store';

export const MapLoad = () => {
  const { isGoogle, isLeaflet } = useMapType();
  const { zoom, center } = useMapParams();
  const { isSelectGeoposition } = useAppSelector(({ observers }) => observers);
  const { isMyReviewsOnMap } = useAppSelector(({ myReviews }) => myReviews);

  return (
    <SMapWrapper
      $isSmallMap={isMyReviewsOnMap}
      $isSelectGeoposition={isSelectGeoposition}
    >
      <ReturnDrawerPanel />
      {isLeaflet && <MapLeaflet initialZoom={zoom} initialCenter={center} />}
      {isGoogle && <MapGoogle initialZoom={zoom} initialCenter={center} />}
      <DownLoadAppPanel />
    </SMapWrapper>
  );
};
