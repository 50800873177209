import { useEffect } from 'react';
import { DoubleLeftOutlined } from '@ant-design/icons';

import {
  useAppDispatch,
  useAppSelector,
  useLazyGetPhotosQuery,
  setPhotos,
  setIsOpenGallery,
  setMarkerId,
  setSelectedMarker,
  setReviewMarker,
} from 'store';
import {
  _dataPhotos,
  _isOpenGallery,
  _level,
  _selectedMarker,
  _userUid,
} from 'store/selectors';

import { useReviewDrawer } from 'shared/hooks';

import { MapPhotoGallery } from './components';
import {
  SGallery,
  SPhotoBlurLeft,
  SPhotoBlurRight,
  SShevron,
  SShevronButton,
} from './style';

export const MapGallery = () => {
  const dispatch = useAppDispatch();
  const { isOpenDrawer } = useReviewDrawer();
  const userUid = useAppSelector(_userUid);
  const data = useAppSelector(_dataPhotos);
  const level = useAppSelector(_level);
  const isOpenGallery = useAppSelector(_isOpenGallery);
  const selectedMarker = useAppSelector(_selectedMarker);

  const markerId = selectedMarker?.id;
  const [getPhotos] = useLazyGetPhotosQuery();

  const closeGallery = () => {
    dispatch(setIsOpenGallery(false));
    setTimeout(() => {
      dispatch(setMarkerId(null));
      dispatch(setSelectedMarker(null));
      dispatch(setReviewMarker(null));
    }, 1);
  };

  // TODO: move logic to useReviewsSync hook
  useEffect(() => {
    if (!markerId) {
      return;
    }

    if (Object.keys(data).length === 0) {
      getPhotos({ level, markerId, userUid }).then(
        ({ data }) => data && dispatch(setPhotos({ markerId, ...data }))
      );
    } else {
      if (!Object.keys(data).includes(markerId)) {
        getPhotos({ level, markerId, userUid }).then(
          ({ data }) => data && dispatch(setPhotos({ markerId, ...data }))
        );
      }
    }
  }, [markerId]);

  if (!markerId) {
    return null;
  }

  return (
    <SGallery $show={isOpenGallery} $drawerIsOpen={isOpenDrawer}>
      <SShevronButton onClick={closeGallery} justify="center" align="center">
        <SShevron>
          <DoubleLeftOutlined />
        </SShevron>
      </SShevronButton>

      <SPhotoBlurLeft />
      {data[markerId] && (
        <MapPhotoGallery
          level={level}
          markerId={markerId}
          total={data[markerId].total}
          data={data[markerId].photos}
        />
      )}
      {!isOpenDrawer && <SPhotoBlurRight />}
    </SGallery>
  );
};
