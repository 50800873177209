import { useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import { mapConfig } from 'config';

import {
  useAppSelector,
  _isOpenGallery,
  useAppDispatch,
  setMarkerLocation,
} from 'store';

import {
  useDeepLinkingGoogle,
  useLoadMapGoogle,
  useMarkers,
} from 'shared/hooks';

import { MapPhotoDrawer } from '../MapPhotoDrawer';
import { MapMarkers } from '../MapMarkers';
import { MapGallery } from '../MapGallery';
import { MapReviewMarker } from '../MapReviewMarker';
import { MapSelectedMarker } from '../MapSelectedMarker';
import { IMapGoogleProps } from './types';
import { MapControl } from '../MapControl';
import { useMapView } from 'shared/hooks/useMapView';
import { Cabinet } from 'modules/cabinet';

export const MapGoogle = ({ initialCenter, initialZoom }: IMapGoogleProps) => {
  const dispatch = useAppDispatch();
  const { defaultStyle, googleMapOptions } = mapConfig;
  const { zoom, onLoadMap, onZoomChanged, onBoundsChanged } =
    useMapView(initialZoom);

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  useDeepLinkingGoogle(map);

  const { isMyReviewsOpen } = useAppSelector(({ myReviews }) => myReviews);

  const isOpenGallery = useAppSelector(_isOpenGallery);

  const { markersVisible } = useMarkers();

  const isLoaded = useLoadMapGoogle();

  const handleLoadMap = (map: google.maps.Map) => {
    onLoadMap({ mapGoogle: map, initialZoom, initialCenter });
    setMap(map);
  };

  const handleMapClick = (mapProps: google.maps.MapMouseEvent) => {
    if (mapProps.latLng) {
      dispatch(
        setMarkerLocation({
          lat: mapProps.latLng.lat(),
          lng: mapProps.latLng.lng(),
        })
      );
    }
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={defaultStyle}
      options={googleMapOptions}
      center={initialCenter}
      zoom={initialZoom}
      onLoad={(map) => handleLoadMap(map)}
      onBoundsChanged={() => onBoundsChanged(map, true)}
      onZoomChanged={() => onZoomChanged(map)}
      onClick={(mapProps) => handleMapClick(mapProps)}
    >
      {map && (
        <>
          {markersVisible && <MapMarkers />}
          <MapSelectedMarker />
          <MapReviewMarker />
          <Cabinet map={map} />
          <MapControl map={map} zoom={zoom} />
          {isOpenGallery && !isMyReviewsOpen && <MapGallery />}
          {!isMyReviewsOpen && <MapPhotoDrawer />}
        </>
      )}
    </GoogleMap>
  );
};
