import styled from 'styled-components';
import { Layout } from 'antd';

import { metrics } from 'theme';

export const SLayout = styled(Layout)`
  z-index: 5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const handleDrawerSize = (
  $show: boolean,
  $isSelectGeoposition: boolean,
  $isMyReviewsOnMap: boolean
) => {
  if ($show && $isSelectGeoposition) {
    return `
    width: 40% !important;

    @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
      width: 50% !important;
    }
    @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
      width: 0% !important;
    }
  `;
  }
  return `
    width: ${$show ? 40 : 0}% !important;

    @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
      width: ${$show ? 50 : 0}% !important;
    }
    @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
      width: ${$show ? 60 : 0}% !important;
    }
    @media screen and (max-width: ${metrics.mediaTabletSize}px) {
      width: ${$show && !$isMyReviewsOnMap ? 100 : 0}% !important;
    }
  `;
};

export const SBody = styled.div<{
  $show: boolean;
  $isMiniDrawer: boolean;
  $isSelectGeoposition: boolean;
  $isMyReviewsOnMap: boolean;
}>`
  z-index: 0;
  min-height: 400px;
  height: 100%;
  width: 100%;
  position: relative;
  border-style: none;

  .ant-drawer-header {
    border-bottom: 0;
  }

  .ant-drawer-body {
    padding: 0 24px;
    overflow: ${({ $isMiniDrawer }) => ($isMiniDrawer ? 'hidden' : 'auto')};
  }

  .ant-drawer .ant-drawer-content-wrapper {
    box-shadow: none;

    ${({ $show, $isSelectGeoposition, $isMyReviewsOnMap }) =>
      handleDrawerSize($show, $isSelectGeoposition, $isMyReviewsOnMap)};
  }
`;
