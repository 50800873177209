import { IGeoCoordinates } from '@svitlofour/types';
import L from 'leaflet';

export const latLngToGeo = (
  coord: google.maps.LatLng | undefined
): IGeoCoordinates | undefined => {
  if (!coord) {
    return undefined;
  }

  return {
    latitude: coord.lat(),
    longitude: coord.lng(),
  };
};

export const lLatLngToGeo = (
  coord: L.LatLng | undefined
): IGeoCoordinates | undefined => {
  if (!coord) {
    return undefined;
  }

  return {
    latitude: coord.lat,
    longitude: coord.lng,
  };
};
