import { Image, Typography } from 'antd';
import dayjs from 'dayjs';
import { HashTags } from '@svitlofour/ui';
import { IReportCardProps } from './types';
import {
  SDateWrapper,
  SHashTagsWrapper,
  SInfoBlockWrapper,
  SWrapper,
  SEmployeeInfo,
  SImageWrapper,
  SAuthWrapper,
  STextWrapper,
} from './style';
import { EPhotoSizes } from 'shared/types';
import { getPhoto } from 'shared/helpers/getPhoto';
import { useLocation } from 'react-router-dom';
import { ReportsOnMapPath } from 'router/routes/constants';

export const ReportCard = ({ report }: IReportCardProps) => {
  const location = useLocation();
  const isOnMap = location.pathname.includes(ReportsOnMapPath);

  return (
    <SWrapper $isOnMap={isOnMap} gap={6} justify="flex-start">
      <SImageWrapper>
        <Image
          height={280}
          width={280}
          src={getPhoto(report.urls[0], EPhotoSizes.ORIGIN)}
          alt={`report-${report.uid}`}
        />
      </SImageWrapper>
      <SInfoBlockWrapper
        vertical
        gap={6}
        justify="flex-start"
        align="flex-start"
      >
        <SAuthWrapper vertical>
          <SDateWrapper>
            {dayjs(report.createdAt).format('DD MMM, YYYY HH:mm')}
          </SDateWrapper>
          <SEmployeeInfo vertical>
            <Typography.Text>{report.user.name}</Typography.Text>
            <Typography.Text>{report.user.email}</Typography.Text>
          </SEmployeeInfo>
        </SAuthWrapper>
        <STextWrapper>
          {report.text && <Typography.Text>{report.text}</Typography.Text>}
        </STextWrapper>

        <SHashTagsWrapper gap={8}>
          <HashTags hashTags={report.hashTags} />
        </SHashTagsWrapper>
      </SInfoBlockWrapper>
    </SWrapper>
  );
};
