import { setMarkerLocation, useAppDispatch, useAppSelector } from 'store';
import { MapLocationSelect } from 'modules/map/components/MapLocationSelect';
import { MyMarker } from 'modules/map/components/MyMarker';
import { ObserversOnMap } from './components/ObserversOnMap';
import { LocationCircle } from './components/LocationCircle';
import { useMapType } from 'shared/hooks';
import { BoundsLeaflet } from './BoundsLeaflet';
import { BoundsGoogle } from './BoundsGoogle';
import { Marker } from '@react-google-maps/api';
import { ICabinetProps } from './types';

export const Cabinet = ({ map }: ICabinetProps) => {
  const dispatch = useAppDispatch();
  const { isGoogle } = useMapType();
  const { isSelectGeoposition, markerLocation } = useAppSelector(
    ({ observers }) => observers
  );
  const { isMyReviewsOpen, myReviewsData } = useAppSelector(
    ({ myReviews }) => myReviews
  );
  const { isNotificationsOpen, notificationsData } = useAppSelector(
    ({ notifications }) => notifications
  );

  const onMarkerMoved = (e: google.maps.MapMouseEvent) => {
    if (map && e.latLng) {
      dispatch(
        setMarkerLocation({
          lat: e.latLng?.lat(),
          lng: e.latLng?.lng(),
        })
      );
    }
  };

  return (
    <>
      {isGoogle ? <BoundsGoogle map={map} /> : <BoundsLeaflet />}
      {isSelectGeoposition && <MapLocationSelect />}
      {isSelectGeoposition && markerLocation && (
        <Marker
          position={markerLocation}
          draggable
          onDragEnd={(e) => onMarkerMoved(e)}
        />
      )}
      {isNotificationsOpen && !isSelectGeoposition && !isMyReviewsOpen && (
        <>
          {notificationsData?.events.map(
            ({ uid, review: { longitude, latitude, color } }, i) => (
              <MyMarker
                key={`${uid}-${i}`}
                uid={uid}
                longitude={longitude}
                latitude={latitude}
                color={color}
              />
            )
          )}
        </>
      )}
      {isMyReviewsOpen && !isSelectGeoposition && !isNotificationsOpen ? (
        <>
          {myReviewsData.map(({ uid, longitude, latitude, color }, i) => (
            <MyMarker
              key={`${uid}-${i}`}
              uid={uid}
              longitude={longitude}
              latitude={latitude}
              color={color}
            />
          ))}
        </>
      ) : (
        <>
          {/*{map && level && !isSelectGeoposition && (*/}
          {/*  <MapMarkers*/}
          {/*    level={level}*/}
          {/*    center={activeCenter}*/}
          {/*    windowSize={windowSize}*/}
          {/*  />*/}
          {/*)}*/}
        </>
      )}
      {false && <ObserversOnMap />}
      <LocationCircle />
    </>
  );
};
