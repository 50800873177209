import { useMapType } from 'shared/hooks';

import { IMarkerProps } from './types';
import { LeafletMarker } from './LeafletMarker';
import { GoogleMapMarker } from './GoogleMapMarker';

export const Marker = (props: IMarkerProps) => {
  const { isLeaflet } = useMapType();

  if (isLeaflet) {
    return <LeafletMarker {...props} />;
  }

  return <GoogleMapMarker {...props} />;
};
