import { memo } from 'react';
import { IMarker } from '@svitlofour/types';

import {
  setMarkerId,
  setPhoto,
  useAppDispatch,
  setReview,
  setReviewMarker,
  setSelectedMarker,
  useAppSelector,
  setIsOpenGallery,
} from 'store';
import { _markerId } from 'store/selectors';

import { Marker } from 'modules/map/components/Marker';

import { ISquareMarkersProps } from './types';

export const SquareMarkers = memo(function SquareMarkers({
  data,
}: ISquareMarkersProps) {
  const dispatch = useAppDispatch();
  const selectedMarkerId = useAppSelector(_markerId);

  const openGallery = (marker: IMarker) => {
    dispatch(setMarkerId(marker.id));
    dispatch(setSelectedMarker(marker));
    dispatch(setReview(null));
    dispatch(setReviewMarker(null));
    dispatch(setPhoto(null));
    dispatch(setIsOpenGallery(true));
  };

  return (
    <>
      {data &&
        data.map((marker) => {
          if (marker.id) {
            return (
              <Marker
                key={marker.id}
                marker={marker}
                scale={
                  !selectedMarkerId
                    ? 1
                    : marker.id === selectedMarkerId
                    ? 1.3
                    : 0.8
                }
                handlerMarkerClick={() => openGallery(marker)}
              />
            );
          }
        })}
    </>
  );
});
