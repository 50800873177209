import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetPhotosResponse, IGetPhotosRequest } from '@svitlofour/types';

import { baseQueryWithoutAuth } from '../client';
import { version } from '../client/constants';

export const photosApi = createApi({
  reducerPath: 'photosApi',
  baseQuery: baseQueryWithoutAuth,
  endpoints: (build) => ({
    getPhotos: build.query<IGetPhotosResponse, IGetPhotosRequest>({
      keepUnusedDataFor: 20,
      query: (params) => ({
        url: `${version}/photos`,
        params,
      }),
    }),
  }),
});

export const { useLazyGetPhotosQuery } = photosApi;
