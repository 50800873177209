import { useRef, useEffect } from 'react';

interface IUseDropdownTimeOut {
  setIsDropdownOpen: (val: boolean) => void;
}

export const useDropdownTimeOut = ({
  setIsDropdownOpen,
}: IUseDropdownTimeOut) => {
  const timeOutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeOutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 2000);

    return () => {
      if (timeOutRef.current) clearTimeout(timeOutRef.current);
    };
  }, []);

  const onMouseEnter = () => {
    if (timeOutRef.current) clearTimeout(timeOutRef.current);
  };

  const onMouseLeave = () => {
    if (timeOutRef.current) clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 2000);
  };

  return { onMouseEnter, onMouseLeave };
};
