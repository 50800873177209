import { useState } from 'react';
import { Dropdown, Flex } from 'antd';
import { Loader } from '@svitlofour/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';

import { ReactComponent as IconUser } from 'assets/images/icon-user.svg';

import { BurgerMenu } from '../BurgerMenu';
import { Menu } from '../Menu';

import { Notifications } from './components';
import { SLogin, SUserButton, SPlanButton } from './style';
import { IRightSide } from './types';

export const RightSide = ({
  isLoading,
  menuItems,
  burgerMenuItems,
  showModal,
  closeMenuDrawer,
}: IRightSide) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const {
    isAuth,
    userName,
    userData: { plan },
  } = useAppSelector(({ auth }) => auth);

  return (
    <Flex gap={16} align="center" justify="flex-end">
      {isAuth && (
        <SPlanButton>{`${plan[0].toUpperCase()}${plan.slice(1)}`}</SPlanButton>
      )}
      <SLogin align="center" justify="space-between" gap={16}>
        <Notifications isAuth={isAuth} closeMenuDrawer={closeMenuDrawer} />
        <BurgerMenu
          isOpen={isBurgerOpen}
          onClickBurger={() => setIsBurgerOpen((prev) => !prev)}
          menuItems={
            isAuth
              ? burgerMenuItems
              : [
                  {
                    title: t('title.logIn'),
                    onClick: showModal,
                    icon: <IconUser style={{ fontSize: 18 }} />,
                  },
                ]
          }
        />

        {isAuth ? (
          <Dropdown
            open={isDropdownOpen}
            destroyPopupOnHide
            dropdownRender={() => (
              <Menu
                menuItems={menuItems}
                setIsDropdownOpen={setIsDropdownOpen}
              />
            )}
            placement="bottomRight"
            trigger={['click']}
          >
            <SUserButton onClick={() => setIsDropdownOpen((prev) => !prev)}>
              {isLoading ? <Loader /> : userName && userName[0].toUpperCase()}
            </SUserButton>
          </Dropdown>
        ) : (
          <SUserButton onClick={showModal}>
            <IconUser />
          </SUserButton>
        )}
      </SLogin>
    </Flex>
  );
};
