import { Button, Typography } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';

import { SReturnPanel } from './style';
import { useTranslation } from 'react-i18next';
import { setIsMyReviewsOnMap, useAppDispatch, useAppSelector } from 'store';

export const ReturnDrawerPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMyReviewsOnMap } = useAppSelector(({ myReviews }) => myReviews);

  const onClose = () => {
    dispatch(setIsMyReviewsOnMap(false));
  };

  return (
    <SReturnPanel
      justify="flex-end"
      align="center"
      gap={8}
      $show={isMyReviewsOnMap}
    >
      <Typography.Text>{t('myReviews.return')}</Typography.Text>
      <Button type="text" icon={<RightCircleOutlined />} onClick={onClose} />
    </SReturnPanel>
  );
};
