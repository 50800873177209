import { useMapBounds } from 'shared/hooks';
import { useMap } from 'react-leaflet';

export const BoundsLeaflet = () => {
  const map = useMap();
  const { onFitBoundsLeaflet } = useMapBounds();
  
  onFitBoundsLeaflet(map);

  return null;
};
