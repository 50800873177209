import {
  useAppDispatch,
  myReviewsApi,
  observersApi,
  hashtagsApi,
  filterApi,
  locationsApi,
  commentApi,
  reviewAgreeApi,
  reviewApi,
  photosApi,
  complaintApi,
  notificationApi,
  companiesApi,
  companyReportsApi,
  userDataApi,
  companyUsersApi,
} from 'store';

export const useResetApiState = () => {
  const dispatch = useAppDispatch();

  const apiList = [
    myReviewsApi,
    observersApi,
    hashtagsApi,
    filterApi,
    locationsApi,
    commentApi,
    reviewAgreeApi,
    reviewApi,
    photosApi,
    complaintApi,
    notificationApi,
    companiesApi,
    companyReportsApi,
    userDataApi,
    companyUsersApi,
  ];

  const resetApiState = () => {
    apiList.forEach((api) => {
      dispatch(api.util.resetApiState());
    });
  };

  return { resetApiState };
};
