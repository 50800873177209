import { createContext, useState, ReactNode, useContext } from 'react';
import { IHeaderContext } from './types';

export const HeaderContext = createContext<IHeaderContext | null>(null);

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsForgotPassword(false);
    setIsModalOpen(false);
  };

  const value = {
    isModalOpen,
    isForgotPassword,
    setIsForgotPassword,
    showModal,
    closeModal,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  const headerContext = useContext(HeaderContext);

  if (!headerContext) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }

  return headerContext;
};
