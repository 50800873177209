import { useMap } from 'react-leaflet';
import { _isOpenGallery, useAppSelector } from 'store';
import { mapConfig } from 'config';
import { MapControlButtons } from './MapControlButtons';
import { SMapControl } from './style';

export const LeafletControl = () => {
  const map = useMap();
  const { geoposition, isReviewDrawerSmall, isDrawerOpen } = useAppSelector(
    ({ active }) => active
  );
  const { isMyReviewsOnMap } = useAppSelector(({ myReviews }) => myReviews);
  const isOpenGallery = useAppSelector(_isOpenGallery);

  const { defaultMinZoom, defaultMaxZoom, defaultGeopositionZoom } = mapConfig;

  const moveToCurrentGeoposition = () => {
    if (geoposition) {
      // TODO: add animation t move to the new center
      map.flyTo(geoposition, defaultGeopositionZoom, { animate: true });
    }
  };

  return (
    <SMapControl
      $show={isOpenGallery}
      $isMiniDrawer={isReviewDrawerSmall}
      $isDrawer={isDrawerOpen && !isMyReviewsOnMap}
      vertical
      justify="space-between"
      align="center"
    >
      <MapControlButtons
        zoomIn={() => map.getZoom() < defaultMaxZoom - 2 && map.zoomIn(1)}
        zoomOut={() => map.getZoom() > defaultMinZoom && map.zoomOut(1)}
        moveToMyPosition={moveToCurrentGeoposition}
        withPosition={!!geoposition}
      />
    </SMapControl>
  );
};
