import Cookies from 'js-cookie';
import { currentPosition } from '../config';

export const setCookie = (
  userCurrentPosition:
    | google.maps.LatLng
    | google.maps.LatLngLiteral
    | undefined
) => {
  if (userCurrentPosition !== undefined) {
    Cookies.set(currentPosition.key, JSON.stringify(userCurrentPosition), {
      expires: 365,
    });
  }
};
