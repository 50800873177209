import styled from 'styled-components';
import { Flex } from 'antd';

import { metrics } from 'theme';

export const SObserversWrapper = styled(Flex)`
  position: absolute;
  right: 16px;
  top: 16px;

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    display: none;
  }
`;
