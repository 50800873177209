import { styled, css } from 'styled-components';
import { Button, Flex } from 'antd';

import { colors } from 'theme';

const commentHeaderBasicStyles = css`
  padding-inline-end: 8px;
  font-size: 12px;
  line-height: 1.6666666666666667;
`;

const basicButtonsStyles = css`
  border: none;
  box-shadow: none;
  font-size: 16px;
`;

export const SWrapper = styled(Flex)`
  margin-top: 12px;
`;
export const SHeader = styled.span`
  color: ${colors.subtext};
`;

export const SAuthor = styled(Flex)`
  ${commentHeaderBasicStyles};
  color: ${colors.black};
  font-weight: 700;
`;

export const SDropdownBody = styled(Flex)`
  box-shadow: 0 2px 4px #00000040;
  padding: 10px;
  borderradius: 8px;
  background-color: ${colors.white};
`;

export const STime = styled(Flex)`
  ${commentHeaderBasicStyles};
  color: ${colors.subtext};
  font-weight: 500;
`;

export const SIconBlock = styled(Flex)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${colors.grey40};
  color: ${colors.greyBlue};
`;

export const SReportButton = styled(Button)`
  color: red;
  ${basicButtonsStyles};

  &&&:hover {
    color: #ee6b6e;
  }
`;

export const SActionsButton = styled(Button)`
  ${basicButtonsStyles};

  &&&:hover {
    color: ${colors.subtext};
  }
`;
