import { useMapBounds } from 'shared/hooks';

interface IBoundsGoogleProps {
  map?: google.maps.Map | undefined;
}

export const BoundsGoogle = ({ map }: IBoundsGoogleProps) => {
  const { onFitBoundsGoogle } = useMapBounds();
  
  onFitBoundsGoogle(map);

  return null;
};
