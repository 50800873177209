export enum EHashtagTagType {
  HASHTAGS = 'hashtags',
}

export enum ELocationsTagType {
  LOCATIONS = 'locations',
}

export enum EFilterTagType {
  FILTER = 'filter',
}

export enum EObserverTagType {
  OBSERVERS = 'observers',
}
