import { Flex, Typography } from 'antd';

import { useGetFiltersQuery } from 'store';

import { SCard } from './style';
import { ICard } from './types';

export const EventCard = ({
  filterUid,
  isEmpty,
  setIsEditFilterVisible,
}: ICard) => {
  const { data: filtersData } = useGetFiltersQuery();

  const filter = filtersData?.find((item) => item.uid === filterUid);

  return (
    <SCard hoverable={!!filterUid} onClick={setIsEditFilterVisible}>
      <Flex justify="center" align="center">
        {isEmpty ? (
          <Typography.Text disabled>Empty</Typography.Text>
        ) : (
          <Typography.Text>{filter?.title}</Typography.Text>
        )}
      </Flex>
    </SCard>
  );
};
