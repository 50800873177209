import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Flex, Tabs, TabsProps, Typography } from 'antd';

import { _selectedReports, useAppSelector } from 'store';

import {
  ReportsOnMapPath,
  ReportsPath,
  SelectedReportsPath,
} from 'router/routes/constants';

import { EReportsPages } from './types';
import { colors } from 'theme';

const Reports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedReports = useAppSelector(_selectedReports);

  const activeKey = location.pathname.includes(ReportsOnMapPath)
    ? EReportsPages.REPORTS_ON_MAP
    : location.pathname.includes(SelectedReportsPath)
    ? EReportsPages.SELECTED_REPORTS
    : EReportsPages.REPORTS;

  const items: TabsProps['items'] = [
    {
      key: EReportsPages.REPORTS,
      label: t('sidebar.reports'),
      children: <Outlet />,
    },
    {
      key: EReportsPages.REPORTS_ON_MAP,
      label: t('reports.reportsOnMap'),
      children: <Outlet />,
    },
    {
      key: EReportsPages.SELECTED_REPORTS,
      label: (
        <Flex style={{ width: '100%' }}>
          <Badge count={selectedReports?.length} offset={[10, 0]}>
            <Typography.Text disabled={Boolean(!selectedReports?.length)}>
              {t('reports.selectedReports')}
            </Typography.Text>
          </Badge>
        </Flex>
      ),
      children: <Outlet />,
      disabled: !selectedReports?.length,
    },
  ];

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <Flex
      style={{
        zIndex: 1,
        position: 'sticky',
        top: 0,
        height: '70px',
        backgroundColor: colors.white,
        padding: '0px 16px',
      }}
    >
      <DefaultTabBar {...props} style={{ width: '100%' }} />
    </Flex>
  );

  return (
    <Tabs
      activeKey={activeKey}
      renderTabBar={renderTabBar}
      onChange={(key) => {
        if (key === EReportsPages.REPORTS) {
          navigate(ReportsPath);
        }
        if (key === EReportsPages.REPORTS_ON_MAP) {
          navigate(ReportsOnMapPath);
        }
        if (key === EReportsPages.SELECTED_REPORTS) {
          navigate(SelectedReportsPath);
        }
      }}
      items={items}
    />
  );
};

export default Reports;
