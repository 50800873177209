import { appRoutesList } from 'router/routes/config';
import { RouteObject } from 'react-router-dom';
import { useAppSelector } from 'store';

export const useUserRouter = () => {
  const { plan } = useAppSelector(({ auth: { userData } }) => userData);

  const getMenuItemsList = () => {
    return appRoutesList.filter((route) => route.plan.includes(plan));
  };

  const getRouterChildren = (): RouteObject[] => {
    return appRoutesList
      .filter((route) => route.plan.includes(plan))
      .map(({ index, path, element, children, loader }) => {
        if (!index) {
          return {
            path,
            element,
            children,
          };
        }
        return {
          index,
          element,
          loader,
        };
      });
  };

  return {
    plan,
    getMenuItemsList,
    getRouterChildren,
  };
};
