import { colors } from '../colors';

export const components = {
  Input: {
    activeBorderColor: colors.black,
    activeShadow: colors.black,
    hoverBorderColor: colors.black,
  },
  Select: {
    // optionSelectedBg: colors.text,
    colorPrimary: colors.black,
    colorPrimaryHover: colors.black,
    controlOutline: 'rgba(171, 171, 171, 0)',
    optionActiveBg: colors.grey40,
  },
  Button: {
    colorPrimary: colors.black,
    colorPrimaryHover: colors.black,
    colorPrimaryActive: colors.black,
  },
  Checkbox: {
    colorPrimary: colors.black,
    colorPrimaryHover: colors.grey40,
  },
};
