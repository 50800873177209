export const MapPath = '/';
export const DashboardPath = '/dashboard';
export const MyCompanyPath = '/my-company';
export const EmployeesPath = 'employees';
export const ReportsPath = '/reports';
export const ReportsOnMapPath = 'reports-on-map';
export const SelectedReportsPath = 'selected-reports';
export const ObserversPath = '/observers';
export const PricesPath = '/prices';
export const FinancePath = '/finance';
