import styled from 'styled-components';
import { Button, Flex, Divider } from 'antd';

import { colors } from 'theme';

export const SForgotPassword = styled.span`
  cursor: pointer;
  color: ${colors.subtext};
  font-weight: 500;
`;

export const SButton = styled(Button)`
  width: 100%;
  height: 40px;
  background-color: ${colors.black};
  font-weight: 500;
  margin-top: 30px;

  &&&:hover {
    background-color: ${colors.black};
  }
`;

export const SDivider = styled(Divider)`
  margin: 0;
`;

export const SSocialNetworkCard = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 10px;
  width: 40%;
`;
