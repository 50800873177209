import dayjs from 'dayjs';
import { IDateRangeValue, IPaginationPagesLimit, IReportsLimit } from './types';

export const dateFormat = 'YYYY-MM-DD';

export const date: IDateRangeValue = {
  fromDate: dayjs().startOf('year').format(dateFormat),
  toDate: dayjs().format(dateFormat),
};

export const tableParams: IPaginationPagesLimit = {
  limit: 10,
  page: 1,
};

export const reportsMapParams: IReportsLimit = {
  limit: 1000,
};

export enum EComplaintsVariants {
  SEXUAL = 'sexual', // контент сексуального характеру
  INTOLERANCE = 'intolerance', // Порушення прав людей
  CONTENT = 'content', // Обман, читинг и тд...
  OUT_OF_LAW = 'out-of-law', // Пожаловаться на контент, который нарушает закон
}
