import { EventCard } from '../EventCard';

export const renderEmptyLocationCard = (count: number) => {
  const emptyCards = [];

  for (let i = 1; i <= 4 - count; i++) {
    emptyCards.push(<EventCard isEmpty key={i} />);
  }

  return emptyCards;
};
