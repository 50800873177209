import axios from 'axios';
import { SERVER_URL } from './constants';
import {
  IAppGetMarkersResponse,
  TMapLevel,
  TSquareId,
} from '@svitlofour/types';

const instance = axios.create({
  baseURL: SERVER_URL,
});

export const getMarkers = async (
  level: TMapLevel,
  activeParentSquares: Array<TSquareId>
): Promise<IAppGetMarkersResponse> => {
  const response = await instance.get(SERVER_URL + '/v1/markers', {
    params: { level, id: activeParentSquares },
  });
  if (response.status !== 200) {
    throw Error(response.data);
  }
  return response.data;
};
