import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, RangePicker } from '@svitlofour/ui';
import { Button, ConfigProvider, Empty, Flex, Pagination, Select } from 'antd';
import { EDirection } from '@svitlofour/types';
import dayjs, { Dayjs } from 'dayjs';

import {
  _pickerDate,
  _reportsCurrentPage,
  _reportsDirections,
  setCurrentPage,
  setReportsDirection,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { CustomModal } from 'shared';
import { useReportsContext } from '../../../ReportsContext';
import { ReportsActionsDashboard } from '../../../ReportsActionsDashboard';
import { IRangeParams, IReportsCardsProps } from './types';
import { colors } from 'theme';
import { ReportItems } from './components';

export const ReportsResponsiveTable = ({
  data,
  isFetching,
}: IReportsCardsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const direction = useAppSelector(_reportsDirections);
  const { fromDate, toDate } = useAppSelector(_pickerDate);
  const { page, limit } = useAppSelector(_reportsCurrentPage);

  const { onRangeChange, setReports } = useReportsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rangeParams, setRangeParams] = useState<IRangeParams>({
    dates: null,
    dateStrings: [],
  });

  const onModalSubmit = () => {
    onRangeChange(rangeParams.dates, rangeParams.dateStrings);
    setIsModalOpen(false);
  };

  const onChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    setRangeParams({
      dates: dates,
      dateStrings: dateStrings,
    });
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    setReports(data.reports);
  }, [data]);

  return (
    <Flex>
      <CustomModal
        title={t('filters.filters')}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      >
        <Flex gap={16} vertical>
          <RangePicker
            onRangeChange={onChange}
            value={[dayjs(fromDate), dayjs(toDate)]}
          />
          <Flex justify="flex-end">
            <Button onClick={onModalSubmit}>{t('filters.ok')}</Button>
          </Flex>
        </Flex>
      </CustomModal>

      <Flex vertical align="center" justify="center" style={{ width: '100%' }}>
        <Flex
          vertical
          gap={8}
          align="center"
          justify="center"
          style={{
            zIndex: 1,
            position: 'sticky',
            top: 70,
            height: 50,
            width: '100%',
            backgroundColor: colors.white,
            padding: '0px 16px',
          }}
        >
          <Flex
            gap={16}
            align="center"
            justify="space-between"
            style={{ width: '100%' }}
          >
            <Button onClick={() => setIsModalOpen(true)}>
              {t('filters.filters')}
            </Button>
            <Flex gap={16}>
              <ConfigProvider theme={{ token: { colorPrimary: '#D0D5DD66' } }}>
                <Select
                  defaultValue={EDirection.DESC}
                  disabled={!data?.total}
                  value={direction}
                  onChange={(direction) =>
                    dispatch(setReportsDirection(direction))
                  }
                  options={[
                    { value: EDirection.DESC, label: t('sortSelect.newest') },
                    { value: EDirection.ASC, label: t('sortSelect.oldest') },
                  ]}
                />
              </ConfigProvider>
            </Flex>
          </Flex>
        </Flex>
        {isFetching ? (
          <Loader />
        ) : data?.total === 0 || data?.total === undefined ? (
          <Flex justify="center" style={{ width: '100%' }}>
            <Empty />
          </Flex>
        ) : (
          <Flex vertical align="center" style={{ width: '100%' }}>
            <Flex
              justify="flex-start"
              style={{
                zIndex: 1,
                position: 'sticky',
                top: 120,
                width: '100%',
                backgroundColor: colors.white,
                padding: '0px 16px',
              }}
            >
              <ReportsActionsDashboard
                isFetching={isFetching}
                total={data?.total}
              />
            </Flex>
            {data?.reports.length && <ReportItems reports={data.reports} />}
            <Flex
              align="center"
              justify="center"
              style={{
                width: '100%',
                padding: '8px',
                backgroundColor: colors.white,
              }}
            >
              <Pagination
                total={data?.total}
                showSizeChanger
                current={page}
                pageSize={limit}
                locale={{ items_per_page: t('dataTable.page') }}
                onChange={(page, pageSize) =>
                  dispatch(setCurrentPage({ page: page, limit: pageSize }))
                }
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
