import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetReviewsResponse, IGetReviewsRequest } from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const myReviewsApi = createApi({
  reducerPath: 'myReviewsApi',
  tagTypes: ['myReviews'],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getReviews: build.query<IGetReviewsResponse, IGetReviewsRequest>({
      query: (params) => ({
        url: `${version}/reviews`,
        params,
      }),
    }),
  }),
});

export const { useGetReviewsQuery } = myReviewsApi;
