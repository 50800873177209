import { Flex, Typography } from 'antd';
import { HashTags, SmallButton } from '@svitlofour/ui';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';

import { getPhoto } from 'shared/helpers/getPhoto';
import { normalizeTextByLength } from 'shared/helpers/normalizeTextByLength';

import { IDetailedMyEvent } from './types';
import { SDate } from './style';

export const DetailedMyEvent = ({
  eventData: {
    filter: { title },
    review: { uid, urls, text, hashTags, createdAt },
  },
  closeDetailedEvent,
}: IDetailedMyEvent) => {
  return (
    <Flex vertical gap={12}>
      <SmallButton onClick={closeDetailedEvent} icon={<ArrowLeftOutlined />} />
      <Flex vertical gap={12}>
        <Typography.Text>
          Your filter under the name:{' '}
          <Typography.Text mark>{title}</Typography.Text> worked successfully
        </Typography.Text>
        <LazyLoadImage
          width="100%"
          src={getPhoto(urls[0])}
          alt={`photo-${uid}`}
        />
        <SDate>{dayjs(createdAt).format('DD MMM, YYYY HH:mm')}</SDate>
        <Typography.Text>
          {normalizeTextByLength(125)(text || undefined)}
        </Typography.Text>
        <HashTags hashTags={hashTags} />
      </Flex>
    </Flex>
  );
};
