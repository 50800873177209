import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  setIsNotificationsLoading,
  setNotificationsData,
  useAppDispatch,
  useAppSelector,
  useLazyGetNotificationsQuery,
} from 'store';

interface IUseNotifications {
  notificationCount: number;
  setNotifications: () => void;
}

export const useNotifications = (): IUseNotifications => {
  const dispatch = useAppDispatch();
  const { notificationsData } = useAppSelector(
    ({ notifications }) => notifications
  );
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [getNotifications] = useLazyGetNotificationsQuery();

  const setNotifications = () => {
    dispatch(setIsNotificationsLoading(true));
    getNotifications({
      fromDate: dayjs().startOf('year').format('YYYY-MM-DD'),
      toDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      limit: 10,
    })
      .unwrap()
      .then((data) => {
        dispatch(setNotificationsData(data));
      })
      .finally(() => dispatch(setIsNotificationsLoading(false)));
  };

  useEffect(() => {
    const result = notificationsData?.events.map(
      ({ status }) => status === 'new'
    );
    if (result?.length) {
      setNotificationCount(result.length);
    }
  }, [notificationsData]);

  return { notificationCount, setNotifications };
};
