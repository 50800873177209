import { Flex, Modal } from 'antd';
import {
  SWrapper,
  SBurgerMenuWrapper,
  SHeaderWrapper,
  SName,
  SEmail,
  SDivider,
  SMenuItem,
  SMenuItemText,
} from './style';
import { IBurgerMenu } from './types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

export const BurgerMenu = ({
  isOpen,
  onClickBurger,
  menuItems,
}: IBurgerMenu) => {
  const { t } = useTranslation();
  const {
    isAuth,
    userData: { user },
  } = useAppSelector(({ auth }) => auth);

  return (
    <SWrapper>
      <Modal
        open={isOpen}
        closable={false}
        destroyOnClose
        width={280}
        style={{ top: 80, left: 'calc(50vw - 160px)' }}
        onCancel={onClickBurger}
        footer={[]}
      >
        <Flex vertical>
          {isAuth && (
            <>
              <SHeaderWrapper gap={5} vertical>
                <SName>
                  {t('name')}: {user?.name}
                </SName>
                <SEmail>Email: {user?.email}</SEmail>
              </SHeaderWrapper>
              <SDivider />
            </>
          )}
          {menuItems.map(({ title, onClick, icon, disabled }, i) => (
            <SMenuItem
              key={i}
              align="center"
              $disabled={disabled}
              onClick={() => {
                if (!disabled) {
                  onClickBurger();
                  onClick();
                }
              }}
            >
              {icon}
              <SMenuItemText>{title}</SMenuItemText>
            </SMenuItem>
          ))}
        </Flex>
      </Modal>
      <SBurgerMenuWrapper onClick={onClickBurger} $isOpen={isOpen}>
        <div className="bar1" />
        <div className="bar2" />
        <div className="bar3" />
      </SBurgerMenuWrapper>
    </SWrapper>
  );
};
