import styled from 'styled-components';
import { Button, Flex } from 'antd';

import { colors } from 'theme';

export const SSocialSharedButtons = styled(Flex)`
  background-color: ${colors.white};
  padding: 16px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const SButton = styled(Button)`
  border: none;
`;
