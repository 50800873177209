type CartesianPointType = {
  X: number;
  Y: number;
};

export const polarToCartesian = (
  radius: number,
  angleInDegrees: number
): CartesianPointType => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    X: radius + radius * Math.cos(angleInRadians),
    Y: radius + radius * Math.sin(angleInRadians),
  };
};
