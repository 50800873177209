import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query';
import { message } from 'antd';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';

import { API_SERVER_URL } from 'envConfig';

import { setUserSignOut } from 'store';

const baseQuery = fetchBaseQuery({
  baseUrl: API_SERVER_URL,
  prepareHeaders: async (headers) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
    } catch (err) {
      message.error(`${err}`);
      setTimeout(async () => {
        await signOut();
      }, 1000);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    try {
      await getCurrentUser();

      result = await baseQuery(args, api, extraOptions);
    } catch (err) {
      message.error(`${err}`);
      setTimeout(async () => {
        await signOut();
        api.dispatch(setUserSignOut());
      }, 1000);
    }
  }

  return result;
};

export const baseQueryWithoutAuth = fetchBaseQuery({
  baseUrl: API_SERVER_URL,
});
