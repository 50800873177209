import { Flex } from 'antd';
import { useAppSelector } from 'store';
import {
  _activeReviewUid,
  _activeShowReviewMarker,
  _getDataReview,
} from 'store/selectors';
import { markerConfig } from 'config';
import { colors } from 'theme';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { EColorValue } from '@svitlofour/types';

export const MapLeafletReviewMarker = () => {
  const reviewUid = useAppSelector(_activeReviewUid);
  const review = useAppSelector(_getDataReview(reviewUid));
  const showReviewMarker = useAppSelector(_activeShowReviewMarker);

  if (!showReviewMarker || !reviewUid || !review) {
    return null;
  }

  const r = markerConfig.defaultMarkerSize / 2;
  const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
    r * 2
  } 0`;

  const getMarker = (color: EColorValue): L.DivIcon => {
    return L.divIcon({
      iconAnchor: [
        markerConfig.defaultMarkerSize / 2,
        markerConfig.defaultMarkerSize / 2,
      ],
      className: 'hidden',
      html: `
          <svg 
            width="${markerConfig.defaultMarkerSize}" 
            height="${markerConfig.defaultMarkerSize}" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${path}"
              fill="${colors[color]}"
              fill-opacity="1"
            />
          </svg>`,
    });
  };
  return (
    <Flex key={review.uid}>
      <Marker
        position={[review.latitude, review.longitude]}
        icon={getMarker(review.color)}
      />
    </Flex>
  );
};
