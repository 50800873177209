import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDirection, IReportTableItem, ECardView } from '@svitlofour/types';

import { IDateRangeValue, IPaginationPagesLimit } from 'shared/types';

import { initialState } from './initialState';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsDirection(state, action: PayloadAction<EDirection | undefined>) {
      state.reportsDirection = action.payload;
    },
    setSelectedReportsDirection(state, action: PayloadAction<EDirection>) {
      state.selectedReportsDirection = action.payload;
    },
    setListType(state, action: PayloadAction<ECardView>) {
      state.listType = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<IPaginationPagesLimit>) {
      state.currentPage = action.payload;
    },
    setPickerDate(state, action: PayloadAction<IDateRangeValue>) {
      state.pickerDate = action.payload;
    },

    setSelectedReports(state, action: PayloadAction<IReportTableItem[]>) {
      state.selectedReports = action.payload;
    },
  },
});

export const {
  setReportsDirection,
  setSelectedReportsDirection,
  setListType,
  setCurrentPage,
  setPickerDate,
  setSelectedReports,
} = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
