import styled from 'styled-components';
import { Flex, Divider } from 'antd';

import { colors } from 'theme';

export const SWrapper = styled(Flex)<{ $isBurgerOpen?: boolean }>`
  background-color: ${colors.white};
  width: 240px;
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: ${({ $isBurgerOpen }) => ($isBurgerOpen ? '25px' : '10px')};
`;

export const SMenuWrapper = styled(Flex)``;

export const SHeaderWrapper = styled(Flex)`
  padding: 10px 16px;
`;

export const SName = styled.div`
  color: #344054;
  font-weight: 500;
  line-height: 24px;
`;

export const SEmail = styled.div`
  color: ${colors.subtext};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const SDivider = styled(Divider)`
  margin: 10px 0;
`;

export const SMenuItem = styled(Flex)<{ $disabled?: boolean }>`
  height: 42px;
  weight: 100%;
  padding: 8px 16px;
  color: ${colors.subtext};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: not-allowed;

  ${({ $disabled }) =>
    !$disabled &&
    `
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey40};
    border-radius: 8px;
    color: ${colors.text};
  }
  `}
`;

export const SMenuItemText = styled.span`
  margin-left: 8px;
`;
