import styled, { keyframes } from 'styled-components';
import { Flex } from 'antd';

import { colors, metrics } from 'theme';

const open = () => keyframes`
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0px;
  }
`;

const close = () => keyframes`
  0% {
    bottom: 0px;
  }
  100% {
    bottom: -200px;
  }
`;

const handleGallerySize = ($show: boolean, $drawerIsOpen: boolean) => {
  if ($show && $drawerIsOpen) {
    return `
    width: 60% !important;

    @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
      width: 50% !important;
    }
  `;
  }
};

export const SGallery = styled(Flex)<{
  $show: boolean;
  $drawerIsOpen: boolean;
}>`
  position: absolute;
  z-index: 10000;
  width: 100% !important;
  height: 124px;
  color: ${colors.blueMediumAzure};
  font-size: 10px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.border};
  animation: ${({ $show }) => ($show ? open : close)} 0.3s linear forwards;
  ${({ $show, $drawerIsOpen }) => handleGallerySize($show, $drawerIsOpen)};

  @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
    animation: ${({ $drawerIsOpen }) => ($drawerIsOpen ? close : open)} 0.3s
      linear forwards;
  }
`;

export const SShevronButton = styled(Flex)`
  position: absolute;
  top: -33px;
  right: 40px;
  height: 32px;
  width: 40px;
  background-color: ${colors.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid ${colors.border};
  border-left: 1px solid ${colors.border};
  border-right: 1px solid ${colors.border};
  cursor: pointer;
`;

export const SShevron = styled(Flex)`
  font-size: 20px;
  color: ${colors.subtext60};
  transform: rotate(270deg);
  &:hover {
    color: ${colors.grey};
  }
`;

export const SPhotoBlurLeft = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 45px;
  background: linear-gradient(to right, ${colors.white}, transparent);
  z-index: 1;
`;

export const SPhotoBlurRight = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 45px;
  background: linear-gradient(to left, ${colors.white}, transparent);
  z-index: 1;
`;
