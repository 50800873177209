import { useAppSelector } from 'store';
import {
  _observersIsSelectMode,
  _isMyReviewsOpen,
  _level,
  _markersVisible,
} from 'store/selectors';

interface IUseMarkers {
  markersVisible: boolean;
}
export const useMarkers = (): IUseMarkers => {
  const level = useAppSelector(_level);
  const eventsIsSelectMode = useAppSelector(_observersIsSelectMode);
  const markersVisible = useAppSelector(_markersVisible);
  const isMyReviewsOpen = useAppSelector(_isMyReviewsOpen);
  const { isNotificationsOpen } = useAppSelector(
    ({ notifications }) => notifications
  );

  return {
    markersVisible:
      markersVisible &&
      level > 0 &&
      !eventsIsSelectMode &&
      !isMyReviewsOpen &&
      !isNotificationsOpen,
  };
};
