import { lazy } from 'react';
import { EPlan } from '@svitlofour/types';

const Dashboard = lazy(() => import('modules/corporativeCabinet/dashboard'));
const Company = lazy(() => import('modules/corporativeCabinet/company'));
const MyCompany = lazy(
  () => import('modules/corporativeCabinet/company/components/MyCompany')
);
const EmployeesTable = lazy(
  () => import('modules/corporativeCabinet/company/components/EmployeesTable')
);
const Reports = lazy(() => import('modules/corporativeCabinet/reports'));
const ReportsView = lazy(
  () => import('modules/corporativeCabinet/reports/components/ReportsView')
);
const ReportsOnMap = lazy(
  () => import('modules/corporativeCabinet/reports/components/ReportsOnMap')
);
const Observers = lazy(() => import('modules/corporativeCabinet/observers'));
const Finance = lazy(() => import('modules/corporativeCabinet/finance'));
const Prices = lazy(() => import('modules/corporativeCabinet/prices'));

import { IRouteObject, TIndex } from './types';
import { MapLoad } from 'modules';

import {
  DashboardPath,
  MyCompanyPath,
  EmployeesPath,
  MapPath,
  ReportsPath,
  ObserversPath,
  ReportsOnMapPath,
  SelectedReportsPath,
  FinancePath,
  PricesPath,
} from './constants';
import SelectedReports from 'modules/corporativeCabinet/reports/components/SelectedReports';
import { ReportsProvider } from 'modules/corporativeCabinet/reports';

export const appRoutesList: IRouteObject[] = [
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'map',
    index: true as TIndex,
    path: MapPath,
    element: <MapLoad />,
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.dashboard',
    path: DashboardPath,
    element: <Dashboard />,
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.company',
    path: MyCompanyPath,
    element: <Company />,
    children: [
      {
        path: MyCompanyPath,
        element: <MyCompany />,
      },
      {
        path: EmployeesPath,
        element: <EmployeesTable />,
      },
    ],
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.reports',
    path: ReportsPath,
    element: (
      <ReportsProvider>
        <Reports />
      </ReportsProvider>
    ),
    children: [
      {
        path: ReportsPath,
        element: <ReportsView />,
      },
      {
        path: ReportsOnMapPath,
        element: <ReportsOnMap />,
      },
      {
        path: SelectedReportsPath,
        element: <SelectedReports />,
      },
    ],
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.observers',
    path: ObserversPath,
    element: <Observers />,
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.finance',
    path: FinancePath,
    element: <Finance />,
  },
  {
    plan: [
      EPlan.BASIC,
      EPlan.PREMIUM,
      EPlan.PREMIUM_PLUS,
      EPlan.PREMIUM_CORPORATIVE,
      EPlan.INDIVIDUAL,
    ],
    title: 'sidebar.prices',
    path: PricesPath,
    element: <Prices />,
  },
];
