import { ICustomModal } from './types';
import { SModal } from './style';

export const CustomModal = ({
  title,
  isModalOpen,
  closeModal,
  children,
}: ICustomModal) => {
  return (
    <SModal
      title={title}
      open={isModalOpen}
      footer={null}
      onCancel={closeModal}
    >
      {children}
    </SModal>
  );
};
