import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetEventsResponse, IGetEventsRequest } from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getNotifications: build.query<IGetEventsResponse, IGetEventsRequest>({
      query: (params) => ({
        url: `${version}/user-events`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery } =
  notificationApi;
