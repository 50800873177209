import { SmallButton } from '@svitlofour/ui';
import dayjs from 'dayjs';
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import {
  SBlure,
  SFooter,
  SFooterPanel,
  SRightsReserved,
  SText,
  SWrapper,
} from './style';
import { useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { LanguageButton, LanguagePanel } from 'shared';

export const Footer = () => {
  const { t } = useTranslation();
  const { isSelectGeoposition } = useAppSelector(({ observers }) => observers);

  const goToGoogle = () => {
    window.open('https://www.google.com', '_blank'); // TODO: add real links
  };

  return (
    <SFooter>
      <LanguagePanel />
      <SWrapper justify="space-between" align="center">
        <SFooterPanel justify="flex-start" align="center" gap={10}>
          <SText onClick={goToGoogle}>{t('footer.about')}</SText>
          <SText onClick={goToGoogle}>{t('footer.privacy')}</SText>
          <SText onClick={goToGoogle}>{t('footer.terms')}</SText>
        </SFooterPanel>
        <Flex gap={16} justify="flex-start">
          <LanguageButton />
          <SRightsReserved justify="flex-start" align="center">
            © {dayjs().year()} Svitlofour. {t('footer.rights')}
          </SRightsReserved>
        </Flex>

        <SFooterPanel justify="flex-end" align="center" gap={10}>
          <SmallButton onClick={goToGoogle} icon={<InstagramOutlined />} />
          <SmallButton onClick={goToGoogle} icon={<WhatsAppOutlined />} />
          <SmallButton onClick={goToGoogle} icon={<FacebookOutlined />} />
        </SFooterPanel>
      </SWrapper>
      {isSelectGeoposition && <SBlure />}
    </SFooter>
  );
};
