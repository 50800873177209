import { SLanguagePanel } from './style';
import { setIsLanguagePanelOpen, useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { languages } from './constants';
import { Button, Typography } from 'antd';
import { colors } from 'theme';

export const LanguagePanel = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLanguagePanelOpen } = useAppSelector(({ active }) => active);

  const [language, setLanguage] = useState<string>(i18n.language);

  const selectLanguage = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
    dispatch(setIsLanguagePanelOpen(false));
  };

  return (
    <SLanguagePanel
      vertical
      gap={4}
      justify="space-between"
      align="flex-start"
      $show={isLanguagePanelOpen}
    >
      {languages.map((value, i) => {
        return (
          <Button
            key={i}
            type="text"
            onClick={() => selectLanguage(value.value)}
          >
            <Typography.Text
              style={{
                color:
                  language === value.value ? colors.subtext60 : colors.text,
              }}
            >
              {value.label}
            </Typography.Text>
          </Button>
        );
      })}
    </SLanguagePanel>
  );
};
