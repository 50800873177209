import { ReactNode, ReactElement } from 'react';

export interface IMenu {
  menuItems: IMenuItems[];
  setIsDropdownOpen: (val: boolean) => void;
  isBurgerOpen?: boolean;
}

export enum EMenuItems {
  MY_REVIEWS = 'my-reviews',
  OBSERVERS = 'observers',
  SETTINGS = 'settings',
}

export interface IMenuItems {
  title: string;
  onClick: () => void;
  icon: ReactNode;
  disabled?: boolean;
}

export interface IDrawersList {
  title: string;
  isOpen: boolean;
  filterComponent?: ReactElement;
  children: ReactNode;
}
