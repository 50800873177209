import { SMapLocationSelect } from './style';
import {
  setIsSelectGeoposition,
  setLocation,
  setMarkerLocation,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { Button } from 'antd';
import { getCookie } from 'helpers';
import { useEffect } from 'react';

export const MapLocationSelect = () => {
  const dispatch = useAppDispatch();
  const { markerLocation, location } = useAppSelector(
    ({ observers }) => observers
  );
  const cookie = getCookie();

  const selectLocation = () => {
    dispatch(setIsSelectGeoposition(false));
    if (!markerLocation) {
      return;
    }
    dispatch(
      setLocation({
        latitude: markerLocation.lat,
        longitude: markerLocation.lng,
      })
    );
    dispatch(setMarkerLocation(null));
  };

  const cancelSelectLocation = () => {
    dispatch(setIsSelectGeoposition(false));
    dispatch(setMarkerLocation(null));
  };

  useEffect(() => {
    if (location) {
      dispatch(
        setMarkerLocation({ lat: location.latitude, lng: location.longitude })
      );
      return;
    }
    if (!cookie) {
      return;
    }
    dispatch(setMarkerLocation({ lat: +cookie.lat, lng: +cookie.lng }));
  }, [location]);

  return (
    <SMapLocationSelect>
      <Button htmlType="reset" onClick={cancelSelectLocation}>
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" onClick={selectLocation}>
        Select
      </Button>
    </SMapLocationSelect>
  );
};
