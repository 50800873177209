import { MarkerF } from '@react-google-maps/api';
import { colors } from 'theme';
import { markerConfig } from 'config';
import { path } from './constants';
import { IReportMarkerProps } from './types';
import { useReportsContext } from '../../../ReportsContext';

export const ReportGoogleMarker = ({ report, scale }: IReportMarkerProps) => {
  const { setReport } = useReportsContext();
  return (
    <MarkerF
      position={{
        lng: report.longitude,
        lat: report.latitude,
      }}
      icon={{
        anchor: {
          x: markerConfig.myMarkerSize / 2,
          y: markerConfig.myMarkerSize / 2,
        } as google.maps.Point,
        path,
        fillColor: colors[report.color],
        fillOpacity: 0.9,
        scale: scale,
        strokeColor: colors[report.color],
        strokeWeight: 1,
      }}
      opacity={0.8}
      onClick={() => {
        setReport(report);
      }}
    />
  );
};
