import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useInView } from 'react-intersection-observer';
import { IReviewItem } from '@svitlofour/types';

import {
  setActiveReviewUid,
  useAppDispatch,
  useAppSelector,
  useLazyGetPhotosQuery,
  setPhotos,
} from 'store';
import { _photo, _userUid } from 'store/selectors';

import { getPhotoSmall } from 'helpers';

import { useReviewDrawer } from 'shared/hooks';

import {
  SPhotoGallery,
  SPhotoItem,
  SPhotoItemWrapper,
  SSelectedPhotoBorder,
} from './style';

import { IMapPhotoGalleryProps } from './types';

export const MapPhotoGallery = ({
  level,
  markerId,
  total,
  // handleOpenPhoto,
  data,
}: IMapPhotoGalleryProps) => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useReviewDrawer();

  const [getPhotos] = useLazyGetPhotosQuery();

  const [offset, setOffset] = useState(data.length);
  const [loading, setLoading] = useState(false);

  const photo = useAppSelector(_photo);
  const userUid = useAppSelector(_userUid);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const handlerOpenPhoto = (photo: IReviewItem) => {
    dispatch(setActiveReviewUid(photo.uid));
    openDrawer();
  };

  useEffect(() => {
    if (total > data.length) {
      setLoading(true);
    } else {
      setLoading(false);
      return;
    }
  }, [data]);

  useEffect(() => {
    if (inView) {
      setOffset(offset + 20);
      getPhotos({ level, markerId, offset, userUid }).then(
        ({ data }) => data && setPhotos({ markerId, ...data })
      );
    }
  }, [inView]);

  return (
    <SPhotoGallery>
      <SPhotoItemWrapper>
        {data.map((item) => {
          return (
            <SPhotoItem key={item.uid} onClick={() => handlerOpenPhoto(item)}>
              <img
                alt={'photo-' + item.uid}
                src={getPhotoSmall(item.urls[0])}
                style={{
                  width: 100,
                  height: 100,
                }}
                loading="lazy"
              />
              {photo?.uid === item.uid && <SSelectedPhotoBorder />}
            </SPhotoItem>
          );
        })}
        {loading && (
          <Button
            ref={ref}
            type="text"
            icon={<LoadingOutlined />}
            loading={loading}
          />
        )}
      </SPhotoItemWrapper>
    </SPhotoGallery>
  );
};
