import {
  ELanguagesLabel,
  ELanguagesValue,
  ELangugesRegion,
} from '@svitlofour/types';

export const languages = [
  {
    label: ELanguagesLabel.ENGLISH,
    value: ELanguagesValue.EN,
    region: ELangugesRegion.GB,
  },
  {
    label: ELanguagesLabel.CROATIAN,
    value: ELanguagesValue.HR,
    region: ELangugesRegion.HR,
  },
  {
    label: ELanguagesLabel.UKRAINIAN,
    value: ELanguagesValue.UA,
    region: ELangugesRegion.UA,
  },
];
