import { Flex, ConfigProvider, Select, Radio } from 'antd';
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { EDirection, ECardView } from '@svitlofour/types';
import { useMediaQuery } from 'react-responsive';

import {
  useAppSelector,
  useAppDispatch,
  setReviewsBounds,
  setIsMyReviewsOnMap,
} from 'store';

import { getLatLngBounds } from 'helpers';

import { IMyReviewsFilter } from './types';
import { ActionButton } from 'shared';
import { useTranslation } from 'react-i18next';

export const MyReviewsFilter = ({
  listType,
  setListType,
  direction,
  setDirection,
}: IMyReviewsFilter) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { myReviewsData } = useAppSelector(({ myReviews }) => myReviews);
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const showAllOnMap = () => {
    dispatch(setIsMyReviewsOnMap(true));
    dispatch(setReviewsBounds(getLatLngBounds(myReviewsData)));
  };

  return (
    <Flex justify="space-between" align="center">
      <Flex gap={10} justify="space-between" align="center">
        <ActionButton
          titleTooltip={t('myReviews.showAll')}
          onClick={showAllOnMap}
          disabled={!myReviewsData.length}
          icon={<GlobalOutlined />}
        />

        <ConfigProvider theme={{ token: { colorPrimary: '#D0D5DD66' } }}>
          <Select
            defaultValue={EDirection.DESC}
            disabled={!myReviewsData.length}
            value={direction}
            style={{ width: 120 }}
            onChange={setDirection}
            options={[
              { value: EDirection.DESC, label: t('sortSelect.newest') },
              { value: EDirection.ASC, label: t('sortSelect.oldest') },
            ]}
          />
          {!isMobile && (
            <Radio.Group
              value={listType}
              onChange={(e) => setListType(e.target.value)}
              disabled={!myReviewsData.length}
            >
              <Radio.Button value={ECardView.LIST}>
                <UnorderedListOutlined />
              </Radio.Button>
              <Radio.Button value={ECardView.TILE}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          )}
        </ConfigProvider>
      </Flex>
    </Flex>
  );
};
