import { useMapType } from 'shared/hooks';
import { GoogleLocationCircle, LeafletLocationCircle } from './components';

export const LocationCircle = () => {
  const { isLeaflet } = useMapType();

  if (isLeaflet) {
    return <LeafletLocationCircle />;
  }

  return <GoogleLocationCircle />;
};
