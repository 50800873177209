import styled from 'styled-components';

import { gallery } from 'config';

export const SPhotoGallery = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  background-color: #fff;
  padding: 12px 45px;
`;

export const SPhotoItemWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  gap: 12px;
`;

export const SPhotoItem = styled.div`
  display: flex;
  cursor: pointer;
  &:hover {
    scale: 1.03;
  }
`;

export const SSelectedPhotoBorder = styled.div`
  position: absolute;
  width: ${gallery.photoSize}px;
  height: ${gallery.photoSize}px;
  border: 2px solid #667085;
`;
