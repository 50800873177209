import { Dropdown, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { SmallButton } from '@svitlofour/ui';

import { ReactComponent as IconShared } from 'assets/images/icon-shared.svg';
import IconTelegram from 'assets/images/icon-telegram.png';
import IconFacebook from 'assets/images/icon-facebook.png';

import { APP_URL } from 'envConfig';

import { telegramShareUrl, facebookShareUrl } from './constants';
import { SSocialSharedButtons, SButton } from './style';
import { ISharedSNDropdown } from './types';

export const SharedSNDropdown = ({
  text,
  urls,
  uid,
  color,
  placement,
}: ISharedSNDropdown) => {
  return (
    <Dropdown
      destroyPopupOnHide
      trigger={['click']}
      dropdownRender={() => (
        <SSocialSharedButtons gap={16}>
          <SButton
            icon={<img src={IconTelegram} style={{ width: 20 }} />}
            onClick={() =>
              window.open(telegramShareUrl(uid, text, urls), '_blank')
            }
          />
          <SButton
            icon={<img src={IconFacebook} style={{ width: 30 }} />}
            onClick={() => window.open(facebookShareUrl + uid, '_blank')}
          />
          <CopyOutlined
            onClick={() => {
              navigator.clipboard
                .writeText(`${APP_URL}/?uid=${uid}`)
                .then(() => {
                  message.success(
                    'The link to the selected review has been successfully copied to the clipboard.'
                  );
                })
                .catch(() => {
                  message.error('some error occured');
                });
            }}
          />
        </SSocialSharedButtons>
      )}
      placement={placement}
    >
      <SmallButton icon={<IconShared />} $color={color} />
    </Dropdown>
  );
};
