import { createApi } from '@reduxjs/toolkit/query/react';
import { IPostReviewAgreeRequest, ISuccessResponse } from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const reviewAgreeApi = createApi({
  reducerPath: 'reviewAgreeApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addReviewAgree: build.mutation<ISuccessResponse, IPostReviewAgreeRequest>({
      query: (body) => ({
        url: `${version}/review-agree`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useAddReviewAgreeMutation } = reviewAgreeApi;
