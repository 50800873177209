import styled from 'styled-components';
import { Flex } from 'antd';

export const SLogoWrapper = styled(Flex)`
  gap: 8px;
  cursor: pointer;
`;

export const SLogoText = styled(Flex)`
  font-size: 22px;
  font-weight: 500;
`;
