import { Flex, Typography } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import { HashTags, SmallButton } from '@svitlofour/ui';
import { EllipsisOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { ECardView } from '@svitlofour/types';

import { useAppDispatch, setCoordinateOfMyReview } from 'store';

import { EPhotoSizes } from 'shared/types';
import { getPhoto } from 'shared/helpers/getPhoto';
import { normalizeTextByLength } from 'shared/helpers/normalizeTextByLength';

import { SharedSNDropdown } from './components';
import { IMyReview } from './types';
import {
  SCard,
  SImageWrapper,
  SButtonsWrapper,
  SDate,
  SLink,
  SInfoBlockWrapper,
  SDateWrapper,
  STextWrapper,
} from './style';
import { ActionButton } from 'shared';
import { useTranslation } from 'react-i18next';

export const MyReview = ({
  listType,
  review: { uid, urls, createdAt, text, hashTags, latitude, longitude },
}: IMyReview) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const [commentFormIsVisible, setCommentFormIsVisible] = useState(false);

  const showMyReviewOnMap = () => {
    dispatch(setCoordinateOfMyReview({ lat: latitude, lng: longitude }));
  };

  return (
    <SCard hoverable={listType === ECardView.LIST} id={`my-review-${uid}`}>
      {listType === ECardView.LIST ? (
        <Flex>
          <LazyLoadImage
            height={160}
            src={getPhoto(urls[0], EPhotoSizes.SMALL)}
            alt={`photo-${uid}`}
          />

          <SInfoBlockWrapper vertical gap={8}>
            <Flex justify="space-between" style={{ width: '100%' }}>
              <SDate>{dayjs(createdAt).format('DD MMM, YYYY HH:mm')}</SDate>
              <ActionButton
                titleTooltip={t('myReviews.show')}
                onClick={showMyReviewOnMap}
                icon={<EnvironmentOutlined />}
              />
            </Flex>
            <Typography.Text>
              {normalizeTextByLength(125)(text || undefined)}
            </Typography.Text>
            <HashTags hashTags={hashTags} />
          </SInfoBlockWrapper>
        </Flex>
      ) : (
        <Flex vertical gap={12}>
          <SImageWrapper>
            <LazyLoadImage
              width="100%"
              src={getPhoto(urls[0])}
              alt={`photo-${uid}`}
            />
            <SButtonsWrapper vertical gap={10}>
              <SharedSNDropdown
                text={text}
                urls={urls}
                uid={uid}
                color="light"
                placement="topRight"
              />
              <SmallButton icon={<EllipsisOutlined />} $color="light" />
            </SButtonsWrapper>
          </SImageWrapper>

          <SDateWrapper justify="space-between">
            <SDate>{dayjs(createdAt).format('DD MMM, YYYY HH:mm')}</SDate>
            <ActionButton
              titleTooltip={t('myReviews.show')}
              onClick={showMyReviewOnMap}
              icon={<EnvironmentOutlined />}
            />
          </SDateWrapper>

          <STextWrapper vertical gap={12}>
            <Typography.Text>
              {normalizeTextByLength(125)(text || undefined)}
            </Typography.Text>
            <HashTags hashTags={hashTags} />
            {/* <Flex vertical gap={12}>
              <Comment
                text="hardcode, waiting for reall back end from Volody"
                createdAt="2024-06-18"
              />
              <AddCommentForm
                reviewUid={uid}
                commentFormIsVisible={commentFormIsVisible}
                setCommentFormIsVisible={setCommentFormIsVisible}
              />
            </Flex> */}
          </STextWrapper>
        </Flex>
      )}
    </SCard>
  );
};
