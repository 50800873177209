import { useEffect, Suspense } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { InnerDrawer, SmallButton } from '@svitlofour/ui';
import { Flex } from 'antd';

import { useAppSelector } from 'store';

import { useDrawersList } from './hooks';
import { ICustomDrawer } from './types';
import { useLocationCircle } from 'shared/hooks/useLocationCircle';

export const CustomDrawer = ({
  drawerIsOpen,
  setDrawerIsOpen,
}: ICustomDrawer) => {
  const { isAuth } = useAppSelector(({ auth }) => auth);
  const { isCorporativeCabinet } = useAppSelector(
    ({ corporativeCabinet }) => corporativeCabinet
  );
  const { resetCircle } = useLocationCircle();

  const drawersList = useDrawersList(drawerIsOpen);

  useEffect(() => {
    if (!isAuth || isCorporativeCabinet) {
      setDrawerIsOpen(null);
      resetCircle();
    }
  }, [isAuth, isCorporativeCabinet]);

  return (
    <Suspense>
      {drawersList.map(({ title, isOpen, filterComponent, children }, i) => (
        <InnerDrawer
          key={i}
          title={title}
          open={isOpen}
          destroyOnClose
          extra={
            <>
              {filterComponent ? (
                <Flex justify="space-between" align="center" gap={10}>
                  {filterComponent}
                  <SmallButton
                    onClick={() => {
                      setDrawerIsOpen(null);
                      resetCircle();
                    }}
                    icon={<CloseOutlined />}
                  />
                </Flex>
              ) : (
                <SmallButton
                  onClick={() => {
                    setDrawerIsOpen(null);
                    resetCircle();
                  }}
                  icon={<CloseOutlined />}
                />
              )}
            </>
          }
        >
          {children}
        </InnerDrawer>
      ))}
    </Suspense>
  );
};
