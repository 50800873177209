import { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader, Layout as SvitlofourLayout } from '@svitlofour/ui';
import { MenuItem } from '@svitlofour/ui/components/Layout/types';
import { useAppDispatch, useAppSelector, setIsCorporativeCabinet } from 'store';
import { useUserRouter } from 'router/Router/useUserRouter';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { SLayout, SBody } from './style';
import { getItem } from './helpers';
import { MapPath } from 'router/routes/constants';
import { HeaderProvider } from 'view/Header/components/HeaderContext';

export const Layout = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { getMenuItemsList } = useUserRouter();
  const { pathname } = useLocation();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { isCorporativeCabinet } = useAppSelector(
    ({ corporativeCabinet }) => corporativeCabinet
  );
  const { isSelectGeoposition } = useAppSelector(({ observers }) => observers);
  const { isReviewDrawerSmall, isDrawerOpen } = useAppSelector(
    ({ active }) => active
  );
  const { isMyReviewsOnMap } = useAppSelector(({ myReviews }) => myReviews);
  const { plan } = useAppSelector(({ auth: { userData } }) => userData);

  const isRootPath = pathname === MapPath;

  useLayoutEffect(() => {
    if (isRootPath) {
      dispatch(setIsCorporativeCabinet(false));
    } else {
      dispatch(setIsCorporativeCabinet(true));
    }
  }, []);

  useEffect(() => {
    if (getMenuItemsList().length) {
      setMenuItems(
        getMenuItemsList()
          .slice(1)
          .map((item) =>
            getItem(
              <NavLink to={item.path}>{t(item.title)}</NavLink>,
              item.path
            )
          )
      );
    }
  }, [i18n.language, plan]);

  return (
    <SLayout>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
      {isCorporativeCabinet ? (
        <SvitlofourLayout pathname={pathname} items={menuItems}>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </SvitlofourLayout>
      ) : (
        <SBody
          id="map-body"
          $show={isDrawerOpen}
          $isSelectGeoposition={isSelectGeoposition}
          $isMyReviewsOnMap={isMyReviewsOnMap}
          $isMiniDrawer={isReviewDrawerSmall}
        >
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </SBody>
      )}
      <Footer />
    </SLayout>
  );
};
