import { useState, useEffect } from 'react';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SmallButton } from '@svitlofour/ui';
import { Dropdown, Flex, message, Tooltip } from 'antd';

import { useAddComplaintMutation, useAppSelector } from 'store';

import { EComplaintsVariants } from 'shared/constants';

import { ComplaintReason } from './components';
import { SDropdownBody, SComplaint, SText } from './style';
import { IComplaintDropdown } from './types';

const complaintsReasons = [
  {
    id: 1,
    reason: EComplaintsVariants.CONTENT,
  },
  {
    id: 2,
    reason: EComplaintsVariants.INTOLERANCE,
  },
  {
    id: 3,
    reason: EComplaintsVariants.OUT_OF_LAW,
  },
  {
    id: 4,
    reason: EComplaintsVariants.SEXUAL,
  },
];

export const ComplaintDropdown = ({ reviewUid }: IComplaintDropdown) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clickedOnComplaint, setClickedOnComplaint] = useState(false);

  const { isAuth } = useAppSelector(({ auth }) => auth);

  const [addComplaint, { isLoading }] = useAddComplaintMutation();

  useEffect(() => {
    if (!isDropdownOpen) {
      setClickedOnComplaint(false);
    }
  }, [isDropdownOpen]);

  const onClickComplaintReason = async (reason: EComplaintsVariants) => {
    setIsDropdownOpen(false);

    await addComplaint({ reviewUid, alias: reason })
      .unwrap()
      .then(
        ({ success }) =>
          success && message.success('Complaint successfully sended')
      )
      .catch((err) => message.error(`${err}`));
  };

  return (
    <Dropdown
      destroyPopupOnHide
      placement="bottomRight"
      open={isDropdownOpen}
      trigger={['click']}
      onOpenChange={() => setIsDropdownOpen((prev) => !prev)}
      dropdownRender={() => (
        <SDropdownBody>
          {!clickedOnComplaint ? (
            <SComplaint
              justify="center"
              align="center"
              onClick={() => setClickedOnComplaint(true)}
            >
              <ExclamationCircleOutlined />
              <SText>Report a publication</SText>
            </SComplaint>
          ) : (
            <Flex vertical justify="center" gap={8}>
              {complaintsReasons.map(({ id, reason }) => (
                <ComplaintReason
                  key={`${reason}-${id}`}
                  reason={reason}
                  onClickComplaintReason={onClickComplaintReason}
                />
              ))}
            </Flex>
          )}
        </SDropdownBody>
      )}
    >
      <Tooltip
        title={!isAuth && 'For adding complaint you have to been logged'}
      >
        <SmallButton
          loading={isLoading}
          icon={<EllipsisOutlined />}
          disabled={!isAuth}
        />
      </Tooltip>
    </Dropdown>
  );
};
