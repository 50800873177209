import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';

export const corporativeCabinetSlice = createSlice({
  name: 'corporativeCabinet',
  initialState,
  reducers: {
    setIsCorporativeCabinet(state, action: PayloadAction<boolean>) {
      state.isCorporativeCabinet = action.payload;
    },
    setActiveCabinetPath(state, action: PayloadAction<string | undefined>) {
      state.activeCabinetPath = action.payload;
    },
  },
});

export const { setIsCorporativeCabinet, setActiveCabinetPath } =
  corporativeCabinetSlice.actions;

export const corporativeCabinetReducer = corporativeCabinetSlice.reducer;
