import { Divider, Flex } from 'antd';
import styled, { css } from 'styled-components';

import { colors, metrics } from 'theme';

const defaultLineStyle = css`
  width: 100%;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
  border-radius: 12px;
`;

export const SWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
    display: block;
  }
`;

export const SHeaderWrapper = styled(Flex)`
  padding: 10px 16px;
`;

export const SName = styled.div`
  color: #344054;
  font-weight: 500;
  line-height: 24px;
`;

export const SEmail = styled.div`
  color: ${colors.subtext};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const SDivider = styled(Divider)`
  margin: 10px 0;
`;

export const SMenuItem = styled(Flex)<{ $disabled?: boolean }>`
  height: 42px;
  weight: 100%;
  padding: 8px 16px;
  color: ${colors.subtext};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: not-allowed;

  ${({ $disabled }) =>
    !$disabled &&
    `
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey40};
    border-radius: 8px;
    color: ${colors.text};
  }
  `}
`;

export const SMenuItemText = styled.span`
  margin-left: 8px;
`;

export const SBurgerMenuWrapper = styled.div<{ $isOpen: boolean }>`
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;

  .bar1,
  .bar2,
  .bar3 {
    ${defaultLineStyle};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    .bar1 {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      transform: rotate(45deg) translate(-5px, -5px);
    }
  `}
`;
