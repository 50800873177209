import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { IReportsMapGoogleProps } from './types';
import { Loader } from '@svitlofour/ui';
import { useCallback, useEffect, useState } from 'react';
import { useReportsContext } from '../../../ReportsContext';
import { ReportGoogleMarker } from '../ReportGoogleMarker';
import { ReportSelectedGoogleMarker } from '../ReportSelectedGoogleMarker';
import { _selectedReports, useAppSelector } from 'store';
import { ReportMapCard } from '../ReportMapCard';

export const ReportsGoogleMap = ({
  initialCenter,
  initialZoom,
  bounds,
}: IReportsMapGoogleProps) => {
  const selectedReports = useAppSelector(_selectedReports);
  const { report, reports, isShowSelectedReports } = useReportsContext();
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  });

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '65vh' }}
      options={{
        scrollwheel: true,
        disableDefaultUI: false,
        zoomControl: true,
        gestureHandling: 'greedy',
      }}
      center={initialCenter}
      zoom={initialZoom}
      onLoad={onLoad}
    >
      {reports?.length && !isShowSelectedReports && (
        <>
          {reports.map((item, i) => (
            <ReportGoogleMarker
              key={`${item.uid}-${i}`}
              report={item}
              scale={1}
            />
          ))}
        </>
      )}
      {selectedReports?.length && (
        <>
          {selectedReports.map((item, i) => (
            <ReportSelectedGoogleMarker
              key={`${item.uid}-${i}`}
              report={item}
            />
          ))}
        </>
      )}
      {report && <ReportMapCard report={report} />}
    </GoogleMap>
  ) : (
    <Loader />
  );
};
