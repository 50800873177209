import { IReportTableItem } from '@svitlofour/types';

export const getReportsBounds = (
  data: IReportTableItem[]
): google.maps.LatLngBoundsLiteral => {
  return {
    east: data.reduce((acc, curr) =>
      acc.longitude > curr.longitude ? acc : curr
    ).longitude,
    north: data.reduce((acc, curr) =>
      acc.latitude > curr.latitude ? acc : curr
    ).latitude,
    west: data.reduce((acc, curr) =>
      acc.longitude < curr.longitude ? acc : curr
    ).longitude,
    south: data.reduce((acc, curr) =>
      acc.latitude < curr.latitude ? acc : curr
    ).latitude,
  };
};
