import {
  _cabinetLocationCircleCenter,
  _cabinetLocationCircleMarkers,
  useAppSelector,
} from 'store';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import {
  centerColor,
  centerSize,
  pointColor,
  R,
  RCenter,
} from '../../constants';
import { memo } from 'react';

export const LeafletLocationCircle = memo(function LeafletLocationCircle(){
  const locationCircleMarkers = useAppSelector(_cabinetLocationCircleMarkers);
  const locationCircleCenter = useAppSelector(_cabinetLocationCircleCenter);

  if (!locationCircleMarkers || !locationCircleCenter) {
    return null;
  }

  const getCenterPoint = (): L.DivIcon => {
    const r = RCenter;
    const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
      r * 2
    } 0`;

    return L.divIcon({
      iconAnchor: [RCenter, RCenter],
      className: 'hidden',
      html: `
          <svg 
            width="${centerSize}" 
            height="${centerSize}" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${path}"
              fill="${centerColor}"
              stroke-opacity="none"
            />
          </svg>`,
    });
  };

  const getCircle = (): L.DivIcon => {
    const r = R;
    const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
      r * 2
    } 0`;

    return L.divIcon({
      iconAnchor: [centerSize / 2 - 4, centerSize / 2 - 4],
      className: 'hidden',
      html: `
          <svg 
            width="${centerSize}" 
            height="${centerSize}" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${path}"
              fill="${pointColor}"
              stroke-opacity="0.9"
            />
          </svg>`,
    });
  };

  return (
    <>
      <Marker
        key="center-point"
        position={[
          locationCircleCenter.latitude,
          locationCircleCenter.longitude,
        ]}
        icon={getCenterPoint()}
      />
      {locationCircleMarkers.map((point, key) => (
        <Marker
          key={`circle-${key}`}
          position={[point.latitude, point.longitude]}
          icon={getCircle()}
        />
      ))}
    </>
  );
});
