import styled from 'styled-components';
import { Flex, Typography } from 'antd';

import { colors } from 'theme';

export const SDropdownBody = styled(Flex)`
  background-color: ${colors.white};
  padding: 8px 10px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const SComplaint = styled(Flex)`
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: ${colors.grey40};
    border-radius: 8px;
  }
`;

export const SText = styled(Typography.Text)`
  padding: 10px;
`;
