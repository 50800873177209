import { SComplaint, SText } from '../../style';

import { IComplaintReason } from './types';

export const ComplaintReason = ({
  reason,
  onClickComplaintReason,
}: IComplaintReason) => {
  return (
    <SComplaint onClick={() => onClickComplaintReason(reason)}>
      <SText>{`${reason.charAt(0).toUpperCase()}${reason.slice(1)}`}</SText>
    </SComplaint>
  );
};
