import {
  useAppSelector,
  useAppDispatch,
  setMapCenter,
  setMapZoom,
  setIsMyReviewsOpen,
  setSelectedMarker,
  setMarkerId,
  setIsOpenGallery,
  setActiveReviewDrawerIsOpen,
  setIsNotificationDrawerOpen,
  setIsSelectGeoposition,
  setIsCorporativeCabinet,
  setActiveCabinetPath,
  setIsObserversOpen,
} from 'store';
import { _level } from 'store/selectors';
import { getCookie } from 'helpers';

import { levelToZoom } from '@svitlofour/map';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationCircle } from './useLocationCircle';
import { MapPath } from 'router/routes/constants';

interface ICorporativeCabinet {
  onCorporativeCabinetOpen: () => void;
  onCorporativeCabinetClose: () => void;
}

export const useCorporativeCabinet = (path: string): ICorporativeCabinet => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { resetCircle } = useLocationCircle();
  const cookie = getCookie();
  const level = useAppSelector(_level);

  const onCorporativeCabinetOpen = () => {
    if (!cookie) {
      return;
    }

    dispatch(setIsCorporativeCabinet(true));
    dispatch(setMapCenter({ lat: +cookie.lat, lng: +cookie.lng }));
    dispatch(setMapZoom(levelToZoom(level)));
    dispatch(setSelectedMarker(null));
    dispatch(setMarkerId(null));
    dispatch(setIsOpenGallery(false));
    dispatch(setActiveReviewDrawerIsOpen(false));
    dispatch(setIsMyReviewsOpen(false));
    dispatch(setIsObserversOpen(false));
    dispatch(setIsNotificationDrawerOpen(false));
    dispatch(setIsSelectGeoposition(false));
    resetCircle();

    navigate(path);
  };

  const onCorporativeCabinetClose = () => {
    dispatch(setIsCorporativeCabinet(false));
    dispatch(setActiveCabinetPath(location.pathname));
    navigate(MapPath);
  };

  return { onCorporativeCabinetOpen, onCorporativeCabinetClose };
};
