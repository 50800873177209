import { createSlice } from '@reduxjs/toolkit';
import { IGetEventsResponse } from '@svitlofour/types';

import { initialState } from './initialState';

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setIsNotificationsLoading(state, { payload }: { payload: boolean }) {
      state.isLoading = payload;
    },
    setIsNotificationsOpen(state, { payload }: { payload: boolean }) {
      state.isNotificationsOpen = payload;
    },
    setNotificationsData(state, { payload }: { payload: IGetEventsResponse }) {
      state.notificationsData = payload;
    },
    setNotificationsBounds(state, action) {
      state.notificationsBounds = action.payload;
    },
    setDetailedNotificationCoordinate(
      state,
      { payload }: { payload: google.maps.LatLngLiteral | null }
    ) {
      state.isDetailedNotificationCoordinate = payload;
    },
    setIsNotificationDrawerOpen(state, { payload }: { payload: boolean }) {
      state.isNotificationDrawerOpen = payload;
    },
  },
});

export const {
  setIsNotificationsLoading,
  setIsNotificationsOpen,
  setNotificationsData,
  setNotificationsBounds,
  setDetailedNotificationCoordinate,
  setIsNotificationDrawerOpen,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
