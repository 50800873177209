import styled from 'styled-components';
import { Flex } from 'antd';
import { colors, metrics } from 'theme';

export const SWrapper = styled(Flex)<{ $isOnMap: boolean }>`
  flex-direction: ${({ $isOnMap }) => ($isOnMap ? 'column' : 'row')} !important;
  align-items: ${({ $isOnMap }) =>
    $isOnMap ? 'center' : 'flex-start'} !important;

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    flex-direction: column !important;
    align-items: center !important;
  }
`;

export const SImageWrapper = styled(Flex)``;

export const SInfoBlockWrapper = styled(Flex)`
  height: 100%;
  width: 100%;
`;

export const SAuthWrapper = styled(Flex)`
  border: 1px solid ${colors.grey40};
  padding: 6px;
`;

export const SEmployeeInfo = styled(Flex)`
  font-weight: 500;
  color: ${colors.subtext};
`;

export const SDateWrapper = styled(Flex)`
  padding: 0px 8px 0px 0px;
  color: ${colors.subtext60};
`;

export const STextWrapper = styled(Flex)`
  border: 1px solid ${colors.grey40};
  padding: 6px;
`;

export const SHashTagsWrapper = styled(Flex)`
  width: 100%;
  padding: 6px;
`;
