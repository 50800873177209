import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetCommentsResponse,
  IGetCommentsRequest,
  IPostCommentResponse,
  IPostCommentRequest,
  IPutCommentResponse,
  IPutCommentRequest,
  IDeleteCommentResponse,
  IDeleteCommentRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getComments: build.query<IGetCommentsResponse, IGetCommentsRequest>({
      query: (params) => ({
        url: `${version}/comments`,
        params,
      }),
    }),
    addComment: build.mutation<IPostCommentResponse, IPostCommentRequest>({
      query: (body) => ({
        url: `${version}/comment`,
        method: 'POST',
        body,
      }),
    }),
    editComment: build.mutation<IPutCommentResponse, IPutCommentRequest>({
      query: (body) => ({
        url: `${version}/comment`,
        method: 'PUT',
        body,
      }),
    }),
    deleteComment: build.mutation<
      IDeleteCommentResponse,
      IDeleteCommentRequest
    >({
      query: (body) => ({
        url: `${version}/comment`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCommentsQuery,
  useAddCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
} = commentApi;
