import { TMapLevel, TUid } from '@svitlofour/types';

import { TRootState } from './types';

// markers
export const _markersVisible = (state: TRootState) => state.markers.visible;
export const _markersList = (state: TRootState) => state.markers.data;
export const _levelMarkers = (level: TMapLevel) => (state: TRootState) =>
  state.markers.data[level];

// active
export const _level = (state: TRootState) => state.active.level;
export const _markerId = (state: TRootState) => state.active.markerId;
export const _selectedMarker = (state: TRootState) =>
  state.active.selectedMarker;
export const _photo = (state: TRootState) => state.active.photo;
export const _geoposition = (state: TRootState) => state.active.geoposition;
export const _activeReviewDrawerIsOpen = (state: TRootState) =>
  state.active.reviewDrawerIsOpen;
export const _activeReviewUid = (state: TRootState) => state.active.reviewUid;
export const _activeIsAnimatedView = (state: TRootState) =>
  state.active.isAnimatedView;
export const _activeIsDeepLinking = (state: TRootState) =>
  state.active.isDeepLinking;
export const _activeMapCenter = (state: TRootState) => state.active.mapCenter;
export const _activeWindowSize = (state: TRootState) => state.active.windowSize;
export const _activeShowReviewMarker = (state: TRootState) =>
  state.active.showReviewMarker;
export const _isOpenGallery = (state: TRootState) => state.active.isOpenGallery;

// review
export const _reviewMarker = (state: TRootState) => state.review.reviewMarker;
export const _review = (state: TRootState) => state.review.review;

// observers
export const _observersIsSelectMode = (state: TRootState) =>
  state.observers.isSelectGeoposition;

// user
export const _userIsAuth = (state: TRootState) => state.auth.isAuth;
export const _userUid = (state: TRootState) => state.auth.userUid;

// data
export const _dataPhotos = (state: TRootState) => state.data.photos.data;
export const _dataReviews = (state: TRootState) => state.data.reviews.data;
export const _getDataReview = (reviewUid: TUid | null) => (state: TRootState) =>
  state.data.reviews.data[reviewUid || ''];

//
export const _isMyReviewsOpen = (state: TRootState) =>
  state.myReviews.isMyReviewsOpen;

// cabinet
export const _cabinetLocationCircleMarkers = (state: TRootState) =>
  state.cabinet.locationCirclePoints;
export const _cabinetLocationCircleCenter = (state: TRootState) =>
  state.cabinet.locationCircleCenter;

// reports
export const _reportsDirections = (state: TRootState) =>
  state.reports.reportsDirection;
export const _selectedReportsDirections = (state: TRootState) =>
  state.reports.selectedReportsDirection;
export const _reportsListType = (state: TRootState) => state.reports.listType;
export const _reportsCurrentPage = (state: TRootState) =>
  state.reports.currentPage;
export const _pickerDate = (state: TRootState) => state.reports.pickerDate;
export const _selectedReports = (state: TRootState) =>
  state.reports.selectedReports;
