import { Flex, Typography } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { HashTags } from '@svitlofour/ui';
import dayjs from 'dayjs';

import { getPhoto } from 'shared/helpers/getPhoto';
import { normalizeTextByLength } from 'shared/helpers/normalizeTextByLength';
import { EPhotoSizes } from 'shared/types';

import { SCard, SDate, SInfoBlockWrapper } from './style';
import { IMyEvent } from './types';

export const MyEvent = ({
  eventData: {
    review: { uid, urls, text, hashTags, createdAt },
    filter: { title },
  },
}: IMyEvent) => {
  return (
    <SCard hoverable>
      <Flex>
        <LazyLoadImage
          height={160}
          src={getPhoto(urls[0], EPhotoSizes.SMALL)}
          alt={`photo-${uid}`}
        />

        <SInfoBlockWrapper vertical gap={8}>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <SDate>{dayjs(createdAt).format('DD MMM, YYYY HH:mm')}</SDate>
            <Typography.Text strong>
              This review came across this filter:{' '}
              <Typography.Text mark>{title}</Typography.Text>
            </Typography.Text>
          </Flex>
          <Typography.Text>
            {`Text: ${normalizeTextByLength(125)(
              text || 'No text in this review'
            )}`}
          </Typography.Text>
          <Typography.Text>
            Hashtags:{' '}
            {hashTags?.length ? (
              <HashTags hashTags={hashTags} />
            ) : (
              <Typography.Text>No hashtags in this review</Typography.Text>
            )}
          </Typography.Text>
        </SInfoBlockWrapper>
      </Flex>
    </SCard>
  );
};
