import { mapConfig } from 'config';
import {
  setActiveMapCenter,
  setIsOpenGallery,
  setLevel,
  setMarkerId,
  setSelectedMarker,
  TMapGeoposition,
  useAppDispatch,
  useAppSelector,
  _activeIsDeepLinking,
  setActiveWindowSize,
  setReviewsBounds,
  setGeoposition,
} from 'store';
import { latLngToGeo, lLatLngToGeo, setCookie } from 'helpers';
import { zoomToLevel } from '@svitlofour/map';
import { LatLng, Map } from 'leaflet';
import { TMapZoom } from '@svitlofour/types';
import { useState } from 'react';
import { useReviewDrawer } from './useReviewDrawer';

interface IUseMapView {
  zoom: TMapZoom;
  onLoadMap: (data: IOnLoadMapProps) => void;
  onZoomChanged: (map: google.maps.Map | Map | undefined) => void;
  onBoundsChanged: (map: google.maps.Map | Map | undefined, isGoogle?: boolean) => void;
}

interface IOnLoadMapProps {
  mapGoogle?: google.maps.Map;
  mapLeaflet?: Map;
  initialZoom: TMapZoom;
  initialCenter: TMapGeoposition;
}

export const useMapView = (initialZoom: TMapZoom): IUseMapView => {
 
  const dispatch = useAppDispatch();
  const { closeDrawer } = useReviewDrawer();

  const activeIsDeepLinking = useAppSelector(_activeIsDeepLinking);
  const [zoom, setZoom] = useState<TMapZoom>(initialZoom);
 
  const onLoadMap = ({ mapGoogle, mapLeaflet, initialCenter, initialZoom }: IOnLoadMapProps) => {
    if (navigator.geolocation && initialCenter === mapConfig.initialCenterMap) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (mapGoogle) {
          mapGoogle.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }
        if (mapLeaflet) {
          mapLeaflet.setView({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }, initialZoom);
        }
      });
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(setGeoposition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },));
      });
    }
    if (mapGoogle) {
      dispatch(setActiveMapCenter(latLngToGeo(mapGoogle.getCenter())));
    }
    if (mapLeaflet) {
      dispatch(setActiveMapCenter(lLatLngToGeo(mapLeaflet.getCenter())));
    }
    dispatch(setLevel(zoomToLevel(initialZoom)));
  };

  const onZoomChanged = (map: google.maps.Map | Map | undefined) => {
    if (!map) {
      return;
    }

    const mapZoom = map.getZoom();

    if (mapZoom) {
      setZoom(mapZoom as TMapZoom);
      dispatch(setLevel(zoomToLevel(mapZoom as TMapZoom)));
    }

    if (!activeIsDeepLinking && mapZoom && Math.abs(zoom - mapZoom) >= 1) {
      closeDrawer();
    }

    dispatch(setSelectedMarker(null));
    dispatch(setMarkerId(null));
    dispatch(setIsOpenGallery(false));
  };

  const onBoundsChanged = (map: google.maps.Map | Map | undefined, isGoogle?: boolean) => {
    if (activeIsDeepLinking || !map) {
      return;
    }

    const center = map.getCenter();

    setCookie(center);
    dispatch(setActiveMapCenter(isGoogle ? latLngToGeo(center as google.maps.LatLng) : lLatLngToGeo(center as LatLng)));
    dispatch(
      setActiveWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    );
  
    dispatch(setReviewsBounds(null));
  };

  return {
    zoom,
    onLoadMap,
    onZoomChanged,
    onBoundsChanged,
  };
};
