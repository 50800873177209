import { MarkerF } from '@react-google-maps/api';
import { markerConfig } from 'config';
import { colors } from 'theme';
import { IReportSelectedMarkerProps } from './types';
import { useReportsContext } from '../../../ReportsContext';

export const ReportSelectedGoogleMarker = ({
  report,
}: IReportSelectedMarkerProps) => {
  const { setReport } = useReportsContext();
  const r = markerConfig.defaultMarkerSize / 2 + 1;
  const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
    r * 2
  } 0`;

  return (
    <MarkerF
      zIndex={1000}
      position={{
        lng: report.longitude,
        lat: report.latitude,
      }}
      icon={{
        anchor: {
          x: markerConfig.defaultMarkerSize / 2 + 1,
          y: markerConfig.defaultMarkerSize / 2 + 1,
        } as google.maps.Point,
        path,
        strokeColor: colors.black,
        strokeWeight: 2,
        strokeOpacity: 1,
        scale: 1,
        fillColor: colors[report.color],
        fillOpacity: 1,
      }}
      onClick={() => {
        setReport(report);
      }}
    />
  );
};
