import { useLayoutEffect } from 'react';
import { Input } from 'antd';

import { IEditCommentInput } from './types';

export const EditCommentInput = ({
  initialText,
  commentText,
  setCommentText,
}: IEditCommentInput) => {
  useLayoutEffect(() => {
    setCommentText(initialText);
  }, []);

  return (
    <Input
      value={commentText}
      onChange={(e) => setCommentText(e.target.value)}
      status={commentText.length === 0 ? 'error' : undefined}
    />
  );
};
