import { createSlice } from '@reduxjs/toolkit';
import { IReview } from '@svitlofour/types';

import { initialState } from './initialState';

export const myReviewsSlice = createSlice({
  name: 'myReviews',
  initialState,
  reducers: {
    setIsMyReviewsOpen(state, { payload }: { payload: boolean }) {
      state.isMyReviewsOpen = payload;
    },
    setIsMyReviewsOnMap(state, { payload }: { payload: boolean }) {
      state.isMyReviewsOnMap = payload;
    },
    setMyReviewsData(state, { payload }: { payload: IReview[] }) {
      state.myReviewsData = payload;
    },
    setReviewsBounds(state, action) {
      state.reviewsBounds = action.payload;
    },
    setCoordinateOfMyReview(
      state,
      { payload }: { payload: google.maps.LatLngLiteral | null }
    ) {
      state.coordinateOfMyReview = payload;
    },
  },
});

export const {
  setIsMyReviewsOpen,
  setIsMyReviewsOnMap,
  setMyReviewsData,
  setReviewsBounds,
  setCoordinateOfMyReview,
} = myReviewsSlice.actions;

export const myReviewsReducer = myReviewsSlice.reducer;
