import { Tooltip, message } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

import {
  useAppDispatch,
  IDataReview,
  setComments,
  useLazyGetCommentsQuery,
} from 'store';

export const LoadAllCommentsButton = ({
  reviewsData,
  trigger,
}: {
  reviewsData: IDataReview;
  trigger: ReturnType<typeof useLazyGetCommentsQuery>[0];
}) => {
  const dispatch = useAppDispatch();

  const loadAllComments = () => {
    trigger({ reviewUid: reviewsData.uid })
      .then((res) => {
        res.data &&
          dispatch(
            setComments({
              ...reviewsData,
              uid: reviewsData.uid,
              comments: res.data.comments,
              total: res.data.total,
            })
          );
      })
      .catch((err) => message.error(err));
  };
  if (reviewsData.commentsTotal > 1) {
    return (
      <Tooltip title="Show more comments">
        <CommentOutlined style={{ fontSize: 20 }} onClick={loadAllComments} />
      </Tooltip>
    );
  } else {
    return null;
  }
};
