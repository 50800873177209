import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportsContext } from '../../../ReportsContext';
import { Flex, Modal } from 'antd';
import { ReportCard } from '../../../ReportCard';
import { ReportSingleActions } from '../../../ReportSingleActions';
import { IReportMapCardsProps } from './types';

export const ReportMapCard = ({ report }: IReportMapCardsProps) => {
  const { t } = useTranslation();
  const { setReport } = useReportsContext();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onModalClose = () => {
    setReport(null);
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t('reports.report')}
      open={isModalOpen}
      destroyOnClose
      width={364}
      footer={[]}
      onCancel={onModalClose}
    >
      <Flex gap={16} vertical align="center">
        <ReportCard report={report} />
        <Flex justify="flex-end" style={{ width: '100%' }}>
          <ReportSingleActions report={report} />
        </Flex>
      </Flex>
    </Modal>
  );
};
