import { useEffect, useState } from 'react';
import { Flex, message, Popconfirm } from 'antd';
import { ActionButton } from 'shared';
import {
  FileAddOutlined,
  FilePdfOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  _selectedReports,
  useAppSelector,
  useDeleteCompanyReportsMutation,
} from 'store';
import { useReportsContext } from 'modules/corporativeCabinet/reports/components/ReportsContext';

import { colors } from 'theme';

import { IReportSingleActionsProps } from './types';

export const ReportSingleActions = ({ report }: IReportSingleActionsProps) => {
  const { t } = useTranslation();
  const selectedReports = useAppSelector(_selectedReports);
  const { setIsShowSelectedReports, onSelectReport, onCheckSelected } =
    useReportsContext();
  const [checked, setChecked] = useState(onCheckSelected(report));

  const [deleteReport, { isLoading }] = useDeleteCompanyReportsMutation();
  const { currentCompany } = useAppSelector(
    ({ auth: { userData } }) => userData
  );

  const onClick = () => {
    onSelectReport(report);
    setChecked(!checked);
  };

  useEffect(() => {
    if (selectedReports.length) {
      const searchReport = selectedReports.find(
        (res) => res.uid === report.uid
      );
      if (searchReport) {
        setChecked(true);
      }
    } else {
      setIsShowSelectedReports(false);
      setChecked(false);
    }
  }, [selectedReports]);

  const handleDeleteReport = async () => {
    if (currentCompany) {
      await deleteReport({
        companyUid: currentCompany.uid,
        companyReportsUids: [report.uid],
      })
        .unwrap()
        .then(() => message.success(t('reports.successDeleted')))
        .catch(({ data }) => message.error(data));
    }
  };

  return (
    <Flex
      gap={6}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <ActionButton
        onClick={onClick}
        icon={
          checked ? (
            <FilePdfOutlined style={{ color: colors.red }} />
          ) : (
            <FileAddOutlined />
          )
        }
      />
      <Popconfirm
        title={t('employeesTable.delete')}
        description={t('employeesTable.sureToDelete')}
        placement="bottomLeft"
        onConfirm={handleDeleteReport}
        okButtonProps={{ loading: isLoading }}
        okText={t('yes')}
        cancelText={t('employeesTable.cancel')}
        icon={false}
      >
        <ActionButton icon={<DeleteOutlined />} />
      </Popconfirm>
    </Flex>
  );
};
