import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICabinetState } from './types';
import { IGeoCoordinates } from '@svitlofour/types';

const initialState: ICabinetState = {
  locationCirclePoints: null,
  locationCircleCenter: null,
};

export const cabinetSlice = createSlice({
  name: 'cabinet',
  initialState,
  reducers: {
    resetLocationCircle: (state) => {
      state.locationCircleCenter = null;
      state.locationCirclePoints = null;
    },
    setLocationCirclePoints: (state, action: PayloadAction<IGeoCoordinates[]>) => {
      state.locationCirclePoints = action.payload;
    },
    setLocationCircleCenter: (state, action: PayloadAction<IGeoCoordinates>) => {
      state.locationCircleCenter = action.payload;
    },
  },
});

export const {
  resetLocationCircle,
  setLocationCirclePoints,
  setLocationCircleCenter,
} = cabinetSlice.actions;
export const cabinetReducer = cabinetSlice.reducer;
