import { EPlan } from '@svitlofour/types';

import { IAuthSlice } from './types';

export const initialState: IAuthSlice = {
  userName: undefined,
  userEmail: undefined,
  name: undefined,
  isAuth: false,
  accessToken: undefined,
  userUid: undefined,
  userData: {
    user: undefined,
    companies: undefined,
    currentCompany: undefined,
    plan: EPlan.INDIVIDUAL,
  },
  isLoading: true,
};
