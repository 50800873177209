import styled from 'styled-components';
import { Flex, Button } from 'antd';

import { colors, metrics } from 'theme';

export const SLogin = styled(Flex)`
  font-size: 24px;
`;

export const SUserButton = styled.div`
  border: 1px solid ${colors.grey40};
  background-color: ${colors.grey40};
  border-radius: 50%;
  cursor: pointer;
  color: ${colors.subtext};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &&& {
    width: 48px;
    height: 48px;
    outline: none;
  }

  &&&:hover {
    border: 2px solid ${colors.grey40};
    background-color: ${colors.grey40};
    color: ${colors.text};
  }

  @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
    display: none !important;
  }
`;

export const SPlanButton = styled(Button)`
  background-color: #fff7e6;
  color: #ffa500;
  font-weight: bold;
  border-radius: 12px;
  border: none;
  padding: 4px 12px;
  cursor: default;

  &:hover {
    background-color: #fff7e6 !important;
    color: #ffa500 !important;
  }

  // &:hover {
  //   background-color: #ffefd5 !important;
  //   color: #ff8c00 !important;
  // }

  // &:focus {
  //   box-shadow: none;
  // }

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    display: none;
  }
`;
