import { styled, css } from 'styled-components';
import { Flex } from 'antd';

import { colors, metrics } from 'theme';

export const SFooter = styled(Flex)`
  position: relative;
  height: 70px;
  width: 100%;
  border-top: 1px solid ${colors.border};
`;

export const SWrapper = styled(Flex)`
  z-index: 5;
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  padding: 0 20px;

  @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
    justify-content: center;
  }
  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    justify-content: center;
  }
`;

export const SBlure = styled.div`
  position: absolute;
  z-index: 6;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${colors.grey40};
`;

export const SFooterPanel = styled(Flex)`
  width: 400px;
  @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
    display: none;
  }

  .ant-btn-icon .anticon {
    font-size: 20px;
  }
`;

const sharedTxtStyles = css`
  font-size: 12px;
  color: ${colors.subtext};
`;

export const SText = styled.span`
  ${sharedTxtStyles};

  &:hover {
    color: ${colors.text};
    cursor: pointer;
  }
`;

export const SRightsReserved = styled(Flex)`
  width: 250px;
  ${sharedTxtStyles};
`;
