import styled from 'styled-components';
import { Flex, Card } from 'antd';

export const SCard = styled(Card)`
  width: 100%;

  .ant-card-body {
    padding: 16px;
  }
`;

export const SImageWrapper = styled(Flex)`
  position: relative;
`;

export const SButtonsWrapper = styled(Flex)`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

export const SDate = styled.div`
  color: #66708599;
`;

export const SLink = styled.div`
  font-weight: 500;
  color: #667085;
  cursor: pointer;
`;

export const SDateWrapper = styled(Flex)`
  padding: 4px 16px;
`;

export const STextWrapper = styled(Flex)`
  padding: 0 16px;
`;

export const SInfoBlockWrapper = styled(Flex)`
  padding: 0 8px;
  width: 100%;
`;
