import {
  IDataReview,
  setDataReviewsRatingItem,
  useAddReviewAgreeMutation,
  useAppDispatch,
} from 'store';

import { useGeolocation } from './useGeolocation';

interface IUseReviewRating {
  onAgree: () => void;
}

export const useReviewRating = (data: IDataReview): IUseReviewRating => {
  const dispatch = useAppDispatch();
  const [addReviewAgree] = useAddReviewAgreeMutation();
  const userGeo = useGeolocation();

  const onAgree = () => {
    dispatch(setDataReviewsRatingItem(data));
    addReviewAgree({
      reviewUid: data.uid,
      agree: !data.agree,
      latitude: userGeo?.latitude,
      longitude: userGeo?.longitude,
    });
  };

  return {
    onAgree,
  };
};
