import { MapContainer, TileLayer } from 'react-leaflet';
import { IMapProps } from './types';
import { MapPhotoDrawer } from '../MapPhotoDrawer';
import { MapObserver } from './components';
import { useAppSelector, _isOpenGallery } from 'store';
import { useMarkers } from 'shared/hooks';
import { MapMarkers } from '../MapMarkers';
import { MapControl } from '../MapControl';
import { MapGallery } from '../MapGallery';
import { useMapView } from 'shared/hooks/useMapView';
import { MapSelectedMarker } from '../MapSelectedMarker';
import { MapReviewMarker } from '../MapReviewMarker';
import { mapConfig } from 'config';
import { Cabinet } from 'modules/cabinet';

const mapStyleUrl = [
  'https://tiles.hey.st/styles/combined/{z}/{x}/{y}@2x.png', // only localhost
  'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
];

export const MapLeaflet = ({ initialCenter, initialZoom }: IMapProps) => {
  const { markersVisible } = useMarkers();

  const { zoom } = useMapView(initialZoom);

  const isOpenGallery = useAppSelector(_isOpenGallery);

  const { 
    defaultMinZoom,
    defaultMaxZoom
  } = mapConfig;

  return (
    <MapContainer
      center={[initialCenter.lat, initialCenter.lng]}
      zoom={initialZoom}
      minZoom={defaultMinZoom}
      maxZoom={defaultMaxZoom - 2}
      zoomControl={false}
      scrollWheelZoom={true}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        url={mapStyleUrl[0]}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapObserver initialCenter={initialCenter} initialZoom={initialZoom} />
      {markersVisible && <MapMarkers />}
      <MapSelectedMarker />
      <MapReviewMarker />
      <Cabinet />
      <MapControl zoom={zoom} />
      {isOpenGallery && <MapGallery />}
      <MapPhotoDrawer />
    </MapContainer>
  );
};
