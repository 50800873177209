import { IReportTableItem } from '@svitlofour/types';
import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { IReportsContext } from './types';
import { Dayjs } from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ReportsOnMapPath,
  ReportsPath,
  SelectedReportsPath,
} from 'router/routes/constants';
import {
  _selectedReports,
  setCurrentPage,
  setPickerDate,
  setSelectedReports,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { tableParams } from 'shared/constants';

export const ReportsContext = createContext<IReportsContext | null>(null);

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const selectedReports = useAppSelector(_selectedReports);
  const location = useLocation();
  const navigate = useNavigate();
  const [report, setReport] = useState<IReportTableItem | null>(null);
  const [reports, setReports] = useState<IReportTableItem[] | undefined>(
    undefined
  );

  const [isShowSelectedReports, setIsShowSelectedReports] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);

  const onCheckedAll = (reports: IReportTableItem[], checked: boolean) => {
    if (checked) {
      const newSelectedReports = Array.from(
        new Set(selectedReports.concat(reports))
      );
      dispatch(setSelectedReports(newSelectedReports));
    } else {
      dispatch(
        setSelectedReports(selectedReports.filter((e) => !reports.includes(e)))
      );
    }
    setCheckedAll(checked);
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      dispatch(
        setPickerDate({
          fromDate: dateStrings[0],
          toDate: dateStrings[1],
        })
      );
    }
    setIsShowSelectedReports(false);
    setCheckedAll(false);
  };

  const onSelectReport = (report: IReportTableItem) => {
    if (!selectedReports.length) {
      dispatch(setSelectedReports([report]));
      return;
    }
    const searchReport = selectedReports.find((res) => res.uid === report.uid);
    if (!searchReport) {
      dispatch(setSelectedReports([...selectedReports, report]));
    } else {
      dispatch(
        setSelectedReports(
          selectedReports.filter((res) => res.uid !== report.uid)
        )
      );
    }
  };

  const onCheckSelected = (report: IReportTableItem): boolean => {
    if (!selectedReports.length) {
      return false;
    }
    const searchReport = selectedReports.find((res) => res.uid === report.uid);
    if (!searchReport) {
      return false;
    } else {
      return true;
    }
  };

  const onRemoveReport = (report: IReportTableItem) => {
    if (selectedReports.length === 1) {
      dispatch(setSelectedReports([]));
      navigate(ReportsPath);
    }
    const searchReport = selectedReports.find((res) => res.uid === report.uid);
    if (searchReport) {
      dispatch(
        setSelectedReports(
          selectedReports.filter((res) => res.uid !== report.uid)
        )
      );
    }
  };

  const onRemoveAllReport = () => {
    dispatch(setSelectedReports([]));
    dispatch(setCurrentPage(tableParams));
    setCheckedAll(false);
    if (location.pathname.includes(SelectedReportsPath)) {
      navigate(ReportsPath);
    }
  };

  const value = {
    checkedAll,
    onCheckedAll,
    isShowSelectedReports,
    setIsShowSelectedReports,
    reports,
    setReports,
    report,
    setReport,
    onRangeChange,
    onRemoveReport,
    onRemoveAllReport,
    onSelectReport,
    onCheckSelected,
  };

  useEffect(() => {
    if (!reports) {
      setReport(null);
      return;
    }
  }, [reports]);

  useEffect(() => {
    if (!(location.pathname === ReportsPath + '/' + ReportsOnMapPath)) {
      setReport(null);
    }
  }, [location]);

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};

export const useReportsContext = () => {
  const reportsOnMapContext = useContext(ReportsContext);

  if (!reportsOnMapContext) {
    throw new Error('useReportContext must be used within a ReportsProvider');
  }

  return reportsOnMapContext;
};
