import { useState, useEffect } from 'react';
import { HashTags } from '@svitlofour/ui';
import { Image, Flex, Button } from 'antd';

import { Comment, ReviewRating } from 'shared';
import { dateTime, getPhotoOrigin } from 'helpers';

import {
  SDateWrapper,
  SLink,
  SPhoto,
  SPhotoDate,
  SPhotoItem,
  SPhotoText,
} from './style';
import { IPhotoProps } from './types';
import { APP_URL } from 'envConfig';

export const Photo = ({ data }: IPhotoProps) => {
  const [commentFormIsVisible, setCommentFormIsVisible] = useState(false);

  const onShowOnMap = () => {
    window.open(`${APP_URL}/?uid=${data.uid}`, '_blank');
  };

  useEffect(() => {
    setCommentFormIsVisible(false);
  }, [data]);

  return (
    <SPhoto vertical>
      <SPhotoItem>
        <Image
          height="100%"
          width="100%"
          preview={false}
          src={getPhotoOrigin(data.urls[0])}
          alt={`photo-${data.uid}`}
        />
      </SPhotoItem>
      <Flex vertical>
        <SDateWrapper justify="space-between">
          <SPhotoDate justify="flex-start" align="center">
            {dateTime.format(data.createdAt)}
          </SPhotoDate>
          <ReviewRating data={data} />
        </SDateWrapper>
        <SLink justify="flex-start" align="center"> 
          <Button 
            type="text"
            onClick={onShowOnMap}
          > 
            Show on map 
          </Button>
        </SLink>
        <SPhotoText>{data.text}</SPhotoText>
        <HashTags hashTags={data.hashTags} />
        <Comment
          reviewsData={data}
          commentFormIsVisible={commentFormIsVisible}
          setCommentFormIsVisible={setCommentFormIsVisible}
        />
      </Flex>
    </SPhoto>
  );
};
