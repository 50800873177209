import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { setIsLanguagePanelOpen, useAppDispatch, useAppSelector } from 'store';

export const LanguageButton = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLanguagePanelOpen } = useAppSelector(({ active }) => active);

  return (
    <Button
      size="small"
      style={{ width: 30 }}
      onClick={() => dispatch(setIsLanguagePanelOpen(!isLanguagePanelOpen))}
    >
      {i18n.language.toUpperCase()}
    </Button>
  );
};
