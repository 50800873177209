import Cookies from 'js-cookie';
import { currentPosition } from '../config';
import { TMapGeoposition } from '../store';

export const getCookie = (): TMapGeoposition | undefined => {
  const userCurrentPosition = Cookies.get(currentPosition.key);
  if (userCurrentPosition !== undefined) {
    return JSON.parse(userCurrentPosition);
  }
};
