import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActiveState } from './types';
import { IGeoCoordinates, IWindowSize } from '@svitlofour/types';
import { mapConfig } from 'config';

const initialState: IActiveState = {
  level: 0,
  markerId: null,
  selectedMarker: null,
  photo: null,
  geoposition: mapConfig.initialCenterMap,
  reviewDrawerIsOpen: false,
  reviewUid: null,
  isAnimatedView: false,
  isDeepLinking: false,
  windowSize: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  showReviewMarker: false,
  isReviewDrawerSmall: false,
  isOpenGallery: false,
  isLanguagePanelOpen: false,
  isDrawerOpen: false,
};

export const activeSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setLevel(state, action) {
      state.level = action.payload;
    },
    setMarkerId(state, action) {
      state.markerId = action.payload;
    },
    setSelectedMarker(state, action) {
      state.selectedMarker = action.payload;
    },
    setPhoto(state, action) {
      state.photo = action.payload;
    },
    setGeoposition(state, action) {
      state.geoposition = action.payload;
    },
    setActiveReviewDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      state.reviewDrawerIsOpen = action.payload;
    },
    setActiveReviewUid: (state, action: PayloadAction<string | null>) => {
      state.reviewUid = action.payload;
    },
    setActiveIsAnimatedView: (state, action: PayloadAction<boolean>) => {
      state.isAnimatedView = action.payload;
    },
    setActiveIsDeepLinking: (state, action: PayloadAction<boolean>) => {
      state.isDeepLinking = action.payload;
    },
    setActiveMapCenter: (
      state,
      action: PayloadAction<IGeoCoordinates | undefined>
    ) => {
      state.mapCenter = action.payload;
    },
    setActiveWindowSize: (state, action: PayloadAction<IWindowSize>) => {
      state.windowSize = action.payload;
    },
    setActiveShowReviewMarker: (state, action: PayloadAction<boolean>) => {
      state.showReviewMarker = action.payload;
    },
    setIsReviewDrawerSmall: (state, { payload }: PayloadAction<boolean>) => {
      state.isReviewDrawerSmall = payload;
    },
    setIsOpenGallery: (state, action: PayloadAction<boolean>) => {
      state.isOpenGallery = action.payload;
    },
    setIsLanguagePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isLanguagePanelOpen = action.payload;
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const {
  setLevel,
  setMarkerId,
  setSelectedMarker,
  setPhoto,
  setGeoposition,
  setActiveReviewDrawerIsOpen,
  setActiveReviewUid,
  setActiveIsAnimatedView,
  setActiveIsDeepLinking,
  setActiveMapCenter,
  setActiveWindowSize,
  setActiveShowReviewMarker,
  setIsReviewDrawerSmall,
  setIsOpenGallery,
  setIsLanguagePanelOpen,
  setIsDrawerOpen,
} = activeSlice.actions;

export const activeReducer = activeSlice.reducer;
