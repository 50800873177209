import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { IGeoCoordinates } from '@svitlofour/types';

export const observersSlice = createSlice({
  name: 'observers',
  initialState,
  reducers: {
    setIsObserversOpen(state, action: PayloadAction<boolean>) {
      state.isObserversOpen = action.payload;
    },
    setIsSelectGeoposition(state, action: PayloadAction<boolean>) {
      state.isSelectGeoposition = action.payload;
    },
    setLocation(state, action: PayloadAction<IGeoCoordinates | null>) {
      state.location = action.payload;
    },
    setMarkerLocation(
      state,
      action: PayloadAction<google.maps.LatLngLiteral | null>
    ) {
      state.markerLocation = action.payload;
    },
  },
});

export const {
  setIsSelectGeoposition,
  setLocation,
  setMarkerLocation,
  setIsObserversOpen,
} = observersSlice.actions;

export const observersReducer = observersSlice.reducer;
