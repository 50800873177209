import { EColor, TMapZoom } from '@svitlofour/types';

export const mapConfig = {
  mapType: {
    google: true,
    leaflet: false,
  },

  defaultZoom: 4 as TMapZoom,
  defaultMinZoom: 4 as TMapZoom,
  defaultMaxZoom: 20 as TMapZoom,
  defaultGeopositionZoom: 16 as TMapZoom,
  defaultNotificationZoom: 17 as TMapZoom,
  defaultNotificationsMinZoom: 2 as TMapZoom,
  defaultMyReviewZoom: 17 as TMapZoom,
  defaultReviewsMinZoom: 2 as TMapZoom,

  initialCenterMap: {
    lat: 50.45466,
    lng: 30.52381,
  },
  defaultStyle: {
    height: 'calc(100% + 4px)',
    width: '100%',
  },

  googleMapOptions: {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    gestureHandling: 'greedy',
    minZoom: 4,
    maxZoom: 20,
  },
  squareSize: 100,
  markersTimeToLive: 1 * 24 * 60 * 60 * 1000, // one day
};

export const markerConfig = {
  defaultMarkerSize: 30,
  myMarkerSize: 24,
  green: EColor.G,
  yellow: EColor.Y,
  red: EColor.R,
};
