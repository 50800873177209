import { useMapEvents } from 'react-leaflet';
import { useEffect } from 'react';
import { IMapProps } from '../../types';
import { useMapView } from 'shared/hooks/useMapView';
import { useDeepLinkingLeaflet } from 'shared/hooks';

export const MapObserver = ({ initialCenter, initialZoom }: IMapProps) => {
  const { onLoadMap, onZoomChanged, onBoundsChanged } = useMapView(initialZoom);
  
  const map = useMapEvents({
    moveend: () => {
      onBoundsChanged(map);
    },
    zoomend: () => {
      onZoomChanged(map);
    },
  });

  useDeepLinkingLeaflet(map);

  useEffect(() => {
    onLoadMap({ mapLeaflet: map, initialCenter, initialZoom });
  }, []);

  return null;
};
