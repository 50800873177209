import { createSlice } from '@reduxjs/toolkit';
import { IReviewState } from 'store/slices/reviewSlice/types';

const initialState: IReviewState = {
  fetch: {
    isLoading: false,
    error: '',
  },
  review: null,
  reviewMarker: null,
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setReviewMarker(state, action) {
      state.reviewMarker = action.payload;
    },
    setReview(state, action) {
      state.review = action.payload;
    },
  },
});

export const { setReview, setReviewMarker } = reviewSlice.actions;
export const reviewReducer = reviewSlice.reducer;
