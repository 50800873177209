import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IDeleteCompanyUserRequest,
  IDeleteCompanyUserResponse,
  IGetCompanyUsersRequest,
  IGetCompanyUsersResponse,
  IPostCompanyUserRequest,
  IPostCompanyUserResponse,
  IPutCompanyUserRequest,
  IPutCompanyUserResponse,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../../client';
import { version } from '../../client/constants';

import { ECompanyUsersTagType } from './types';

export const companyUsersApi = createApi({
  reducerPath: 'companyUsersApi',
  tagTypes: [ECompanyUsersTagType.COMPANY_USERS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getCompanyUsers: build.query<
      IGetCompanyUsersResponse,
      IGetCompanyUsersRequest
    >({
      query: (params) => ({
        url: `${version}/company-users`,
        params,
      }),
      providesTags: () => [ECompanyUsersTagType.COMPANY_USERS],
    }),
    addCompanyUser: build.mutation<
      IPostCompanyUserResponse,
      IPostCompanyUserRequest
    >({
      query: (body) => ({
        url: `${version}/company-user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [ECompanyUsersTagType.COMPANY_USERS],
    }),
    putCompanyUser: build.mutation<
      IPutCompanyUserResponse,
      IPutCompanyUserRequest
    >({
      query: (body) => ({
        url: `${version}/company-user`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [ECompanyUsersTagType.COMPANY_USERS],
    }),
    deleteCompanyUser: build.mutation<
      IDeleteCompanyUserResponse,
      IDeleteCompanyUserRequest
    >({
      query: (body) => ({
        url: `${version}/company-user`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: () => [ECompanyUsersTagType.COMPANY_USERS],
    }),
  }),
});

export const {
  useGetCompanyUsersQuery,
  useAddCompanyUserMutation,
  usePutCompanyUserMutation,
  useDeleteCompanyUserMutation,
} = companyUsersApi;
