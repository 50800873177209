import { TMapZoom } from '@svitlofour/types';
import { SMapControl } from './style';
import { mapConfig } from 'config';
import { useAppSelector } from 'store';
import { MapControlButtons } from './MapControlButtons';

interface IGoogleControlProps {
  map: google.maps.Map;
  zoom: TMapZoom;
}

export const GoogleControl = ({ map, zoom }: IGoogleControlProps) => {
  const { geoposition, isReviewDrawerSmall, isOpenGallery, isDrawerOpen } =
    useAppSelector(({ active }) => active);
  const { isSelectGeoposition } = useAppSelector(({ observers }) => observers);
  const { isMyReviewsOnMap } = useAppSelector(({ myReviews }) => myReviews);

  const { defaultMinZoom, defaultMaxZoom, defaultGeopositionZoom } = mapConfig;

  const setToCurrentGeoposition = () => {
    if (geoposition) {
      // TODO: add animation to move to the new center
      map.moveCamera({
        center: geoposition,
        zoom: defaultGeopositionZoom,
      });
    }
  };

  return (
    <SMapControl
      $show={isOpenGallery} // isOffsetY
      $isMiniDrawer={isReviewDrawerSmall}
      $isDrawer={isDrawerOpen && !isMyReviewsOnMap && !isSelectGeoposition}
      vertical
      justify="space-between"
      align="center"
      gap={8}
    >
      <MapControlButtons
        zoomIn={() =>
          zoom < defaultMaxZoom && map.setZoom((zoom + 1) as TMapZoom)
        }
        zoomOut={() =>
          zoom > defaultMinZoom && map.setZoom((zoom - 1) as TMapZoom)
        }
        moveToMyPosition={setToCurrentGeoposition}
        withPosition={!!geoposition}
      />
    </SMapControl>
  );
};
