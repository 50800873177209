import { Button, Flex } from 'antd';
import styled from 'styled-components';

export const SRatingWrapper= styled(Flex)`
`;

export const SRating= styled(Flex)`
  font-size: 16px;
  font-weight: bold;
`;

export const SAgree= styled(Button)`
`;
