import { MarkerF } from '@react-google-maps/api';
import {
  _cabinetLocationCircleCenter,
  _cabinetLocationCircleMarkers,
  useAppSelector,
} from 'store';
import {
  centerColor,
  centerPath,
  centerSize,
  path,
  pointColor,
  pointSize,
} from '../../constants';
import { memo } from 'react';

export const GoogleLocationCircle =  memo(function GoogleLocationCircle(){
  const locationCircleMarkers = useAppSelector(_cabinetLocationCircleMarkers);
  const locationCircleCenter = useAppSelector(_cabinetLocationCircleCenter);

  if (!locationCircleMarkers || !locationCircleCenter) {
    return null;
  }

  return (
    <>
      <MarkerF
        key="center-point"
        position={{
          lng: locationCircleCenter.longitude,
          lat: locationCircleCenter.latitude,
        }}
        icon={{
          anchor: { x: centerSize / 2, y: centerSize / 2 } as google.maps.Point,
          path: centerPath,
          fillColor: centerColor,
          fillOpacity: 1,
          strokeColor: centerColor,
          strokeWeight: 1,
        }}
        opacity={0.9}
      />
      {locationCircleMarkers.map((point, key) => (
        <MarkerF
          key={key}
          position={{
            lng: point.longitude,
            lat: point.latitude,
          }}
          icon={{
            anchor: { x: pointSize / 2, y: pointSize / 2 } as google.maps.Point,
            path,
            fillColor: pointColor,
            fillOpacity: 1,
            strokeColor: pointColor,
            strokeWeight: 1,
          }}
          opacity={0.9}
        />
      ))}
    </>
  );
});
