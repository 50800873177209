import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ErrorPage } from 'router/ErrorPage';
import { Layout } from 'view';

import { useUserRouter } from './useUserRouter';

export const Router = () => {
  const { getRouterChildren } = useUserRouter();

  if (!getRouterChildren) {
    return null;
  }

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '/',
          element: <Layout />,
          errorElement: <ErrorPage />,
          children: getRouterChildren(),
        },
      ])}
    />
  );
};
