import dayjs from 'dayjs';

import {
  _pickerDate,
  _reportsCurrentPage,
  _reportsDirections,
  useAppSelector,
  useGetCompanyReportsQuery,
} from 'store';

import { dateFormat } from 'shared/constants';
import { ReportsResponsiveTable } from './components';

const ReportsView = () => {
  const direction = useAppSelector(_reportsDirections);
  const { fromDate, toDate } = useAppSelector(_pickerDate);
  const { page, limit } = useAppSelector(_reportsCurrentPage);
  const { currentCompany } = useAppSelector(
    ({ auth: { userData } }) => userData
  );

  const { data, isFetching } = useGetCompanyReportsQuery(
    {
      fromDate,
      toDate: dayjs(toDate).add(1, 'day').format(dateFormat),
      page,
      limit,
      direction,
      companyUid: currentCompany?.uid as string,
    },
    { skip: !currentCompany?.uid }
  );

  return <ReportsResponsiveTable data={data} isFetching={isFetching} />;
};

export default ReportsView;
