import { styled } from 'styled-components';
import { Flex } from 'antd';

export const SReportOnMapWrapper = styled(Flex)`
  padding: 0px 16px;
`;

export const SRangePickerWrapper = styled(Flex)`
  width: 300px;
`;
