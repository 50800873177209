import { RangePicker } from '@svitlofour/ui';
import { _pickerDate, useAppSelector, useGetCompanyReportsQuery } from 'store';
import { dateFormat, reportsMapParams } from 'shared/constants';
import { useReportsContext } from '../ReportsContext';
import { LoadReportsMap } from './components';
import { ReportsActionsDashboard } from '../ReportsActionsDashboard';
import dayjs from 'dayjs';
import { SRangePickerWrapper, SReportOnMapWrapper } from './style';

const ReportsOnMap = () => {
  const { limit } = reportsMapParams;
  const { fromDate, toDate } = useAppSelector(_pickerDate);
  const { currentCompany } = useAppSelector(
    ({ auth: { userData } }) => userData
  );
  const { onRangeChange } = useReportsContext();

  const { isFetching, data } = useGetCompanyReportsQuery(
    {
      fromDate,
      toDate: dayjs(toDate).add(1, 'day').format(dateFormat),
      limit,
      companyUid: currentCompany?.uid as string,
    },
    { skip: !currentCompany?.uid }
  );

  return (
    <SReportOnMapWrapper vertical gap={16}>
      <ReportsActionsDashboard isFetching={isFetching} total={data?.total} />
      <SRangePickerWrapper>
        <RangePicker
          onRangeChange={onRangeChange}
          value={[dayjs(fromDate), dayjs(toDate)]}
        />
      </SRangePickerWrapper>
      <LoadReportsMap data={data} />
    </SReportOnMapWrapper>
  );
};

export default ReportsOnMap;
