import { PayloadAction } from '@reduxjs/toolkit';
import { TSquareId, IGetPhotosResponse } from '@svitlofour/types';

import { IDataState, IDataPhoto, IDataReview } from '../types';

export const dataPhotosReducers = {
  setPhotos: (
    state: IDataState,
    action: PayloadAction<IGetPhotosResponse & { markerId: TSquareId }>
  ) => {
    const { markerId, total, photos } = action.payload;

    // Change photos data
    state.photos.data[markerId] = {
      total,
      photos: [...(state.photos.data[markerId]?.photos || []), ...photos],
      timestamp: new Date().valueOf(),
    } as IDataPhoto;

    // Change reviews data
    photos.map((review) => {
      state.reviews.data[review.uid] = {
        ...review,
        timestamp: new Date().valueOf(),
      } as IDataReview;
    });
  },
};
