import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';

import './index.css';
import App from './App';
import { currentConfig } from './aws-exports';
import { store } from './store';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Amplify.configure(currentConfig);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
