import { useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import {
  getCurrentUser,
  fetchUserAttributes,
  AuthError,
} from 'aws-amplify/auth';

import { Router } from 'router/Router';

import {
  useAppDispatch,
  setUserDataAWS,
  setIsAuth,
  useLazyGetUserDataQuery,
  setUserData,
  setUserDataIsLoading,
} from 'store';

import { globalStyles } from 'theme';

const App = () => {
  const dispatch = useAppDispatch();
  const [getUserData] = useLazyGetUserDataQuery();

  const fetchUserData = async () => {
    dispatch(setUserDataIsLoading(true));

    await getUserData()
      .unwrap()
      .then((data) => {
        dispatch(setUserData(data));
      })
      .catch((err) => message.error(err.message));

    dispatch(setUserDataIsLoading(false));
  };

  const getUserInfo = async () => {
    try {
      const { userId } = await getCurrentUser();

      if (userId) {
        dispatch(setIsAuth(true));
        const userDataAWS = await fetchUserAttributes();
        dispatch(setUserDataAWS(userDataAWS));
        fetchUserData();
      }
    } catch (err) {
      const authError = err as AuthError;
      console.error(authError.message);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <ConfigProvider theme={globalStyles}>
      <Router />
    </ConfigProvider>
  );
};

export default App;
