import { Flex } from 'antd';
import { useAppSelector } from 'store';
import { _selectedMarker } from 'store/selectors';
import { markerConfig } from 'config';
import { colors } from 'theme';
import L from 'leaflet';
import { Marker } from 'react-leaflet';

export const MapLeafletSelectedMarker = () => {
  const selectedMarker = useAppSelector(_selectedMarker);

  if (!selectedMarker) {
    return null;
  }

  const scale = 1.2;
  const r = markerConfig.defaultMarkerSize / 2 + 2;
  const path = `M 0 ${r} a ${r} ${r} 0 1 0 ${r * 2} 0 a ${r} ${r} 0 1 0 -${
    r * 2
  } 0`;

  const getMarker = (): L.DivIcon => {
    return L.divIcon({
      iconAnchor: [
        (scale * markerConfig.defaultMarkerSize) / 2 + 2,
        (scale * markerConfig.defaultMarkerSize) / 2 + 2,
      ],
      className: 'hidden',
      html: `
          <svg 
            width="${markerConfig.defaultMarkerSize * scale + 6}" 
            height="${markerConfig.defaultMarkerSize * scale + 6}" 
            viewBox="-1 -1 ${markerConfig.defaultMarkerSize * scale + 8} ${
        markerConfig.defaultMarkerSize * scale + 8
      }"
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${path}"
              fill="none"
              stroke-opacity="1"
              stroke="${colors.black}"
              stroke-width="2"
              style="scale: ${scale}"
            />
          </svg>`,
    });
  };
  return (
    <Flex key={selectedMarker.id}>
      <Marker
        position={[selectedMarker.latitude, selectedMarker.longitude]}
        icon={getMarker()}
      />
    </Flex>
  );
};
