import { useState } from 'react';
import {
  FileDoneOutlined,
  CarryOutOutlined,
  LogoutOutlined,
  SettingOutlined,
  TeamOutlined,
  GlobalOutlined,
  DashboardOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EPlan } from '@svitlofour/types';

import {
  useAppDispatch,
  useAppSelector,
  setIsMyReviewsOpen,
  setActiveReviewUid,
  setSelectedMarker,
  setMarkerId,
  setIsOpenGallery,
  setActiveReviewDrawerIsOpen,
  setIsNotificationDrawerOpen,
  setIsMyReviewsOnMap,
  setIsObserversOpen,
} from 'store';

import { useLocationCircle } from 'shared/hooks/useLocationCircle';
import { useCorporativeCabinet } from 'shared/hooks';

import { IMenuItems, EMenuItems } from '../types';
import { useNavigate } from 'react-router-dom';
import {
  DashboardPath,
  ObserversPath,
  MyCompanyPath,
  ReportsPath,
} from 'router/routes/constants';

export const useMenuItems = (handleSignOut: () => void) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { activeCabinetPath } = useAppSelector(
    ({ corporativeCabinet }) => corporativeCabinet
  );

  const { resetCircle } = useLocationCircle();

  const { plan } = useAppSelector(({ auth: { userData } }) => userData);
  const { isNotificationsOpen } = useAppSelector(
    ({ notifications }) => notifications
  );
  const { isCorporativeCabinet } = useAppSelector(
    ({ corporativeCabinet }) => corporativeCabinet
  );

  const { onCorporativeCabinetClose, onCorporativeCabinetOpen } =
    useCorporativeCabinet(
      activeCabinetPath ? activeCabinetPath : DashboardPath
    );

  const [drawerIsOpen, setDrawerIsOpen] = useState<EMenuItems | null>(null);

  const setActiveElemets = () => {
    dispatch(setSelectedMarker(null));
    dispatch(setMarkerId(null));
    dispatch(setIsOpenGallery(false));
    dispatch(setActiveReviewDrawerIsOpen(false));
    dispatch(setIsMyReviewsOnMap(false));
    resetCircle();
  };

  const handleMyLocationsItem = (item: EMenuItems) => {
    if (isNotificationsOpen) {
      dispatch(setIsNotificationDrawerOpen(false));
    }
    dispatch(setIsObserversOpen(true));
    setDrawerIsOpen(item);
    setActiveElemets();
  };

  const onClickMyReviews = () => {
    if (isNotificationsOpen) {
      dispatch(setIsNotificationDrawerOpen(false));
    }

    handleMyLocationsItem(EMenuItems.MY_REVIEWS);
    dispatch(setIsMyReviewsOpen(true));
    dispatch(setActiveReviewUid(null));
    setActiveElemets();
  };

  const menuCabinetItems: IMenuItems[] = [
    {
      title: t('title.map'),
      onClick: onCorporativeCabinetClose,
      icon: <GlobalOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('title.logOut'),
      onClick: handleSignOut,
      icon: <LogoutOutlined />,
    },
  ];

  const burgerMenuCabinetItems: IMenuItems[] = [
    {
      title: t('sidebar.dashboard'),
      onClick: () => navigate(DashboardPath),
      icon: <DashboardOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('sidebar.company'),
      onClick: () => navigate(MyCompanyPath),
      icon: <TeamOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('sidebar.reports'),
      onClick: () => navigate(ReportsPath),
      icon: <SolutionOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('sidebar.observers'),
      onClick: () => navigate(ObserversPath),
      icon: <CarryOutOutlined style={{ fontSize: 18 }} />,
    },
    ...menuCabinetItems,
  ];

  const menuItems: IMenuItems[] = [
    {
      title: t('title.cabinet'),
      onClick: onCorporativeCabinetOpen,
      icon: <TeamOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('title.myReviews'),
      onClick: onClickMyReviews,
      icon: <FileDoneOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('title.observers'),
      onClick: () => handleMyLocationsItem(EMenuItems.OBSERVERS),
      icon: <CarryOutOutlined style={{ fontSize: 18 }} />,
      disabled: plan === EPlan.BASIC,
    },
    {
      title: t('title.settings'),
      onClick: () => handleMyLocationsItem(EMenuItems.SETTINGS),
      icon: <SettingOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: t('title.logOut'),
      onClick: handleSignOut,
      icon: <LogoutOutlined />,
    },
  ];

  return {
    drawerIsOpen,
    setDrawerIsOpen,
    menuItems: isCorporativeCabinet ? menuCabinetItems : menuItems,
    burgerMenuItems: isCorporativeCabinet ? burgerMenuCabinetItems : menuItems,
  };
};
