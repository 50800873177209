import {
  Flex,
  Input,
  Button,
  Form,
  type FormProps,
  message,
  Tooltip,
} from 'antd';

import {
  useAddCommentMutation,
  useAppDispatch,
  useAppSelector,
  setComments,
} from 'store';

import { IAddCommentForm } from './types';

export const AddCommentForm = ({
  reviewsData,
  commentFormIsVisible,
  setCommentFormIsVisible,
}: IAddCommentForm) => {
  const [addComment, { isLoading }] = useAddCommentMutation();
  const { userUid } = useAppSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish: FormProps<{ text: string }>['onFinish'] = ({ text }) => {
    if (commentFormIsVisible) {
      addComment({ reviewUid: reviewsData.uid, text })
        .unwrap()
        .then(({ comments, total }) => {
          dispatch(
            setComments({
              ...reviewsData,
              comments,
              total,
            })
          );
        })
        .catch((err) =>
          message.error(err.status === 401 ? 'Please login' : err)
        )
        .finally(() => {
          form.resetFields();
          setCommentFormIsVisible(false);
        });
    } else {
      setCommentFormIsVisible(true);
    }
  };

  return (
    <Flex vertical gap={12}>
      <Form<{ text: string }>
        name="add-comment"
        form={form}
        onFinish={onFinish}
      >
        {commentFormIsVisible && (
          <Form.Item name="text" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Add comment" />
          </Form.Item>
        )}

        <Form.Item>
          <Tooltip
            title={!userUid && 'For adding comment you have to been logged'}
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              disabled={!userUid}
            >
              Add comment
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </Flex>
  );
};
