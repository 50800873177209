import { Flex } from 'antd';
import styled from 'styled-components';
import { metrics } from 'theme';

const handleMapSize = ($isSmallMap: boolean, $isSelectGeoposition: boolean) => {
  if ($isSelectGeoposition) {
    return `
    width: 60% !important;

    @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
      width: 50% !important;
    }
    @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
      width: 100% !important;
    }
  `;
  }
  return `
    width: ${$isSmallMap ? 60 : 100}% !important;

    @media screen and (max-width: ${metrics.mediaLaptopSize}px) {
      width: ${$isSmallMap ? 50 : 100}% !important;
    }
    @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
      width: 100% !important;
    }
  `;
};

export const SMapWrapper = styled(Flex)<{
  $isSmallMap: boolean;
  $isSelectGeoposition: boolean;
}>`
  position: absolute;
  top: -2px;
  left: -2px;
  height: 100%;
  width: 100% !important;
  ${({ $isSmallMap, $isSelectGeoposition }) =>
    handleMapSize($isSmallMap, $isSelectGeoposition)};
`;
