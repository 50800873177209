import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IPostCompanyResponse,
  IPostCompanyRequest,
  IPutCompanyResponse,
  IPutCompanyRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../../client';
import { version } from '../../client/constants';

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addCompany: build.mutation<IPostCompanyResponse, IPostCompanyRequest>({
      query: (body) => ({
        url: `${version}/company`,
        method: 'POST',
        body,
      }),
    }),
    editCompany: build.mutation<IPutCompanyResponse, IPutCompanyRequest>({
      query: (body) => ({
        url: `${version}/company`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useAddCompanyMutation, useEditCompanyMutation } = companiesApi;
