import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { IReportCardLabelProps } from './types';
import { SDateWrapper, SEmployeeInfo } from './style';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getPhoto } from 'shared/helpers/getPhoto';
import { EPhotoSizes } from 'shared/types';

export const ReportCardLabel = ({ report }: IReportCardLabelProps) => {
  return (
    <Flex gap={6}>
      <Flex>
        <LazyLoadImage
          height={44}
          width={44}
          src={getPhoto(report.urls[0], EPhotoSizes.SMALL)}
          alt={`report-${report.uid}`}
        />
      </Flex>
      <Flex vertical justify="flex-start" align="flex-start">
        <SDateWrapper>
          {dayjs(report.createdAt).format('DD MMM, YYYY HH:mm')}
        </SDateWrapper>
        <SEmployeeInfo vertical>
          <Typography.Text>{report.user.name}</Typography.Text>
        </SEmployeeInfo>
      </Flex>
    </Flex>
  );
};
