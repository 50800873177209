import { colors } from 'theme';
import { Popover } from 'antd';
import { useAuthUser, useReviewRating } from 'shared/hooks';

import { SAgree, SRating, SRatingWrapper } from './style';
import { IReviewRatingProps } from './types';

export const ReviewRating = ({ data }: IReviewRatingProps) => {
  const isAuth = useAuthUser();
  const { onAgree } = useReviewRating(data);
  const { agree, rating } = data;

  return (
    <SRatingWrapper justify="space-between" gap={12}>
      <SRating align="center" style={{ color: `${colors[data.color]}` }}>
        +{rating}
      </SRating>
      {isAuth ? (
        <SAgree
          shape="circle"
          type="primary"
          style={{
            color: agree ? `${colors.white}` : `${colors.subtext}`,
            background: agree ? `${colors[data.color]}` : `${colors.white}`,
            borderColor: `${colors[data.color]}`,
          }}
          onClick={onAgree}
        >
          +1
        </SAgree>
      ) : (
        <Popover placement="left" content="Please Log in">
          <SAgree
            shape="circle"
            type="primary"
            style={{
              color: `${colors.subtext}`,
              background: `${colors.white}`,
              borderColor: `${colors[data.color]}`,
            }}
          >
            +1
          </SAgree>
        </Popover>
      )}
    </SRatingWrapper>
  );
};
