import { keyframes, styled } from 'styled-components';
import { Flex, Button } from 'antd';

import { colors, metrics } from 'theme';

const slideAnime = (bottom: number[]) => keyframes`
  0% {
    bottom: ${bottom[0]}px;
  }
  100% {
    bottom: ${bottom[1]}px;
  }
`;

export const SMapControl = styled(Flex)<{
  $show: boolean;
  $isMiniDrawer: boolean;
  $isDrawer: boolean;
}>`
  position: absolute;
  z-index: 1000;
  bottom: 40px;
  right: ${({ $isMiniDrawer, $isDrawer }) =>
    $isMiniDrawer ? '32%' : $isDrawer ? '45%' : '40px'};
  animation: ${({ $show }) => slideAnime($show ? [40, 180] : [180, 40])} 0.3s
    linear forwards;

  @media screen and (max-width: ${metrics.mediaMiddleSize}px) {
    right: ${({ $isMiniDrawer, $isDrawer }) =>
      $isMiniDrawer ? '43%' : $isDrawer ? '45%' : '40px'};
  }

  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    right: 20px;
    bottom: 25px;
    animation: ${({ $show }) => slideAnime($show ? [25, 175] : [175, 25])} 0.3s
      linear forwards;
  }
`;

export const SControlButton = styled(Button)<{ $active: boolean }>` 
  border: 1px solid ${colors.grey40};
  background-color: ${colors.white};
  color: ${({ $active }) => ($active ? colors.subtext : colors.subtext60)};
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

  &&& {
    width: 40px;
    height: 40px;
  }

  &&&:hover {
    border: ${({ $active }) => ($active ? 2 : 1)}px solid ${colors.grey40};
    color: ${({ $active }) => ($active ? colors.text : colors.subtext60)};
    cursor: ${({ $active }) => ($active ? 'pointer' : 'auto')};
`;
