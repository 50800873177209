import { createSlice } from '@reduxjs/toolkit';
import { ISettingsSlice } from './types';

export const initialState: ISettingsSlice = {
  isEventsOnMapVisible: true,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsEventsOnMapVisible(state, { payload }: { payload: boolean }) {
      state.isEventsOnMapVisible = payload;
    },
  },
});

export const { setIsEventsOnMapVisible } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
