import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IPostComplaintRequest,
  IPostComplaintResponse,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

export const complaintApi = createApi({
  reducerPath: 'complaintApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addComplaint: build.mutation<IPostComplaintResponse, IPostComplaintRequest>(
      {
        query: (body) => ({
          url: `${version}/complaint`,
          method: 'POST',
          body,
        }),
      }
    ),
  }),
});

export const { useAddComplaintMutation } = complaintApi;
