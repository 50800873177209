import { useMapType } from 'shared/hooks';
import { LoadReportsGoogleMap } from '../LoadReportsGoogleMap';
import { ILoadReportsMapProps } from './types';
import { useEffect } from 'react';
import { useReportsContext } from '../../../ReportsContext';

export const LoadReportsMap = ({ data }: ILoadReportsMapProps) => {
  const { isGoogle } = useMapType();
  const { setReports } = useReportsContext();

  useEffect(() => {
    if (!data) {
      return;
    }
    setReports(data.reports);
  }, [data]);

  if (isGoogle) {
    return <LoadReportsGoogleMap />;
  }

  return null;
};
