import { useEffect, useState } from 'react';
import { Typography, Button } from 'antd';

import {
  useAppDispatch,
  useAppSelector,
  setIsNotificationsOpen,
  setNotificationsBounds,
  setIsNotificationDrawerOpen,
} from 'store';

import { getLatLngBounds } from 'helpers';

import { SWrapper } from './style';
import { IDropdownBlock } from './types';
import { useTranslation } from 'react-i18next';

export const DropdownBlock = ({
  openDrawer,
  closeDropdown,
  events,
  isLoading,
  closeMenuDrawer,
}: IDropdownBlock) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(({ auth }) => auth);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const { notificationsData, isNotificationDrawerOpen } = useAppSelector(
    ({ notifications }) => notifications
  );

  useEffect(() => {
    if (!isAuth) {
      closeDropdown();
    }
  }, [isAuth]);

  const handleButtonClick = () => {
    closeDropdown();
    if (events?.length) {
      dispatch(
        setNotificationsBounds(
          getLatLngBounds(events.map((item) => item.review))
        )
      );
    }

    closeMenuDrawer();
    openDrawer();
    dispatch(setIsNotificationsOpen(true));

    if (isNotificationDrawerOpen) {
      dispatch(setIsNotificationDrawerOpen(true));
    }
  };

  useEffect(() => {
    const result = notificationsData?.events.map(
      ({ status }) => status === 'new'
    );
    if (result?.length) {
      setIsNewNotification(true);
      setNotificationCount(result.length);
    } else {
      setIsNewNotification(false);
    }
  }, [notificationsData]);

  return (
    <SWrapper
      vertical
      justify={isNewNotification ? 'space-between' : 'center'}
      $isNewNotification={isNewNotification}
    >
      {isNewNotification && (
        <Typography.Text>
          {notificationCount > 1 ? (
            <>
              {t('notification.newEvents')}: {notificationCount}
            </>
          ) : (
            <>
              {t('notification.newEvent')}: {notificationCount}
            </>
          )}
        </Typography.Text>
      )}
      <Button type="primary" onClick={handleButtonClick} loading={isLoading}>
        {t('notification.allEvents')}
      </Button>
    </SWrapper>
  );
};
