import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IAppGetMarkersRequest,
  IAppGetMarkersResponse,
  TMapLevel,
  TSquareId,
} from '@svitlofour/types';

import { getMarkers } from 'api/getMarkers';

import { initialState } from './initialState';
import { TSquareStatus } from './types';

export const getMarkerList = createAsyncThunk(
  'map/getMarkerList',
  async ({
    level,
    id,
  }: IAppGetMarkersRequest): Promise<{
    level: TMapLevel;
    data: IAppGetMarkersResponse;
    id: Array<TSquareId>;
  }> => {
    const markers = await getMarkers(level, id);

    return { level, data: markers, id };
  }
);

export const markersSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    setSquaresStatus(
      state,
      action: PayloadAction<{
        level: TMapLevel;
        id: Array<TSquareId>;
        value: TSquareStatus;
      }>
    ) {
      const level = action.payload.level;
      const squaresList = action.payload.id;
      const status = action.payload.value;

      squaresList.map((square) => {
        if (!state.data[level][square]) {
          return;
        }
        state.data[level][square].status = status;
      });
    },
    setMarkersVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarkerList.pending, (state) => {
        state.fetch.isLoading = true;
      })
      .addCase(
        getMarkerList.fulfilled,
        (
          state,
          action: PayloadAction<{
            level: TMapLevel;
            data: IAppGetMarkersResponse;
            id: Array<TSquareId>;
          }>
        ) => {
          const markers = action.payload.data;
          const squareIds = action.payload.id;

          squareIds.map((idMarker) => {
            const isResponseId = Boolean(markers[idMarker]);
            if (!state.data[action.payload.level][idMarker as TSquareId]) {
              state.data[action.payload.level][idMarker as TSquareId] = {
                updatedAt: Date.now(), // TODO: for empty array set update period 4 hours
                status: 'ok',
                data: isResponseId ? markers[idMarker as TSquareId] : [],
              };

              return;
            }

            state.data[action.payload.level][idMarker as TSquareId].data =
              isResponseId ? markers[idMarker as TSquareId] : [];
            state.data[action.payload.level][idMarker as TSquareId].status =
              'ok';
            state.data[action.payload.level][idMarker as TSquareId].updatedAt =
              Date.now();
          });

          state.fetch.isLoading = false;
        }
      )
      .addCase(getMarkerList.rejected, (state, action) => {
        state.fetch.error = action.error.message;
        state.fetch.isLoading = false;
      });
  },
});

export const { setSquaresStatus, setMarkersVisible } = markersSlice.actions;
export const markersReducer = markersSlice.reducer;
