import { PayloadAction } from '@reduxjs/toolkit';
import { IGetCommentsResponse } from '@svitlofour/types';

import { IDataState } from '../types';

export const dataCommentsReducers = {
  setComments: (
    state: IDataState,
    {
      payload: { uid, comments, total },
    }: PayloadAction<{ uid: string } & IGetCommentsResponse>
  ) => {
    state.reviews.data[uid].comments = comments;
    state.reviews.data[uid].commentsTotal = total;
  },
};
