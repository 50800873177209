import { useAppSelector } from 'store';
import { _levelMarkers } from 'store/selectors';

import { SquareMarkers } from 'modules/map/components/SquareMarkers';

import { IMapMarkersSquaresProps } from './types';

export const MapMarkersSquares = ({
  level,
  squares,
}: IMapMarkersSquaresProps) => {
  const data = useAppSelector(_levelMarkers(level));

  return (
    <>
      {data &&
        squares.map((id) => {
          if (data[id] && data[id].data.length) {
            return <SquareMarkers key={id} data={data[id].data} />;
          }
        })}
    </>
  );
};
