export enum EPhotoSizes {
  ORIGIN = 'origin',
  SMALL = 'small',
  MIDDLE = 'middle',
}

export interface IPaginationPagesLimit {
  page: number;
  limit: number;
}

export interface IReportsLimit {
  limit: number;
}

export interface IDateRangeValue {
  fromDate: string;
  toDate: string;
}

export interface IPermissions {
  isRoleEditable?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
}

export interface ISinglePermissions {
  isRoleAddable?: boolean;
  isCompanyEditable?: boolean;
}
