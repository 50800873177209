import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShortGeoCoordinates, TMapZoom } from '@svitlofour/types';
import { initialState } from './initialState';

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapZoom(state, action: PayloadAction<TMapZoom>) {
      state.zoom = action.payload;
    },
    setMapCenter(state, action: PayloadAction<IShortGeoCoordinates>) {
      state.center = action.payload;
    },
  },
});

export const { setMapZoom, setMapCenter } = mapSlice.actions;

export const mapReducer = mapSlice.reducer;
