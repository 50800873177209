import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetHashtagsResponse,
  IPostHashtagResponse,
  IPostHashtagRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EHashtagTagType } from './types';

export const hashtagsApi = createApi({
  reducerPath: 'hashtagsApi',
  tagTypes: [EHashtagTagType.HASHTAGS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getHashtags: build.query<IGetHashtagsResponse, void>({
      query: () => ({
        url: `${version}/hashtags`,
      }),
      providesTags: (result): { type: EHashtagTagType; id: string }[] =>
        result
          ? [
              ...result.map(({ uid }) => ({
                type: EHashtagTagType.HASHTAGS,
                id: uid,
              })),
              { type: EHashtagTagType.HASHTAGS, id: 'List' },
            ]
          : [{ type: EHashtagTagType.HASHTAGS, id: 'List' }],
    }),
    addHashtag: build.mutation<IPostHashtagResponse, IPostHashtagRequest>({
      query: (body) => ({
        url: `${version}/hashtag`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: EHashtagTagType.HASHTAGS, id: 'List' }],
    }),
    deleteHashtag: build.mutation<{ success: boolean }, string>({
      query: (id) => ({
        url: `${version}/hashtag/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: EHashtagTagType.HASHTAGS, id: 'List' }],
    }),
  }),
});

export const {
  useGetHashtagsQuery,
  useAddHashtagMutation,
  useDeleteHashtagMutation,
} = hashtagsApi;
