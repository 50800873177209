import { mapConfig } from 'config';
import {
  useAppSelector,
  useAppDispatch,
  useLazyGetReviewQuery,
  setDataReviewByUid,
  setMarkersVisible,
  setActiveReviewUid,
  setActiveIsAnimatedView,
  setActiveIsDeepLinking,
} from 'store';
import { _getDataReview } from 'store/selectors';
import { getCookie, getUidParam } from 'helpers';
import { IShortGeoCoordinates, TMapZoom } from '@svitlofour/types';
import { useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';

interface IUseMapParams {
  zoom: TMapZoom;
  center: IShortGeoCoordinates;
}

export const useMapParams = (): IUseMapParams => {
  const dispatch = useAppDispatch();
  const cookie = getCookie();
  const params = useSearchParams();
  const uid = getUidParam(params[0]);

  const [getReview] = useLazyGetReviewQuery();

  const { initialCenterMap, defaultZoom } = mapConfig;

  const review = useAppSelector(_getDataReview(uid));
  const { zoom: mapZoom, center: mapCenter } = useAppSelector(({ map }) => map);

  const [center, setCenter] = useState<IShortGeoCoordinates>(initialCenterMap);
  const [zoom, setZoom] = useState<TMapZoom>(defaultZoom);

  useLayoutEffect(() => {
    if (mapCenter && mapZoom) {
      setCenter(mapCenter);
      setZoom(mapZoom);
      return;
    }
    if (!uid) {
      if (!cookie) {
        return;
      }
      setCenter({ lat: +cookie.lat, lng: +cookie.lng });
      setZoom(defaultZoom);
      return;
    }
    if (!review) {
      getReview({ uid })
        .then(({ data }) => data && dispatch(setDataReviewByUid(data)))
        .catch((err) => message.error(err));
    } else {
      setCenter({ lat: review.latitude, lng: review.longitude });
    }

    dispatch(setMarkersVisible(false));
    dispatch(setActiveReviewUid(uid));
    dispatch(setActiveIsAnimatedView(true));
    dispatch(setActiveIsDeepLinking(true));
  }, [review, mapCenter, mapZoom]);

  return {
    zoom,
    center,
  };
};
