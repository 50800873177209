import { createApi } from '@reduxjs/toolkit/query/react';
import {
  IGetUserEventsResponse,
  IUserEvent,
  IPostUserEventRequest,
} from '@svitlofour/types';

import { baseQueryWithReauth } from '../client';
import { version } from '../client/constants';

import { EObserverTagType } from './types';

export const observersApi = createApi({
  reducerPath: 'observersApi',
  tagTypes: [EObserverTagType.OBSERVERS],
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getObservers: build.query<IGetUserEventsResponse, void>({
      query: () => ({
        url: `${version}/events`,
      }),
      providesTags: (
        result
      ): { type: EObserverTagType.OBSERVERS; id: string }[] =>
        result
          ? [
              ...result.map(({ uid }) => ({
                type: EObserverTagType.OBSERVERS,
                id: uid,
              })),
              { type: EObserverTagType.OBSERVERS, id: 'List' },
            ]
          : [{ type: EObserverTagType.OBSERVERS, id: 'List' }],
    }),
    addObserver: build.mutation<IUserEvent, IPostUserEventRequest>({
      query: (body) => ({
        url: `${version}/event`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: EObserverTagType.OBSERVERS, id: 'List' }],
    }),
  }),
});

export const { useGetObserversQuery, useAddObserverMutation } = observersApi;
