import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { signOut } from 'aws-amplify/auth';
import { message } from 'antd';
import Cookies from 'js-cookie';

import {
  useAppDispatch,
  setUserSignOut,
  setIsAuth,
  setIsCorporativeCabinet,
} from 'store';
import { MapPath } from 'router/routes/constants';

import { useResetApiState } from './useResetApiState';
import { IUseSignOut } from './types';

export const useSignOut = (): IUseSignOut => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { resetApiState } = useResetApiState();

  const onSignOut = () => {
    try {
      setIsLoading(true);
      signOut();
      dispatch(setUserSignOut());
      dispatch(setIsAuth(false));
      dispatch(setIsCorporativeCabinet(false));
      navigate(MapPath);
      resetApiState();
      Cookies.remove('currentCompany');
    } catch (err) {
      message.error(`${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  return { onSignOut, isLoading };
};
