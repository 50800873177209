import { keyframes, styled } from 'styled-components';
import { Flex } from 'antd';

import { metrics, colors } from 'theme';

const open = () => keyframes`
  0% {
    height: 40px;
    width: 40px;
  }
  100% {
    height: 180px;
    width: 300px;
  }
`;

const close = () => keyframes`
  0% {
    height: 180px;
    width: 300px;
  }
  100% {
    height: 40px;
    width: 40px;
  }
`;

export const SAppPanel = styled(Flex)<{
  $show: boolean;
  $isOpenGallery: boolean;
  $isDrawerOpen: boolean;
}>`
  position: absolute;
  z-index: 10000;
  bottom: ${({ $isOpenGallery }) => ($isOpenGallery ? '164px' : '24px')}; 
  left: 14px;
  height: 40px;
  width: 40px;
  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.border};
  padding: 4px;
  animation: ${({ $show }) => ($show ? open : close)} 0.6s linear forwards;

  @media screen and (max-width: ${metrics.mediaTabletLSize}px) {
    display: ${({ $isDrawerOpen }) => ($isDrawerOpen ? 'none' : 'block')}; 
`;

export const SMobile = styled.div`
  display: none;
  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    display: block;
  }
`;

export const SWeb = styled.div`
  display: block;
  @media screen and (max-width: ${metrics.mediaTabletSize}px) {
    display: none;
  }
`;
