import { IReportItemsProps } from './types';
import { Collapse, CollapseProps } from 'antd';
import {
  ReportCard,
  ReportCardLabel,
  ReportSingleActions,
} from 'modules/corporativeCabinet/reports';
import { SCollapseWrapper } from './style';

export const ReportItems = ({ reports }: IReportItemsProps) => {
  const items: CollapseProps['items'] = reports.map((report) => {
    return {
      key: report.uid,
      label: <ReportCardLabel report={report} />,
      children: <ReportCard report={report} />,
      extra: <ReportSingleActions report={report} />,
    };
  });

  return (
    <SCollapseWrapper vertical>
      <Collapse accordion items={items} />
    </SCollapseWrapper>
  );
};
