import { IGeoCoordinates } from '@svitlofour/types';
import { resetLocationCircle, setLocationCircleCenter, setLocationCirclePoints, useAppDispatch } from 'store';
import { getCirclePoints, getCircleSquares } from '@svitlofour/map';
// import { getCirclePoints } from 'helpers/location/getCirclePoints';
// import { getCircleSquares } from '../../helpers/location/getCircleSquares';

interface IUseLocationCircle {
  showCircle: (location: IGeoCoordinates | null, radius: number) => void
  resetCircle: () => void;
}

export const useLocationCircle = (): IUseLocationCircle => {
  const dispatch = useAppDispatch();

  const showCircle = (location: IGeoCoordinates | null, radius: number) => {
    if (!location) {
      return;
    }

    const circlePoints = getCirclePoints(location, radius, 100);     
    dispatch(setLocationCirclePoints(circlePoints));
    dispatch(setLocationCircleCenter(location));
    // NOTE: test for squares methods
    // getCircleSquares(location, radius, 100);
  };

  const resetCircle = () => {
    dispatch(resetLocationCircle());
  };

  return {
    showCircle,
    resetCircle,
  };
};
