import { ERole, ICompanyUserTableItem } from '@svitlofour/types';
import { useEffect, useState } from 'react';
import { IPermissions, ISinglePermissions } from 'shared/types';
import { useAppSelector } from 'store';

interface IUseUserPermissions {
  addPermissions: (
    emploees: ICompanyUserTableItem[]
  ) => (ICompanyUserTableItem & IPermissions)[];
  singlePermissons: ISinglePermissions | undefined;
}

export const useUserPermissions = (): IUseUserPermissions => {
  const userRole = useAppSelector(
    ({ auth: { userData } }) => userData.currentCompany?.role
  );
  const userUid = useAppSelector(
    ({ auth: { userData } }) => userData.user?.uid
  );

  const [singlePermissons, setSinglePermissions] = useState<
    ISinglePermissions | undefined
  >(undefined);

  const setPermissions = (emploee: ICompanyUserTableItem): IPermissions => {
    if (userRole === ERole.OWNER && emploee.role === ERole.OWNER) {
      return {
        isRoleEditable: false,
        isEditable: true,
        isDeletable: false,
      };
    }
    if (userRole === ERole.ADMIN && emploee.role === ERole.OWNER) {
      return {
        isRoleEditable: false,
        isEditable: false,
        isDeletable: false,
      };
    }
    if (userRole === ERole.ADMIN && emploee.role === ERole.ADMIN) {
      return {
        isRoleEditable: false,
        isEditable: userUid === emploee.userUid ? true : false,
        isDeletable: false,
      };
    }
    if (userRole === ERole.ADMIN && emploee.role === ERole.WORKER) {
      return {
        isRoleEditable: false,
        isEditable: true,
        isDeletable: true,
      };
    }
    return {
      isRoleEditable: true,
      isEditable: true,
      isDeletable: true,
    };
  };

  const addPermissions = (emploees: ICompanyUserTableItem[]) => {
    return emploees.map((employee) => {
      const { isRoleEditable, isEditable, isDeletable } =
        setPermissions(employee);
      return { ...employee, isRoleEditable, isEditable, isDeletable };
    });
  };

  useEffect(() => {
    if (userRole === ERole.OWNER) {
      setSinglePermissions({
        isRoleAddable: true,
        isCompanyEditable: true,
      });
      return;
    }
    setSinglePermissions({
      isRoleAddable: false,
      isCompanyEditable: false,
    });
  }, [userRole]);

  return { addPermissions, singlePermissons };
};
