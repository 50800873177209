import {
  setActiveIsDeepLinking,
  setActiveReviewDrawerIsOpen,
  setActiveShowReviewMarker,
  setMarkersVisible,
  useAppDispatch,
  useAppSelector,
  setIsReviewDrawerSmall,
  setIsDrawerOpen,
} from 'store';
import {
  _activeIsDeepLinking,
  _activeReviewDrawerIsOpen,
} from 'store/selectors';

interface IUseReviewDrawer {
  isOpenDrawer: boolean;
  openDrawer: (isDeepLinking?: boolean) => void;
  closeDrawer: () => void;
}

export const useReviewDrawer = (): IUseReviewDrawer => {
  const dispatch = useAppDispatch();
  const reviewDrawerIsOpen = useAppSelector(_activeReviewDrawerIsOpen);
  const isDeepLinking = useAppSelector(_activeIsDeepLinking);

  const { isReviewDrawerSmall, showReviewMarker } = useAppSelector(
    ({ active }) => active
  );

  const clearUrlParameters = () => {
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;
    window.history.replaceState({ path: url }, '', url);
  };

  const openDrawer = () => {
    dispatch(setActiveReviewDrawerIsOpen(true));
    dispatch(setIsDrawerOpen(true));
  };

  const closeDrawer = () => {
    if (showReviewMarker && !isReviewDrawerSmall) {
      dispatch(setIsReviewDrawerSmall(true));
    } else {
      dispatch(setActiveReviewDrawerIsOpen(false));
      if (isDeepLinking) {
        dispatch(setActiveIsDeepLinking(false));
        dispatch(setActiveShowReviewMarker(false));
        dispatch(setMarkersVisible(true));
      }
      clearUrlParameters();
      dispatch(setIsReviewDrawerSmall(false));
    }
  };

  return {
    isOpenDrawer: reviewDrawerIsOpen,
    openDrawer,
    closeDrawer,
  };
};
