import { EColor, EColorValue } from '@svitlofour/types';

export const colors = {
  white: '#FFFFFF',
  black: '#111111',
  grey: '#808080',
  text: '#111111',
  subtext: '#667085',
  subtext60: '#66708599', // 60%
  grey40: '#D0D5DD66', // 40%
  greyBlue: '#475467',
  border: '#D0D5DD66',
  red: '#FF0000',
  blueMediumAzure: '#4096ff',
  // TODO: add colors

  // svitlofour
  R: EColor.R,
  Y: EColor.Y,
  G: EColor.G,
  [EColorValue.G]: EColor.G,
  [EColorValue.Y]: EColor.Y,
  [EColorValue.R]: EColor.R,
};
