import { mapConfig } from 'config';
import { TSquareId } from '@svitlofour/types';
import { TSquareMarkers } from 'store';

export const updateMarkersList = (
  parentSquareIds: Array<TSquareId>,
  levelMarkersList: TSquareMarkers
): Array<TSquareId> => {
  if (!parentSquareIds.length || !levelMarkersList) {
    return [];
  }

  const squaresToUpdate = [] as Array<TSquareId>;

  parentSquareIds.map((squareId) => {
    if (!levelMarkersList[squareId]) {
      squaresToUpdate.push(squareId);
      return;
    }

    const updatedAt = levelMarkersList[squareId].updatedAt;
    const status = levelMarkersList[squareId].status;

    // TODO: change backend errors handle
    if (Number(status) && Date.now() - Number(status) < 60 * 1000) {
      return;
    }

    if (
      levelMarkersList[squareId] &&
      Date.now() - updatedAt > mapConfig.markersTimeToLive
    ) {
      squaresToUpdate.push(squareId);
    }
  });

  return squaresToUpdate.filter((item) => Boolean(item));
};
