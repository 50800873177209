import { Flex } from 'antd';
import { MarkerF } from '@react-google-maps/api';
import { markerConfig } from 'config';

import { MarkerData } from 'shared/MarkerSvg';

import { IMarkerProps } from './types';

export const GoogleMapMarker = ({
  marker,
  scale,
  handlerMarkerClick,
}: IMarkerProps) => {
  const { R, Y, G } = MarkerData({ data: marker });

  return (
    <>
      <Flex key={marker.id}>
        {marker.G > 0 && (
          <MarkerF
            position={{
              lng: marker.longitude,
              lat: marker.latitude,
            }}
            icon={{
              anchor: {
                x: markerConfig.defaultMarkerSize / 2,
                y: markerConfig.defaultMarkerSize / 2,
              } as google.maps.Point,
              path: G.path,
              fillColor: G.fill,
              fillOpacity: 1,
              scale,
              strokeColor: G.fill,
              strokeWeight: 1,
            }}
            opacity={0.9}
            onClick={handlerMarkerClick}
          />
        )}
        {marker.Y > 0 && (
          <MarkerF
            position={{
              lng: marker.longitude,
              lat: marker.latitude,
            }}
            icon={{
              anchor: {
                x: markerConfig.defaultMarkerSize / 2,
                y: markerConfig.defaultMarkerSize / 2,
              } as google.maps.Point,
              path: Y.path,
              fillColor: Y.fill,
              fillOpacity: 1,
              scale,
              strokeColor: Y.fill,
              strokeWeight: 1,
            }}
            opacity={0.9}
            onClick={handlerMarkerClick}
          />
        )}
        {marker.R > 0 && (
          <MarkerF
            position={{
              lng: marker.longitude,
              lat: marker.latitude,
            }}
            icon={{
              anchor: {
                x: markerConfig.defaultMarkerSize / 2,
                y: markerConfig.defaultMarkerSize / 2,
              } as google.maps.Point,
              path: R.path,
              fillColor: R.fill,
              fillOpacity: 1,
              scale,
              strokeColor: R.fill,
              strokeWeight: 1,
            }}
            opacity={0.9}
            onClick={handlerMarkerClick}
          />
        )}
      </Flex>
    </>
  );
};
