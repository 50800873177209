import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import { IGetUserDataResponse, ICompany, ERole } from '@svitlofour/types';
import Cookies from 'js-cookie';

import { initialState } from './initialState';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth(state, action) {
      state.isAuth = action.payload;
    },
    setUserDataAWS: (
      state,
      { payload }: PayloadAction<FetchUserAttributesOutput>
    ) => {
      const { name, email, sub } = payload;

      state.userEmail = email;
      state.userName = email;
      state.name = name;
      state.userUid = sub;
    },
    setUserSignOut: () => initialState,
    setUserDataIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setUserData: (state, { payload }: PayloadAction<IGetUserDataResponse>) => {
      const currentCompanyCookies = Cookies.get('currentCompany');

      let currentCompany;

      if (currentCompanyCookies) {
        currentCompany = payload.companies?.find(
          ({ uid }) => uid === currentCompanyCookies
        );
      } else {
        currentCompany = payload.companies?.find(
          ({ role }) => role === ERole.OWNER || payload.companies[0]
        );
        Cookies.set('currentCompany', currentCompany ? currentCompany.uid : '');
      }

      state.userData = { ...payload, currentCompany };
    },
    setCurrentCompany: (
      state,
      { payload }: PayloadAction<(ICompany & { role: ERole }) | undefined>
    ) => {
      state.userData.currentCompany = payload;

      Cookies.set(
        'currentCompany',
        state.userData.currentCompany ? state.userData.currentCompany.uid : ''
      );
    },
  },
});

export const {
  setIsAuth,
  setUserDataAWS,
  setUserSignOut,
  setUserData,
  setUserDataIsLoading,
  setCurrentCompany,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
