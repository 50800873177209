import styled from 'styled-components';
import { Modal } from 'antd';

export const SModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 20px;
  }

  .ant-modal-title {
    font-size: 20px;
    line-height: 24px;
  }

  .ant-modal-content .ant-modal-close {
    border-radius: 50%;
    background-color: #d0d5dd52;
    color: #667085;
  }
`;
