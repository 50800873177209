import { Flex } from 'antd';
import styled from 'styled-components';

export const SPhoto = styled(Flex)``;

export const SPhotoItem = styled(Flex)`
  width: 100%;
  padding: 4px;
`;

export const SMiniPhoto = styled(Flex)`
  cursor: pointer;
`;

export const SDateWrapper = styled(Flex)`
  padding: 4px 16px;
`;

export const SPhotoDate = styled(Flex)`
  width: 100%;
  color: #667085;
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #fff;
`;

export const SPhotoText = styled(Flex)`
  padding: 4px 0;
  color: #667085;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;

export const SLink = styled(Flex)``;
