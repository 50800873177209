import { mapConfig } from 'config';
import { Map } from 'leaflet';
import { useEffect } from 'react';
import { 
  setNotificationsBounds, 
  setReviewsBounds, 
  useAppDispatch, 
  useAppSelector 
} from 'store';

interface IUseMapBounds {
  onFitBoundsGoogle: (map: google.maps.Map | undefined) => void;
  onFitBoundsLeaflet: (map: Map | undefined) => void;
}

export const useMapBounds = (): IUseMapBounds => {
  const dispatch = useAppDispatch();
  const { 
    defaultNotificationZoom,
    defaultNotificationsMinZoom,
    defaultMyReviewZoom,
    defaultReviewsMinZoom,
    defaultMinZoom, 
  } = mapConfig;
  const { 
    isMyReviewsOpen, 
    reviewsBounds, 
    coordinateOfMyReview 
  } = useAppSelector(
    ({ myReviews }) => myReviews
  );
  const { 
    isNotificationsOpen, 
    notificationsBounds, 
    isDetailedNotificationCoordinate 
  } = useAppSelector(
    ({ notifications }) => notifications
  );
  
  const onFitBoundsLeaflet = (map: Map | undefined) => {
    if (!map) {
      return;
    }

    useEffect(() => {
      if (!isMyReviewsOpen) {
        dispatch(setReviewsBounds(null));
        map.invalidateSize();
        map.setMinZoom(defaultMinZoom);
      }
      if (map && reviewsBounds) {
        map.setMinZoom(defaultReviewsMinZoom);
        map.invalidateSize();
        map.fitBounds([
          [reviewsBounds.south, reviewsBounds.west],
          [reviewsBounds.north, reviewsBounds.east],
        ]);
      }
    }, [isMyReviewsOpen, reviewsBounds]);
  
    useEffect(() => {
      if (!isNotificationsOpen) {
        dispatch(setNotificationsBounds(null));
        map.invalidateSize();
        map.setMinZoom(defaultMinZoom);
      }
      if (map && notificationsBounds) {
        map.setMinZoom(defaultNotificationsMinZoom);
        map.invalidateSize();
        map.fitBounds([
          [notificationsBounds.south, notificationsBounds.west],
          [notificationsBounds.north, notificationsBounds.east],
        ]);
      }
    }, [isNotificationsOpen]);
  
    useEffect(() => {
      if (map && isDetailedNotificationCoordinate) {
        map.flyTo(
          [isDetailedNotificationCoordinate.lat, isDetailedNotificationCoordinate.lng], 
          defaultNotificationZoom,
        );
      }
      if (map && coordinateOfMyReview) {
        map.flyTo(
          [coordinateOfMyReview.lat, coordinateOfMyReview.lng], 
          defaultMyReviewZoom,
        );
      }
    }, [isDetailedNotificationCoordinate, coordinateOfMyReview]);
  };

  const onFitBoundsGoogle = (map: google.maps.Map | undefined) => {
    if (!map) {
      return;
    }

    useEffect(() => {
      if (!isMyReviewsOpen) {
        dispatch(setReviewsBounds(null));
        map.setOptions({ minZoom: defaultMinZoom });
      }
      if (map && reviewsBounds) {
        map.setOptions({ minZoom: defaultReviewsMinZoom }); 
        map.fitBounds(reviewsBounds);
      }
    }, [isMyReviewsOpen, reviewsBounds]);
  
    useEffect(() => {
      if (!isNotificationsOpen) {
        dispatch(setNotificationsBounds(null));
        map.setOptions({ minZoom: defaultMinZoom });
      }
      if (map && notificationsBounds) {
        map.setOptions({ minZoom: defaultNotificationsMinZoom });
        map.fitBounds(notificationsBounds);
      }
    }, [isNotificationsOpen]);
  
    useEffect(() => {
      if (map && isDetailedNotificationCoordinate) {
        map.moveCamera({
          center: isDetailedNotificationCoordinate,
          zoom: defaultNotificationZoom,
        });
      }
      if (map && coordinateOfMyReview) {
        map.moveCamera({
          center: coordinateOfMyReview,
          zoom: defaultMyReviewZoom,
        });
      }
    }, [isDetailedNotificationCoordinate, coordinateOfMyReview]);
  };

  return {
    onFitBoundsGoogle,
    onFitBoundsLeaflet,
  };
};
