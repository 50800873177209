import { useAppSelector } from 'store';

import { useDropdownTimeOut } from './hooks';
import {
  SWrapper,
  SMenuWrapper,
  SHeaderWrapper,
  SName,
  SEmail,
  SDivider,
  SMenuItem,
  SMenuItemText,
} from './style';
import { IMenu } from './types';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'shared/hooks';
import { Badge } from 'antd';

export const Menu = ({ menuItems, setIsDropdownOpen, isBurgerOpen }: IMenu) => {
  const { t } = useTranslation();
  const {
    isAuth,
    userData: { user },
  } = useAppSelector(({ auth }) => auth);

  const { notificationCount } = useNotifications();
  const { onMouseEnter, onMouseLeave } = useDropdownTimeOut({
    setIsDropdownOpen,
  });

  return (
    <SWrapper vertical $isBurgerOpen={isBurgerOpen}>
      <SMenuWrapper
        vertical
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isAuth && (
          <>
            <SHeaderWrapper gap={5} vertical>
              <SName>
                {t('name')}: {user?.name}
              </SName>
              <SEmail>Email: {user?.email}</SEmail>
            </SHeaderWrapper>
            <SDivider />
          </>
        )}

        {menuItems.map(({ title, onClick, icon, disabled }, i) => (
          <SMenuItem
            key={i}
            align="center"
            $disabled={disabled}
            onClick={() => {
              if (!disabled) {
                setIsDropdownOpen(false);
                onClick();
              }
            }}
          >
            {icon}
            {title === t('title.notifications') && notificationCount ? (
              <Badge count={notificationCount} offset={[30, -5]}>
                <SMenuItemText>{title}</SMenuItemText>
              </Badge>
            ) : (
              <SMenuItemText>{title}</SMenuItemText>
            )}
          </SMenuItem>
        ))}
      </SMenuWrapper>
    </SWrapper>
  );
};
