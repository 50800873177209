import { useEffect, useState } from 'react';
import { IReportTableItem } from '@svitlofour/types';
import {
  DeleteOutlined,
  DiffOutlined,
  EnvironmentOutlined,
  FilePdfOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { _selectedReports, useAppSelector } from 'store';
import { ActionButton } from 'shared';
import {
  ReportsOnMapPath,
  ReportsPath,
  SelectedReportsPath,
} from 'router/routes/constants';
import { useReportsContext } from '../ReportsContext';
import { SDashboardWrapper } from './style';
import { IReportsActionsDashboardProps } from './types';
import { colors } from 'theme';

export const ReportsActionsDashboard = ({
  isFetching,
  total,
}: IReportsActionsDashboardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedReports = useAppSelector(_selectedReports);
  const {
    reports,
    isShowSelectedReports,
    checkedAll,
    onCheckedAll,
    setIsShowSelectedReports,
    onRemoveAllReport,
  } = useReportsContext();

  const [isShowAll, setIsShowAll] = useState(false);
  const [reportList, setReportList] = useState<IReportTableItem[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);

  const isOnMap = location.pathname.includes(ReportsOnMapPath);
  const isOnSelectedReports = location.pathname.includes(SelectedReportsPath);
  const isOnReports = location.pathname === ReportsPath;

  const onShowOnMap = () => {
    if (location.pathname.includes(SelectedReportsPath)) {
      setIsShowAll(true);
      setIsShowSelectedReports(true);
      navigate(ReportsPath + '/' + ReportsOnMapPath, { replace: true });
    }
    if (location.pathname.includes(ReportsOnMapPath)) {
      setIsShowSelectedReports(true);
      setIsShowAll(true);
    }
  };

  const onShowAllOnMap = () => {
    setIsShowSelectedReports(false);
    setIsShowAll(false);
  };

  const onClickChecked = () => {
    onCheckedAll(reportList, !checkedAll);
    setIndeterminate(false);
  };

  useEffect(() => {
    if (location.pathname.includes(SelectedReportsPath)) {
      setIsShowAll(false);
      setIsShowSelectedReports(false);
    }
    if (location.pathname.includes(ReportsOnMapPath)) {
      setIsShowAll(true);
    }
  }, [location]);

  useEffect(() => {
    if (!reports) {
      return;
    }
    setReportList(reports);
  }, [reports]);

  useEffect(() => {
    if (!reports || !selectedReports) {
      return;
    }
    setIndeterminate(
      Boolean(selectedReports.length) &&
        selectedReports?.length !== reports.length
    );
  }, [reports, selectedReports]);

  return (
    <SDashboardWrapper gap={4} align="center" justify="flex-start">
      <Flex gap={4} style={{ width: 80 }}>
        <Typography.Text disabled={isFetching}>
          {t('reportsTable.total')}
        </Typography.Text>
        <Typography.Text strong={Boolean(total)}>
          {total ? total : '0'}
        </Typography.Text>
      </Flex>
      {!isOnSelectedReports && !isOnMap && (
        <ActionButton
          icon={
            <DiffOutlined
              style={{
                color: checkedAll
                  ? colors.red
                  : indeterminate
                  ? colors.black
                  : colors.grey,
              }}
            />
          }
          onClick={onClickChecked}
        />
      )}
      {selectedReports && (
        <Flex gap={4} align="baseline">
          <Typography.Text disabled={Boolean(!selectedReports.length)}>
            {t('reportsActions.selected')}
          </Typography.Text>
          <Flex justify="center" style={{ width: 20 }}>
            <Typography.Text strong disabled={Boolean(!selectedReports.length)}>
              {selectedReports.length}
            </Typography.Text>
          </Flex>
        </Flex>
      )}
      <ActionButton
        disabled={!selectedReports?.length}
        icon={<FilePdfOutlined />}
        // onClick={() => createPDFDocument(selectedReports)}
      />
      {!isOnReports && (
        <ActionButton
          disabled={Boolean(!selectedReports?.length) || isShowSelectedReports}
          icon={<EnvironmentOutlined />}
          onClick={onShowOnMap}
        />
      )}
      {isOnMap && Boolean(selectedReports?.length) && (
        <ActionButton
          disabled={!isShowAll || !isShowSelectedReports}
          icon={<GlobalOutlined />}
          onClick={onShowAllOnMap}
        />
      )}
      {(isOnSelectedReports || isOnMap) && Boolean(selectedReports?.length) && (
        <ActionButton icon={<DeleteOutlined />} onClick={onRemoveAllReport} />
      )}
    </SDashboardWrapper>
  );
};
