import { IMapControlButtonsProps } from './types';
import { SControlButton } from './style';
import { AimOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { levelToZoom } from '@svitlofour/map';
import { mapConfig } from 'config';
import { useAppSelector, _level } from 'store';

export const MapControlButtons = ({
  zoomIn,
  zoomOut,
  moveToMyPosition,
  withPosition,
}: IMapControlButtonsProps) => {
  const level = useAppSelector(_level);
  const { defaultMinZoom, defaultMaxZoom } = mapConfig;

  return (
    <>
      {withPosition && (
        <SControlButton
          $active
          shape="circle"
          size="large"
          icon={<AimOutlined />}
          onClick={moveToMyPosition}
        />
      )}
      <SControlButton
        $active={levelToZoom(level) < defaultMaxZoom}
        shape="circle"
        size="large"
        icon={<PlusOutlined />}
        onClick={zoomIn}
      />
      <SControlButton
        $active={levelToZoom(level) > defaultMinZoom}
        shape="circle"
        size="large"
        icon={<MinusOutlined />}
        onClick={zoomOut}
      />
    </>
  );
};
