import styled from 'styled-components';
import { Card, Flex } from 'antd';

export const SCard = styled(Card)`
  width: 100%;

  .ant-card-body {
    padding: 16px;
  }
`;

export const SDate = styled.div`
  color: #66708599;
`;

export const SInfoBlockWrapper = styled(Flex)`
  padding: 0 8px;
  width: 100%;
`;
