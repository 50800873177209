import styled from 'styled-components';
import { Flex } from 'antd';
import { colors } from 'theme';

export const SEmployeeInfo = styled(Flex)`
  font-weight: 500;
  color: ${colors.subtext};
`;

export const SDateWrapper = styled(Flex)`
  color: ${colors.subtext60};
`;
