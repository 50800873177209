import { useState } from 'react';
import { Button, Flex, QRCode, Typography } from 'antd';
import { CloseOutlined, MobileOutlined } from '@ant-design/icons';

import appStore from 'assets/images/appstore.png';
import googlePlay from 'assets/images/googleplay.png';

import { SAppPanel, SMobile, SWeb } from './style';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';

export const DownLoadAppPanel = () => {
  const { t } = useTranslation();
  const { isOpenGallery, isDrawerOpen } = useAppSelector(
    ({ active }) => active
  );
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const onClose = () => {
    setShow(false);
    setShowButton(false);
    setShowContent(false);
  };

  const onOpen = () => {
    setShow(true);
    setTimeout(() => setShowContent(true), 700);
    setTimeout(() => setShowButton(true), 800);
  };

  return (
    <SAppPanel
      vertical
      justify="flex-start"
      gap={8}
      $show={show}
      $isOpenGallery={isOpenGallery}
      $isDrawerOpen={isDrawerOpen}
      onClick={() => !show && onOpen()}
    >
      <Flex justify="flex-end" align="center">
        {showButton ? (
          <Flex
            justify="space-between"
            align="center"
            style={{ width: '100%', paddingLeft: 12 }}
          >
            <SMobile>
              <Typography.Text>{t('downloadApp.get')}</Typography.Text>
            </SMobile>
            <SWeb>
              <Typography.Text>{t('downloadApp.scan')}</Typography.Text>
            </SWeb>
            <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
          </Flex>
        ) : (
          <Button type="text" icon={<MobileOutlined />} />
        )}
      </Flex>

      {showContent && (
        <Flex vertical gap={12}>
          <SMobile>
            <Flex vertical gap={6} align="center">
              <img src={appStore} loading="lazy" height={60} width={190} />
              <img src={googlePlay} loading="lazy" height={60} width={190} />
            </Flex>
          </SMobile>
          <SWeb>
            <Flex justify="center">
              <QRCode value="www.svitlofour.com" size={120} bordered={false} />
            </Flex>
          </SWeb>
        </Flex>
      )}
    </SAppPanel>
  );
};
