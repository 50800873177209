import { TUid } from '@svitlofour/types';
import { isUidValid } from './isUidValid';

export const getUidParam = (params: URLSearchParams): TUid | null => {
  const uid = params.get('uid');
  const isUid = isUidValid(uid);

  if (isUid) {
    return uid as TUid;
  }

  return null;
};
