import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { EColorValue } from '@svitlofour/types';

import { colors } from 'theme';
import { markerConfig } from 'config';

import { path } from './constants';
import { IMyMarker } from './types';

export const LeafletMyMarker = ({
  uid,
  longitude,
  latitude,
  color,
}: IMyMarker) => {
  const scrollToSelectedItem = () => {
    const element = document.getElementById(`my-review-${uid}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getMarker = (color: EColorValue): L.DivIcon => {
    return L.divIcon({
      iconAnchor: [
        markerConfig.defaultMarkerSize / 2,
        markerConfig.defaultMarkerSize / 2,
      ],
      className: 'hidden',
      html: `
          <svg 
            width="${markerConfig.defaultMarkerSize}" 
            height="${markerConfig.defaultMarkerSize}" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
              d="${path}"
              fill="${colors[color]}"
              fill-opacity="1"
              stroke="${colors[color]}"
              stroke-width="0"
              style="scale: 1"
            />
          </svg>`,
    });
  };

  return (
    <Marker
      position={[latitude, longitude]}
      icon={getMarker(color)}
      opacity={0.9}
      eventHandlers={{
        click: scrollToSelectedItem,
      }}
    />
  );
};
